import React, { Component, Fragment } from "react";
import { Modal } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

export const AlertModal =

class extends Component {
	render() {
		return <Modal
          title={<Fragment>
                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" style={{color: "#FFCD07"}}/> {this.props.titulo}
                  </Fragment>
                }
		      open={this.props.visible}
          onOk={this.props.handleClose}
          onCancel={this.props.handleClose}
          cancelButtonProps={{style: { display: 'none' }}}
          maskClosable = {false}
          centered
        >
          {this.props.children}
        </Modal>
	}

}
import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import { Resultado } from "./Resultado";

import api from "../../services/api";
import './RelatorioGerencial.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { baixarArquivo } from "../../utils/downloadUtil";

registerLocale("pt_br", pt_br);

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const RelatorioGerencial =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				orgaos: [],
				orgao: '',
				fornecedoras: [],
				fornecedora: '',
				planos: [],
				planoConsumo: '',
				carregandoFornecedoras: false,
				resultadoPesquisa: {},
				dataInicial: '',
				dataFinal: '',
				tiposVisao: [],
				tipoVisao: 'C',
				considerarAplicacaoMonitora: false,
				mostrarSomenteAdesoesAtivas: false,
				pesquisaExecutada : false,
				botoesHabilitados : true,
				ordenacao: 1,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.carregarFornecedoras = this.carregarFornecedoras.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaGerencial = this.executaPesquisaGerencial.bind(this);
			this.onChangePage = this.onChangePage.bind(this);
			this.onOrderChange = this.onOrderChange.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.mostraRelatorioGerencialCSV = this.mostraRelatorioGerencialCSV.bind(this);
			this.mostraRelatorioGerencialPDF = this.mostraRelatorioGerencialPDF.bind(this);
			this.handleTipoVisaoChange = this.handleTipoVisaoChange.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Relatório Gerencial</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			if(this.props.user.isAdministrador === true || this.props.user.isMantenedor === true || this.props.user.isFornecedor === true)	{
				api.get('/api/orgao/consumidores/perfil-usuario')
				.then(response => { this.setState({orgaos: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar órgãos consumidores " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			if(this.props.user.isFornecedor === true){
				api.get('/api/consumo/tipos-visao-relatorio')
				.then(response => { this.setState({tiposVisao: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de visão para o relatório " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			this.carregarFornecedoras(this.state.tipoVisao, this.state.orgao);
		}

		renderForm() {
			const { resultadoPesquisa, pesquisaExecutada } = this.state;
			const exibeBotoes = resultadoPesquisa.content && resultadoPesquisa.content.length > 0;

			const user = this.props.user;
			const tipoVisao = this.state.tipoVisao;
			const exibeComboOrgao = user && (user.isAdministrador === true || user.isMantenedor === true || (user.isFornecedor === true && tipoVisao === 'F'));
			const exibeConsiderarAplicacaoMonitora = user && (user.isAdministrador === true || user.isMantenedor === true);

			return(
				<div className="container-fluid">
					<div className="br-form">
						{user && user.isFornecedor === true &&
								<div className="row">
									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label htmlFor="tipoVisao">Mostrar:</label>
												<select name="tipoVisao" onChange={this.handleTipoVisaoChange} value={this.state.tipoVisao}>
													{
														this.state.tiposVisao.map(tipoVisao =>
															<option 
																key={tipoVisao.codigo} 
																value={tipoVisao.codigo}>
																{tipoVisao.nome}
															</option>
													)}
												</select>
											</div>
										</div>
									</div>
								</div>
							}
						<div className="row">
							{exibeComboOrgao &&
							<div className="col-md">
									<div className="field">
										<div className="input">
											<label >Órgao Consumidor:</label>
											<select name="orgao" onChange={this.handleOrgaoChange} value={this.state.orgao}>
												<option value="">Todos</option>
												{
													this.state.orgaos.map(orgao =>
														<option 
															key={orgao.id} 
															value={orgao.id}>
															{orgao.nome}
														</option>
												)}
											</select>
										</div>
									</div>
							</div>
							}
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{this.state.fornecedoras.length > 0 &&
											<select name="fornecedora" onChange={this.handleFornecedoraChange} value={this.state.fornecedora}>
											<option value="0">Selecione uma opção</option>
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{this.state.carregandoFornecedoras === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}

										{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'Não há APIs para serem selecionadas.'}
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
									{this.state.planos.length > 0 &&
									<label htmlFor="plano">Plano de Consumo:</label>
									}									
									{this.state.planos.length > 0 &&
										<select name="planoConsumo" onChange={this.handleInputChange} value={this.state.planoConsumo}>
											<option value="">Todos</option>
												{
													this.state.planos.map(plano =>
														<option 
															key={plano.idPlano} 
															value={plano.idPlano} >
															{plano.descricaoPlano}
														</option>
												)}
										</select>
										}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Data Inicial:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={this.state.dataInicial} onChange={data => {this.setState({dataInicial: data})}} />
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Data Final:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={this.state.dataFinal} onChange={data => {this.setState({dataFinal: data})}} />
									</div>
								</div>
							</div>
						</div>

						
						<div className="row">
							{ exibeConsiderarAplicacaoMonitora &&
								<div className="col-md">
									<div className="check">
										<div className="input">
											<input type="checkbox" name="considerarAplicacaoMonitora" checked={this.state.considerarAplicacaoMonitora} onChange={this.handleInputChange} />
											<label>Mostrar consumo das aplicações de monitoração</label>
										</div>
									</div>
								</div>
							}

							<div className="col-md">
								<div className="check">
										<div className="input">
											<input type="checkbox" name="mostrarSomenteAdesoesAtivas" checked={this.state.mostrarSomenteAdesoesAtivas} onChange={this.handleInputChange} />
											<label>Mostrar somente adesões ativas</label>
										</div>
									</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioGerencialPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioGerencialCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

						<Resultado resultadoPesquisa={resultadoPesquisa} pesquisaExecutada={pesquisaExecutada} onChangePage={this.onChangePage} 
							ordenacao={this.state.ordenacao} onOrderChange={this.onOrderChange} 
							botoesHabilitados={this.state.botoesHabilitados}/>

					</div>
				</div>
			);
		}

		validaFormulario(){
			let mensagens = [];
			const {fornecedora, dataInicial, dataFinal} = this.state;

			if(!fornecedora || fornecedora === "0"){
				mensagens.push({mensagem: "API é obrigatório."})
			}

/*			if(!dataInicial){
				mensagens.push({mensagem: "Data Inicial"})
			}

			if(!dataFinal){
				mensagens.push({mensagem: "Data Final"})
			}*/

			if(dataInicial && dataFinal && dataInicial > dataFinal){
				mensagens.push({mensagem: "A data inicial deve ser menor ou igual à data final"})
			}
			
/*			if(dataInicial && dataFinal){
				var diff = Math.floor(dataFinal.getTime() - dataInicial.getTime());
				var day = 1000 * 60 * 60 * 24;
				
				if((diff/day) > 31){
					mensagens.push({mensagem: "A diferença entre a data inicial e final não pode ser superior a 31 dias"})
				}
			}*/

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleTipoVisaoChange(evento){
			const tipoVisao =  evento.target.value;
			const orgao = "";
			let fornecedoras = [];

			this.setState({
				tipoVisao,
				fornecedoras,
				fornecedora: "",
				orgao,
				planos: [],
				planoConsumo: "",
			});

			this.carregarFornecedoras(tipoVisao, orgao);
		}

		handleOrgaoChange(evento){
			const idOrgao =  evento.target.value;
			const idTipoVisao = this.state.tipoVisao;

			this.setState({
				consumidora: "",
				fornecedora: "",
				planos: [],
				planoConsumo: '',
				orgao: idOrgao,
			});

			this.carregarFornecedoras(idTipoVisao, idOrgao);
		}

		carregarFornecedoras(idTipoVisao, idOrgao) {
			this.setState(
				{
					fornecedora: "",
					carregandoFornecedoras : true
				}
			);
			api.get(`/api/aplicacao-fornecedora/tipo-visao/${idTipoVisao}/orgao-consumidor?idOrgaoConsumidor=${idOrgao}`)
			.then(response => { 
				const fornecedoras = response.data;
				this.setState(
					{
						fornecedoras,
						carregandoFornecedoras : false,
					}
				)
			 })
			.catch(erro => {
				this.setState({carregandoFornecedoras : false});
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		pesquisar (e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}
			this.executaPesquisaGerencial(0, 1);
		}

		onChangePage(pagina) {
			this.executaPesquisaGerencial(pagina, this.state.ordenacao);
		}

		onOrderChange(ordem) {
			let ordenacao = ordem;
			if (Math.abs(ordenacao) === this.state.ordenacao){
				ordenacao = ordenacao * -1;
			}
			this.setState({ordenacao});
			this.executaPesquisaGerencial(0, ordenacao);
		}

		executaPesquisaGerencial(pagina, ordenacao){

			const {fornecedora, orgao, dataInicial, dataFinal, planoConsumo, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteAdesoesAtivas } = this.state;
			const criterioPesquisa = {fornecedora, orgao, dataInicial, dataFinal, planoConsumo, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteAdesoesAtivas, pagina, ordenacao };

			let url;
			url = '/api/gerencial/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false, ordenacao});
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar os dados gerenciais ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		mostraRelatorioGerencialCSV(e){
			this.mostraRelatorioGerencial(e, 'csv');
		}

		mostraRelatorioGerencialPDF(e){
			this.mostraRelatorioGerencial(e, 'pdf');
		}

		mostraRelatorioGerencial(e, tipo){
			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {fornecedora, orgao, dataInicial, dataFinal, planoConsumo, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteAdesoesAtivas } = this.state;
			const criterioPesquisa = {fornecedora, orgao, dataInicial, dataFinal, planoConsumo, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteAdesoesAtivas};
			const url = `/api/gerencial/${tipo}`;

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioGerencial.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório gerencial';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			const tipoVisao = 'C';
			const orgao = '';
			this.setState({
				orgao,
				fornecedora: '',
				planos: [],
				tipoVisao,
				resultadoPesquisa: {},
				pesquisaExecutada : false,
				dataInicial: '',
				dataFinal: '',
				planoConsumo: '',
				considerarAplicacaoMonitora: '',
				mostrarSomenteAdesoesAtivas: false,
				ordenacao: 1,
			});
			this.carregarFornecedoras(tipoVisao, orgao);
		}

		handleFornecedoraChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.value;
			this.setState({[nome]: valor});
			this.props.limpaMensagem();
			const idFornecedora =  evento.target.value;
			this.setState({
				planos : [],
				planoConsumo: "",
			});
			this.carregaPlanosFornecedora(idFornecedora);
		}

		carregaPlanosFornecedora(idFornecedora){
			if(idFornecedora && idFornecedora !== "0"){
				api.get(`/api/plano/alocados/tipo-visao/${this.state.tipoVisao}/aplicacao-fornecedora/${idFornecedora}?idOrgaoConsumidor=${this.state.orgao}`)
				.then(response => { 
					this.setState(
						{
							planos: response.data
						}
					);
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os planos associados à API " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento dos planos das api's.")
				});
			}
		}

	}

) // redux
); // router
export const sessionStorageLogin = (token, foto) => {
    sessionStorage.setItem('sessionStorage', JSON.stringify({token, foto}));
}

export const sessionStorageLogout = () => {
    sessionStorage.removeItem('sessionStorage');
}

export const getSessionStorage = () => {
    return sessionStorage.getItem('sessionStorage');
}
import React, { Component } from "react";

import './SortButton.css';

export const SortButton =

class extends Component {

	render() {

		const title = this.props.title;
		const onClick = this.props.onClick;
		const disabled = this.props.disabled;
		const ordenacaoAtual = this.props.ordenacaoAtual;
		const ordenarPor = this.props.ordenarPor;
		let estiloIcone = "fas fa-sort";

		if (Math.abs(ordenacaoAtual) === Math.abs(ordenarPor)) {
			if(ordenacaoAtual > 0) {
				estiloIcone = "fas fa-sort-up";
			}else {
				estiloIcone = "fas fa-sort-down";
			}
		}
		
		return (
			<button type="button" className='botao-ordenacao' title={title}  onClick={() => onClick(ordenarPor)} disabled={disabled}>
				<i className={estiloIcone}></i>
			</button>
		);
	}

}
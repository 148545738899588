import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import './Styles.css';
import { Card } from "./Card";

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const PainelCards =	
	// redux
	connect(
		(mapStateToProps)
	)(
		withRouter(
			class extends Component {

				render() {
					const { user } = this.props;
					let itensMenu = [];
					let submenusComCards = [];

					if (user && user.menu.length > 0) {
						itensMenu = user.menu;
						submenusComCards = itensMenu.map(menu => menu.submenus); 
						submenusComCards = [].concat(...submenusComCards).filter(submenu =>  Boolean(submenu.card));
					}

					return (
						<Fragment>
							{(user && user.menu) &&
								<Fragment>
									<div className="d-flex justify-content-center" style={{margin: "10px"}}>
										<h2>Bem-vindo ao Gerenciador de API do Conecta</h2>
									</div>


									<div className='home-cards'>
										{submenusComCards && submenusComCards.map(submenu => 
											<Card key={submenu.id} titulo={submenu.card.titulo} icone={submenu.card.icone} onClick={() => submenu.linkExterno ? window.open(submenu.link,'_blank') : this.props.history.push(submenu.link)}/>
										)}
									</div>
								</Fragment>
							}
						</Fragment>
					);
				}


				
			}
		)

	)
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Fragment } from "react";
import './ConsentTerm.css';

const mapStateToProps = (state) => {
	return {
		termoConsentimentoMaisRecente: state.termoConsentimentoStore.termoConsentimentoMaisRecente,
	}
}

export const LatestConsentTermAgreementLink =

// redux
connect(
	(mapStateToProps), 
	(null)
)(
	class extends Component {
		render() {
			const { termoConsentimentoMaisRecente } = this.props;
			return (
				<Fragment>
				{(termoConsentimentoMaisRecente && termoConsentimentoMaisRecente.link) && <a href={`${termoConsentimentoMaisRecente.link}`} target="_blank" rel="noopener noreferrer">{`${this.props.textoLink}`}</a>}
				</Fragment>
			);
		}
	}
);

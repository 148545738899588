export const getMessagemErro = function(mensagemInicial, erro){
    const retorno = {};

    if (typeof erro === 'string'){
        retorno['mensagem'] = mensagemInicial + " " + erro;
    }else if(erro.response && erro.response.data) {
        const data = erro.response.data;
        retorno['mensagem'] = data.mensagem;
        retorno['erros'] = data.erros;
	}else if(erro.data) {
        const data = erro.data;
        if(erro.data.mensagem) {
            retorno['mensagem'] = data.mensagem;
            retorno['erros'] = data.erros;
        } else if(erro.data.message){
            retorno['mensagem'] = mensagemInicial + " " + data.message;
        }else {
            retorno['mensagem'] = mensagemInicial + " " + JSON.stringify(data);
        }
	}else {
        retorno['mensagem'] = mensagemInicial + " " + JSON.stringify(erro);
    }
    
    return retorno;
};
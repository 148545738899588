const config = {
	govbrUrl : process.env.REACT_APP_GOV_BR_URL || 'https://sso.staging.acesso.gov.br',
	govbrRedirectUri : process.env.REACT_APP_GOV_BR_URL_RETORNO || 'http://l-gestor.conectagov.estaleiro.serpro.gov.br/login',
	govbrClientId : process.env.REACT_APP_GOV_BR_CLIENT_ID || 'l-gestor.conectagov.estaleiro.serpro.gov.br',
	govbrLougoutUrl : process.env.REACT_APP_GOV_BR_LOGOUT_URL || 'http://l-gestor.conectagov.estaleiro.serpro.gov.br/',
	govbrMonitoracaoUrl : process.env.REACT_APP_GOV_BR_MONITORACAO_URL || 'https://monitoracao.conectagov.estaleiro.serpro.gov.br/login/generic_oauth',
	govbrConfiabilidadesUrl : process.env.REACT_APP_GOV_BR_CONFIABILIDADES_URL || 'https://confiabilidades.staging.acesso.gov.br/',
	govbrOcultaLogotipo : process.env.REACT_APP_GOV_BR_OCULTA_LOGOTIPO || 'false',
	itiUrl: process.env.REACT_APP_ITI_URL || 'https://cas.staging.iti.br',
	itiRedirectUri : process.env.REACT_APP_ITI_URL_RETORNO || 'http://l-gestor.conectagov.estaleiro.serpro.gov.br',
	itiClientId : process.env.REACT_APP_ITI_CLIENT_ID || 'conectagov',
}

export default config;
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { Pagination } from "../Pagination/Pagination";
import {formataData, formataDataMesAno} from '../../utils/formatadorData';
import api from "../../services/api";
import './BillingReference.css';
import DatePicker , { registerLocale } from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
registerLocale("pt_br", pt_br);

export const BillingReferenceList =

// router
withRouter(
// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {
				criterioPesquisa: {
					idContrato: '',
					referencia: '',
					periodoInicial: '',
					periodoFinal: '',
				},
				contratos: [],
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				exibeModalAtivar: false,
				exibeModalInativar: false,
				botoesHabilitados : true,
		 	};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Referências de Faturamento</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			this.props.mostraAguardando();
			api.get('/api/contrato/')
				.then(response => { 
					const contratos = response.data;
					this.props.escondeAguardando();
					this.setState(
						{
							contratos,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os contratos ", erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
					this.props.escondeAguardando();
				});
		}

		renderForm() {
			const { criterioPesquisa, resultadoPesquisa, pesquisaExecutada } = this.state;
			const { idContrato, referencia, periodoInicial, periodoFinal } = criterioPesquisa;
			const { content } = resultadoPesquisa;

			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="contrato">Contrato:</label>
										<select id="contrato" 
											onChange={
												evento => {
													const criterioPesquisa = this.state.criterioPesquisa;
													criterioPesquisa['idContrato'] = evento.target.value;
													this.setState({criterioPesquisa})}
											} 
											value={idContrato} >
											<option value="">Selecione</option>
											{
												this.state.contratos.map(contrato =>
													<option 
														key={contrato.id} 
														value={contrato.id}>
														({contrato.idTipoContrato}) {contrato.nomeContrato} {'=>'} {formataData(contrato.inicioVigencia)} a {formataData(contrato.fimVigencia)}
													</option>
											)}
										</select>
										
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="referencia">Mês de Referência:</label>
										<DatePicker id="referencia" locale="pt_br" dateFormat="MM/yyyy" 
											showMonthYearPicker 
											selected={referencia} 
											onChange={data => {
												const criterioPesquisa = this.state.criterioPesquisa;
												criterioPesquisa['referencia'] = data;
												this.setState({criterioPesquisa})}
											} />
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="periodoInicial">Período inicial:</label>
										<DatePicker id="periodoInicial" locale="pt_br" dateFormat="dd/MM/yyyy" 
											selected={periodoInicial} 
											onChange={data => {
												const criterioPesquisa = this.state.criterioPesquisa;
												criterioPesquisa['periodoInicial'] = data;
												this.setState({criterioPesquisa})}
											} />
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="periodoFinal">Período Final:</label>
										<DatePicker id="periodoFinal" locale="pt_br" dateFormat="dd/MM/yyyy" 
											selected={periodoFinal} 
											onChange={data => {
												const criterioPesquisa = this.state.criterioPesquisa;
												criterioPesquisa['periodoFinal'] = data;
												this.setState({criterioPesquisa})}
											} />
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/billing-reference/new"> 
									<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Novo</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">Contrato</th>
											<th scope="col">Mês de Referência</th>
											<th scope="col">Período Inicial</th>
											<th scope="col">Período Final</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((referenciaFaturamento, index) =>
													<tr key={index} >
														<td>{referenciaFaturamento.nomeContrato}</td>
														<td>{formataDataMesAno(referenciaFaturamento.referencia)}</td>
														<td>{formataData(referenciaFaturamento.periodoInicial)}</td>
														<td>{formataData(referenciaFaturamento.periodoFinal)}</td>
														<td className="text-center">
															<div className="actions">
																<Link to={`/billing-reference/edit/${referenciaFaturamento.id}`}>
																	<button type="button" disabled={!this.state.botoesHabilitados} title="Editar a referência de faturamento">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																</Link>
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhuma referência de faturamento encontrada com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa[nome] = valor;
			this.setState({ criterioPesquisa });
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){

			const {criterioPesquisa} = this.state;
			criterioPesquisa['pagina'] = pagina;
			const url = '/api/referencia-faturamento/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as referências de faturamento. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.setState({
				criterioPesquisa: {
					idContrato: '',
					referencia: '',
					periodoInicial: '',
					periodoFinal: '',
				},
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				exibeModalAtivar: false,
				exibeModalInativar: false,
				botoesHabilitados : true,
			});
		}
	
	}

) // redux
); // router
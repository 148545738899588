import React from "react";
import { Route} from "react-router-dom";
import { connect } from 'react-redux';
import { RedirectPage } from "./RedirectPage";
import { login } from "../providers/actions";
import jwtbroken from 'jsonwebtoken';
import config from "../config";
import { strTobase64 } from "../utils/base64Util";
import { getSessionStorage } from "../utils/sessionStoreUtil";
import { v4 as uuidv4 } from 'uuid';

const validaToken = (token) => {
	if (token ){
		const expiracaoToken = jwtbroken.decode(token).exp;
		const dataHoraAtual = Date.now()/1000;
		if(dataHoraAtual < expiracaoToken){
			return true;
		}
	} 
	return false;
}

const mapStateToProps = (state) => {
	return {
		token: state.usuarioStore.token,
	}
}

const mapDispatchToProps = { login }

export const PrivateRoute = 

// redux
connect(
	(mapStateToProps),
	(mapDispatchToProps)
)(
	({component: Component, path, location, token, login, ...rest }) => {
		let state = {path};
		state['pathname'] = location.pathname;
		if(location.search){
			const queryParams = location.search;
			state['queryParams'] = queryParams;
		}

		state = strTobase64(JSON.stringify(state));

		const urlGovbr = config.govbrUrl;
		const servicoAutorizacaoGovbr = '/authorize';
		const response_type = 'code';
		const client_id = config.govbrClientId;
		const scope = 'openid+email+phone+profile+govbr_confiabilidades';
		const redirect_uri = config.govbrRedirectUri;
		const nonce = uuidv4();

		const urlLoginGovbr = urlGovbr + servicoAutorizacaoGovbr 
		+ '?response_type=' + response_type 
		+ '&client_id=' + client_id
		+ '&scope=' + scope
		+ '&redirect_uri=' + redirect_uri
		+ '&nonce=' + nonce
		+ '&state=' + state;

		let sessaoAtiva = false;

		const storage = getSessionStorage() !== null ? JSON.parse(getSessionStorage()) : null;

		if(storage) {

			const tokenLocalStorage = storage.token;

			if (validaToken(tokenLocalStorage) ){

				if(! token){
					const photo = storage.photo;
					login(tokenLocalStorage, photo);
				}
				sessaoAtiva  = true;
			}
		}

		return (
			<Route
				{...rest}
				render={props => sessaoAtiva ? (<Component {...props} />) : (<RedirectPage to={urlLoginGovbr} />)
			}/>
		);
	}
); // redux 
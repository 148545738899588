import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Navbar } from "./components/Navbar/Navbar";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { MenuHigher } from "./components/Menu/Higher/Higher";
import { Login } from "./components/Login/Login";
import { PrivateRoute } from "./components/PrivateRoute";
import { Home } from "./components/Home/Home";
import { GenerateKey } from "./components/GenerateKey/GenerateKey";
import { RelatorioConsumo } from "./components/RelatorioConsumo/RelatorioConsumo";
import { RelatorioConsumoPorMetodo } from "./components/RelatorioConsumoPorMetodo/RelatorioConsumoPorMetodo";
import { RelatorioAdesaoAplicacao } from "./components/RelatorioAdesaoAplicacao/RelatorioAdesaoAplicacao";
import { RelatorioGerencial } from "./components/RelatorioGerencial/RelatorioGerencial";
import { RelatorioDisponibilidade } from "./components/RelatorioDisponibilidade/RelatorioDisponibilidade";
import { RelatorioAuditoria } from "./components/RelatorioAuditoria/RelatorioAuditoria";
import {RelatorioPrimeiroConsumoOrgao} from "./components/RelatorioPrimeiroConsumoOrgao/RelatorioPrimeiroConsumoOrgao";
import { RelatorioAuditoriaGenerico } from "./components/RelatorioAuditoriaGenerico/RelatorioAuditoriaGenerico";
import { RelatorioPrestacaoContas } from "./components/RelatorioPrestacaoContas/RelatorioPrestacaoContas";
import { ManagerList } from "./components/Manager/List";
import { ManagerEdit } from "./components/Manager/Edit";
import { ApplicationEdit } from "./components/Application/Edit";
import { ApplicationList } from "./components/Application/List";
import {ApplicationAccess} from "./components/Application/Access";
import { OrganizationEdit } from "./components/Organization/Edit";
import { OrganizationList } from "./components/Organization/List";
import {OrganizationAccess} from "./components/Organization/Access";
import { PlanEdit } from "./components/Plan/Edit";
import { PlanList } from "./components/Plan/List";
import { ApiEdit } from "./components/API/Edit";
import { ApiList } from "./components/API/List";
import { APIAttachments } from "./components/API/Attachments";
import { SolicitacaoRelatorioAuditoriaEdit } from "./components/SolicitacaoRelatorioAuditoria/Edit";
import { SolicitacaoRelatorioAuditoriaList } from "./components/SolicitacaoRelatorioAuditoria/List";
import { SolicitacaoCadastroWhitelistEdit } from "./components/SolicitacaoCadastroWhitelist/Edit";
import { SolicitacaoCadastroWhitelistList } from "./components/SolicitacaoCadastroWhitelist/List";
import { SolicitacaoAdesaoEdit } from "./components/SolicitacaoAdesao/Edit";
import { SolicitacaoAdesaoList } from "./components/SolicitacaoAdesao/List";
import { SolicitacaoAdesaoApproval } from "./components/SolicitacaoAdesao/Approval";
import { SolicitacaoAdesaoSigner } from "./components/SolicitacaoAdesao/Sign";
import { SolicitacaoAdesaoView } from "./components/SolicitacaoAdesao/View";
import { WhitelistList } from "./components/Whitelist/List";
import { ConsentTermEdit } from "./components/ConsentTerm/Edit";
import { ManagerProfileEdit } from "./components/ManagerProfile/Edit";
import { ManagerProfileList } from "./components/ManagerProfile/List";
import { ContractEdit } from "./components/Contract/Edit";
import { ContractList } from "./components/Contract/List";
import { APIContractDetails } from "./components/Contract/APIContractDetails";
import { BillingReferenceEdit } from "./components/BillingReference/Edit";
import { BillingReferenceList } from "./components/BillingReference/List";
import { BillingEdit } from "./components/Billing/Edit";
import { BillingList } from "./components/Billing/List";
import { BillingAgreement } from "./components/Billing/Agreement";
import { APIAttachmentTypeEdit } from "./components/APIAttachmentType/Edit";
import { APIAttachmentTypeList } from "./components/APIAttachmentType/List";
import { Notfound } from "./components/Notfound/Notfound";
import './App.css'
class App extends Component {

	componentDidMount(){
				
	}

	render() {
		return (
			<React.Fragment>
				<Navbar />
				<Header />
				{/* Conteúdo principal */}
				<div className="container-fluid">
					<div className="row no-gutters">
						<BrowserRouter>
							{/* <MenuSide/> */}
							<div className="col">
								<MenuHigher/>    
								<Switch>
									<Route exact path="/login" component={Login} />
									<PrivateRoute exact path="/" component={() => <Home />} />
									<PrivateRoute exact path="/generate-key" component={() => <GenerateKey />} />
									<PrivateRoute exact path="/application/new" component={() => <ApplicationEdit />} />
									<PrivateRoute exact path="/application/edit/:id" component={() => <ApplicationEdit />} />
									<PrivateRoute exact path="/application/list" component={() => <ApplicationList />} />
									<PrivateRoute exact path="/application/access/:id" component={() => <ApplicationAccess />} />
									<PrivateRoute exact path="/relatorio-consumo" component={() => <RelatorioConsumo />} />
									<PrivateRoute exact path="/relatorio-consumo-por-metodo" component={() => <RelatorioConsumoPorMetodo />} />
									<PrivateRoute exact path="/relatorio-gerencial" component={() => <RelatorioGerencial />} />
									<PrivateRoute exact path="/relatorio-disponibilidade" component={() => <RelatorioDisponibilidade />} />
									<PrivateRoute exact path="/manager/new" component={() => <ManagerEdit />} />
									<PrivateRoute exact path="/manager/edit/:cpf" component={() => <ManagerEdit />} />
									<PrivateRoute exact path="/manager/list" component={() => <ManagerList />} />
									<PrivateRoute exact path="/manager-profile/new" component={() => <ManagerProfileEdit />} />
									<PrivateRoute exact path="/manager-profile/edit/:id" component={() => <ManagerProfileEdit />} />
									<PrivateRoute exact path="/manager-profile/list" component={() => <ManagerProfileList />} />
									<PrivateRoute exact path="/organization/new" component={() => <OrganizationEdit />} />
									<PrivateRoute exact path="/organization/edit/:id" component={() => <OrganizationEdit />} />
									<PrivateRoute exact path="/organization/list" component={() => <OrganizationList />} />
									<PrivateRoute exact path="/organization/access/:id" component={() => <OrganizationAccess />} />
									<PrivateRoute exact path="/relatorio-auditoria" component={() => <RelatorioAuditoria />} />
									<PrivateRoute exact path="/relatorio-primeiro-consumo-orgao" component={() => <RelatorioPrimeiroConsumoOrgao />} />
									<PrivateRoute exact path="/relatorio-auditoria-generico" component={() => <RelatorioAuditoriaGenerico />} />
									<PrivateRoute exact path="/relatorio-adesao-aplicacao" component={() => <RelatorioAdesaoAplicacao />} />
									<PrivateRoute exact path="/plan/new" component={() => <PlanEdit />} />
									<PrivateRoute exact path="/plan/edit/:idFornecedora/:idPlano" component={() => <PlanEdit />} />
									<PrivateRoute exact path="/plan/list" component={() => <PlanList />} />
									<PrivateRoute exact path="/api/list" component={() => <ApiList />} />
									<PrivateRoute exact path="/api/edit/:id" component={() => <ApiEdit />} />
									<PrivateRoute exact path="/api/attachments/:id" component={() => <APIAttachments />} />
									<PrivateRoute exact path="/api-attachment-type/new" component={() => <APIAttachmentTypeEdit />} />
									<PrivateRoute exact path="/api-attachment-type/edit/:id" component={() => <APIAttachmentTypeEdit />} />
									<PrivateRoute exact path="/api-attachment-type/list" component={() => <APIAttachmentTypeList />} />
									<PrivateRoute exact path="/solicitacao-relatorio-auditoria/new" component={() => <SolicitacaoRelatorioAuditoriaEdit />} />
									<PrivateRoute exact path="/solicitacao-relatorio-auditoria/list" component={() => <SolicitacaoRelatorioAuditoriaList />} />
									<PrivateRoute exact path="/solicitacao-relatorio-auditoria/edit/:id" component={() => <SolicitacaoRelatorioAuditoriaEdit />} />
									<PrivateRoute exact path="/solicitacao-cadastro-whitelist/new" component={() => <SolicitacaoCadastroWhitelistEdit />} />
									<PrivateRoute exact path="/solicitacao-cadastro-whitelist/list" component={() => <SolicitacaoCadastroWhitelistList />} />
									<PrivateRoute exact path="/solicitacao-cadastro-whitelist/edit/:id" component={() => <SolicitacaoCadastroWhitelistEdit />} />
									<PrivateRoute exact path="/whitelist/list" component={() => <WhitelistList />} />
									<PrivateRoute exact path="/solicitacao-adesao/new" component={() => <SolicitacaoAdesaoEdit />} />
									<PrivateRoute exact path="/solicitacao-adesao/list" component={() => <SolicitacaoAdesaoList />} />
									<PrivateRoute exact path="/solicitacao-adesao/edit/:id" component={() => <SolicitacaoAdesaoEdit />} />
									<PrivateRoute exact path="/solicitacao-adesao/view/:id" component={() => <SolicitacaoAdesaoView />} />
									<PrivateRoute exact path="/solicitacao-adesao/approve/:id" component={() => <SolicitacaoAdesaoApproval />} />
									<PrivateRoute exact path="/solicitacao-adesao/sign/:id" component={() => <SolicitacaoAdesaoSigner />} />
									<PrivateRoute exact path="/termo-consentimento/edit" component={() => <ConsentTermEdit />} />
									<PrivateRoute exact path="/relatorio-prestacao-contas" component={() => <RelatorioPrestacaoContas />} />
									<PrivateRoute exact path="/contract/new" component={() => <ContractEdit />} />
									<PrivateRoute exact path="/contract/edit/:id" component={() => <ContractEdit />} />
									<PrivateRoute exact path="/contract/list" component={() => <ContractList />} />
									<PrivateRoute exact path="/contract/api-contract-details/:id" component={() => <APIContractDetails />} />
									<PrivateRoute exact path="/billing-reference/new" component={() => <BillingReferenceEdit />} />
									<PrivateRoute exact path="/billing-reference/edit/:id" component={() => <BillingReferenceEdit />} />
									<PrivateRoute exact path="/billing-reference/list" component={() => <BillingReferenceList />} />
									<PrivateRoute exact path="/billing/new" component={() => <BillingEdit />} />
									<PrivateRoute exact path="/billing/list" component={() => <BillingList />} />
									<PrivateRoute exact path="/billing/agreement/:id" component={() => <BillingAgreement />} />
									<Route path="*" component={() => <Notfound />} />
								</Switch>
							</div>
						</BrowserRouter>
					</div>
				</div>
				{/* footer */}
				<Footer />
			</React.Fragment>
		);
	}

}

export default App;

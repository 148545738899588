const CD_INCLUSAO = 'I';
const OPERACAO_INCLUSAO = {
	codigo: CD_INCLUSAO,
	nome: 'Inclusão'
}

const CD_EXCLUSAO = 'E';
const OPERACAO_EXCLUSAO = {
	codigo: CD_EXCLUSAO,
	nome: 'Exclusão'
}

export {CD_INCLUSAO, CD_EXCLUSAO, OPERACAO_INCLUSAO, OPERACAO_EXCLUSAO}

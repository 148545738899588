import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { Pagination } from "../Pagination/Pagination";

import api from "../../services/api";
import './API.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const ApiList =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				orgaos: [],
				orgao: '',
				fornecedoras: [],
				fornecedorasTodas: [],
				fornecedora: '',
				disponivelAdesaoOrgao: '',
				fornecedorAutorizaInclusaoAdesao: '',
				fornecedorAutorizaAlteracaoAdesao: '',
				carregandoOrgaos: false,
				carregandoFornecedoras: false,
				resultadoPesquisa: {},
				pesquisaExecutada : false,
				exibeModalAtivar: false,
				exibeModalInativar: false,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);

		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>API's</h1>
					</div>
					<div className="div-form1">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			this.setState(
				{
					carregandoOrgaos: true,
					carregandoFornecedoras: true
				}
			);

			api.get('/api/orgao/fornecedores')
			.then(response => { 
				this.setState(
					{
						orgaos: response.data,
						carregandoOrgaos: false
					}
				) 
			})
			.catch(erro => {
				this.setState({carregandoOrgaos : false});
				console.log("Não foi possível recuperar órgãos " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});

			api.get('/api/aplicacao-fornecedora')
			.then(response => { 
				const fornecedoras = response.data;

				this.setState(
					{
						fornecedoras,
						fornecedorasTodas: [...fornecedoras],
						carregandoFornecedoras: false
					}
				)
			 })
			.catch(erro => {
				this.setState({carregandoFornecedoras : false});
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		renderForm() {
			const { resultadoPesquisa, pesquisaExecutada } = this.state;
			const { content } = resultadoPesquisa;
			const numberFormater = new Intl.NumberFormat('pt-BR', {useGrouping: true });

			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="orgao">Órgao Fornecedor:</label>
										{ this.state.orgaos.length > 0 && 
										<select name="orgao" onChange={this.handleOrgaoChange} value={this.state.orgao}>
											<option value="">Todos</option>
											{
												this.state.orgaos.map(orgao =>
													<option 
														key={orgao.id} 
														value={orgao.id}>
														{orgao.nome}
													</option>
											)}
										</select>
										}
										{this.state.carregandoPlanos === false && this.state.orgaos.length === 0 && 'Não há órgãos fornecedores de API'}
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{ this.state.fornecedoras.length > 0 && 
											<select name="fornecedora" onChange={this.handleInputChange} value={this.state.fornecedora}>
												<option value="">Todas</option>
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'O órgão selecionado não é fornecedor de API'}
									</div>
								</div>
							</div>
						</div>
				
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="disponivelAdesaoOrgao">Disponível para adesão dos órgãos:</label>
										<select name="disponivelAdesaoOrgao" value={this.state.disponivelAdesaoOrgao} onChange={this.handleInputChange}>
											<option value="">Todos</option>
											<option value="true">Sim</option>
											<option value="false">Não</option>	
										</select>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="fornecedorAutorizaInclusaoAdesao">Adesão exige aprovação do órgão cedente dos dados:</label>
										<select name="fornecedorAutorizaInclusaoAdesao" value={this.state.fornecedorAutorizaInclusaoAdesao} onChange={this.handleInputChange}>
											<option value="">Todos</option>
											<option value="true">Sim</option>
											<option value="false">Não</option>	
										</select>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="fornecedorAutorizaAlteracaoAdesao">Alteração de volumetria exige aprovação do órgão cedente dos dados:</label>
										<select name="fornecedorAutorizaAlteracaoAdesao" value={this.state.fornecedorAutorizaAlteracaoAdesao} onChange={this.handleInputChange}>
											<option value="">Todos</option>
											<option value="true">Sim</option>
											<option value="false">Não</option>	
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary">Voltar</button>
									</div>
								</Link>
							</div>
						</div>

						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">Órgão Fornecedor</th>
											<th scope="col">API</th>

											<th scope="col" className="text-center" style={{width: "5%"}}>Disponível para Adesão</th>
											<th scope="col" className="text-center" style={{width: "5%"}}>Adesão exige aprovação do órgão cedente</th>
											<th scope="col" className="text-center" style={{width: "5%"}}>Alteração volumetria exige aprovação do órgão cedente</th>


											<th scope="col" className="text-right">Limite Consultas/Ano</th>
											<th scope="col" className="text-right">Total Consumido no Ano</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((fornecedora, index) =>
													<tr key={index} >
														<td>{fornecedora.orgao}</td>
														<td>{fornecedora.nome}</td>
														<td className="text-center">{fornecedora.disponivelAdesaoOrgao ? 'Sim' : 'Não'}</td>
														<td className="text-center">{fornecedora.fornecedorAutorizaInclusaoAdesao ? 'Sim' : 'Não'}</td>
														<td className="text-center">{fornecedora.fornecedorAutorizaAlteracaoAdesao ? 'Sim' : 'Não'}</td>
														{fornecedora.limiteConsultaAnual !== null &&
															<td className="text-right">{numberFormater.format(fornecedora.limiteConsultaAnual)}</td>
														}
														{fornecedora.limiteConsultaAnual === null &&
															<td>&nbsp;</td>
														}
														<td className="text-right">{numberFormater.format(fornecedora.consumoAnoCorrente)}</td>

														<td className="text-center">
															<div className="actions">
																<Link to={`/api/edit/${fornecedora.id}`}>
																	<button type="button" title="Editar as informações da API">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																</Link>
																<Link to={`/api/attachments/${fornecedora.id}`}>
																	<button type="button" title="Editar anexos da API">
																		<span className="sr-only">Anexos</span>
																		<i class="fas fa-paperclip"></i>
																	</button>
																</Link>					
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhuma API encontrada com os critérios informados.
							</div>
						}

					</div>
				</div>

				
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleOrgaoChange(evento){
			const idOrgao =  evento.target.value;
			let fornecedoras = [];
			this.setState({carregandoFornecedoras: true});

			if(idOrgao && idOrgao !== ""){
				this.props.mostraAguardando();
				api.get(`/api/aplicacao-fornecedora/orgao/${idOrgao}`)
				.then(response => { 
					this.setState(
						{
							fornecedoras: response.data,
							carregandoFornecedoras: false
						}
					);
					this.props.escondeAguardando();
				})
				.catch(erro => {
					this.props.escondeAguardando();
					this.setState({carregandoFornecedoras: false});
					console.log("Não foi possível recuperar as aplicações fornecedoras associadas ao órgão " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento das API's do órgão.")
				});
			} else {
				fornecedoras = this.state.fornecedorasTodas;
			}

			this.setState({
				orgao: idOrgao,
				fornecedora: '',
				fornecedoras,
			});
		}


		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada(pagina){

			const {orgao, fornecedora, disponivelAdesaoOrgao, fornecedorAutorizaInclusaoAdesao, fornecedorAutorizaAlteracaoAdesao } = this.state;
			const criterioPesquisa = {orgao, fornecedora, disponivelAdesaoOrgao, fornecedorAutorizaInclusaoAdesao, fornecedorAutorizaAlteracaoAdesao, pagina};
			const url = '/api/aplicacao-fornecedora/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando()
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as API's. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then( () => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			this.setState({
				orgao: '',
				fornecedora: '',
				fornecedoras: [...this.state.fornecedorasTodas],
				disponivelAdesaoOrgao: '',
				fornecedorAutorizaInclusaoAdesao: '', 
				fornecedorAutorizaAlteracaoAdesao: '',
				resultadoPesquisa: {},
				pesquisaExecutada : false,
			});
		}
		
	}

) // redux
); // router
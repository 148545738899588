import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import {formataData} from '../../utils/formatadorData';
import api from "../../services/api";
import './BillingReference.css';
import DatePicker , { registerLocale } from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
registerLocale("pt_br", pt_br);

export const BillingReferenceEdit =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				referenciaFaturamento: {
					id: undefined,
					idContrato: "",
					referencia: "",
					periodoInicial: "",
					periodoFinal: "",
				},
				contratos: [],
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Referências de Faturamento</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				api.get('/api/referencia-faturamento/' + id)
					.then(response => { 
						const referenciaFaturamento = response.data;
						
						this.setState(
							{
								referenciaFaturamento,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar a referência de faturamento ", erro)
						this.props.exibeMensagemErro("Erro durante o carregamento da página")
					});
			}

			this.props.mostraAguardando();
				api.get('/api/contrato/ativos')
					.then(response => { 
						const contratos = response.data;
						this.props.escondeAguardando();
						this.setState(
							{
								contratos,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar os contratos ", erro)
						this.props.exibeMensagemErro("Erro durante o carregamento da página")
						this.props.escondeAguardando();
					});
		}

		renderForm() {
			return(
				<div className="container-fluid">
					<div className="br-form">

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="contrato">Contrato:</label>
										<select id="contrato" onChange={
											evento => {
												const referenciaFaturamento = this.state.referenciaFaturamento;
												referenciaFaturamento['idContrato'] = evento.target.value;
												this.setState({referenciaFaturamento})}
												} 
												value={this.state.referenciaFaturamento.idContrato}
												>
												<option value="">Selecione</option>
												{
													this.state.contratos.map(contrato =>
														<option 
															key={contrato.id} 
															value={contrato.id}>
															({contrato.idTipoContrato}) {contrato.nomeContrato} => {formataData(contrato.inicioVigencia)} a {formataData(contrato.fimVigencia)}
														</option>
												)}
											</select>
									</div>
								</div>
							</div>	
						</div>
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="referencia">Mês de Referência:</label>
										<DatePicker id="referencia" locale="pt_br" dateFormat="MM/yyyy" 
											showMonthYearPicker 
											selected={this.state.referenciaFaturamento.referencia} 
											onChange={data => {
												const referenciaFaturamento = this.state.referenciaFaturamento;
												referenciaFaturamento['referencia'] = data;
												this.setState({referenciaFaturamento})}
											} />
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="periodoInicial">Período inicial:</label>
										<DatePicker id="periodoInicial" locale="pt_br" dateFormat="dd/MM/yyyy" 
											selected={this.state.referenciaFaturamento.periodoInicial} 
											onChange={data => {
												const referenciaFaturamento = this.state.referenciaFaturamento;
												referenciaFaturamento['periodoInicial'] = data;
												this.setState({referenciaFaturamento})}
											} />
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="periodoFinal">Período Final:</label>
										<DatePicker id="periodoFinal" locale="pt_br" dateFormat="dd/MM/yyyy" 
											selected={this.state.referenciaFaturamento.periodoFinal} 
											onChange={data => {
												const referenciaFaturamento = this.state.referenciaFaturamento;
												referenciaFaturamento['periodoFinal'] = data;
												this.setState({referenciaFaturamento})}
											} />
									</div>
								</div>
							</div>
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.referenciaFaturamento.id ? 'Alterar' : 'Incluir'}</button>

								{this.state.referenciaFaturamento.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Nova Referência de Faturamento</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const referenciaFaturamento = this.state.referenciaFaturamento;
			referenciaFaturamento[nome] = valor;
			this.setState({ referenciaFaturamento });
		}

		novo(e){
			const rotaParaNovo = "/billing-reference/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const referenciaFaturamento = this.state.referenciaFaturamento;
			const method = referenciaFaturamento.id ? 'put' : 'post';
			let url = "/api/referencia-faturamento";
			if(referenciaFaturamento.id){
				url = url + '/' + referenciaFaturamento.id;
			}
			const options = {
				method,
				url,
				"data" : referenciaFaturamento,
			}

			api(options)
				.then(response => {
					const mensagem = `Referência de Faturamento ${this.state.referenciaFaturamento.id ? 'alterado' : 'incluído'} com sucesso!`;
					this.setState({ 
						referenciaFaturamento: response.data,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.referenciaFaturamento.id ? 'alterar' : 'incluir'} a referência de faturamento.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});   

		}

		validaFormulario(){
			let mensagens = [];
			const contrato = this.state.referenciaFaturamento.idContrato;
			if(!contrato){
				mensagens.push({mensagem: "Contrato é obrigatório."})
			}

			const referencia = this.state.referenciaFaturamento.referencia;
			if(!referencia){
				mensagens.push({mensagem: "Referência é obrigatória."})
			}

			let periodoInicial = this.state.referenciaFaturamento.periodoInicial;
			if(!periodoInicial){
				mensagens.push({mensagem: "Período Inicial é obrigatório."})
			}

			let periodoFinal = this.state.referenciaFaturamento.periodoFinal;
			if(!periodoFinal){
				mensagens.push({mensagem: "Período Final é obrigatório."})
			}

			if(periodoInicial && periodoFinal){
				periodoInicial = new Date(periodoInicial);
				periodoFinal = new Date(periodoFinal);

				if(periodoInicial > periodoFinal){
					mensagens.push({mensagem: "O Período Inicial deve ser menor ou igual ao Período Final."})
				} else {
					var diff = Math.floor(periodoFinal.getTime() - periodoInicial.getTime());
					var day = 1000 * 60 * 60 * 24;
					
					if((diff/day) > 30){
						mensagens.push({mensagem: "O Período deve ser menor ou igual a 31 dias."})
					}

					if(periodoInicial.getYear() !== periodoFinal.getYear()){
						mensagens.push({mensagem: "O Período Inicial e o Período Final devem estar no mesmo ano."})
					}
				}
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
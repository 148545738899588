import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, exibeMensagemAviso, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { SubscriberStatus } from "../Subscriber/Status"
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { AumentaNivelGovBr } from "../AumentaNivelGovBr/AumentaNivelGovBr";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import {getUrlAutorizacaoIti, getUrlRetorno} from "../../utils/itiUtil"
import { strTobase64, base64ToStr } from "../../utils/base64Util";
import qs from 'query-string';
import './GenerateKey.css';
import { baixarArquivo } from "../../utils/downloadUtil";
import { InformacaoCertificado } from "../AumentaNivelGovBr/InformacaoCertificado";
const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, exibeMensagemAviso, limpaMensagem, mostraAguardando, escondeAguardando };
const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user
	}
}

const pathRetorno = "/generate-key";

export const GenerateKey =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				consumidoras: [],
				consumidora: '0',
				fornecedoras: [],
				fornecedora: '0',
				tiposCertificado: [],
				tipoCertificado: '0',
				orgaos: [],
				orgao: '0',
				textoOriginal: '',
				numeroTermo: '',
				idTermo: 0,
				texto: '',
				chave: '',
				senha: '',
				botoesHabilitados : true,
				carregandoOrgaos: false,
				carregandoConsumidoras: false,
				carregandoFornecedoras: false,
				chaveGeradaSucesso: false,
			};
			this.intervalID = 0;
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleGeraChave = this.handleGeraChave.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.handleFornecedorChange = this.handleFornecedorChange.bind(this);
			this.handleConsumidorChange = this.handleConsumidorChange.bind(this);
			this.preparaGerarNovaChave = this.preparaGerarNovaChave.bind(this);
			this.mostraTermoResponsabilidade = this.mostraTermoResponsabilidade.bind(this);
		}

		render() {
			const consumidora = this.state.consumidora;
			const fornecedora = this.state.fornecedora;
			const tipoCertificado = this.state.tipoCertificado;

			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Gestão de Chaves de Acesso</h1>
					</div>

					{ consumidora && consumidora !== '0' && fornecedora && fornecedora !== '0' && tipoCertificado === this.state.ID_CERTIFICADO_ICPBRASIL &&
						<div className="status">
							<SubscriberStatus/>
						</div>
					}

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			
			const user = this.props.user;
			const state = localStorage.getItem('state');
			localStorage.removeItem('state');

			const urlParams = qs.parse(this.props.location.search);
			const code = urlParams.code;
			const error = urlParams.error;
			
			if((code || error) && state){
				const objState = JSON.parse(base64ToStr(state));
				this.setState(objState);

				if(!error){
					const body = {
						aplicacaoFornecedora : objState.fornecedora, 
						aplicacaoConsumidora : objState.consumidora,
						orgao: objState.orgao,
						numeroTermo: objState.numeroTermo,
						tipoCertificado: objState.tipoCertificado,
						urlRetorno: getUrlRetorno(pathRetorno),
						code
					}

					this.geraChave(body);
				}
				return;
			} 

			if(user.isCliente === true) {
				this.carregaAplicacoesConsumidoras();
			}
			this.carregaTiposCertificadosDigitais();
			this.carregaTermoResponsabilidade();
			if(user.isAdministrador === true){
				this.carregaOrgaos();
			}
		}

		renderForm() {
			const user = this.props.user;
			const orgao = this.state.orgao;
			return(
				<div className="container-fluid">

					<AumentaNivelGovBr disabled={!this.state.botoesHabilitados}/>

					<div className="br-form">

						{user.isAdministrador === true &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="orgao">Órgao:</label>
											<select id="orgao" name="orgao" onChange={this.handleOrgaoChange} value={this.state.orgao}>
												<option value="0">Selecione uma opção</option>
												{
													this.state.orgaos?.map(orgao =>
														<option 
															key={orgao.id} 
															value={orgao.id}>
															{orgao.nome}
														</option>
												)}
											</select>
											{this.state.carregandoOrgaos === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							</div>
						}

						{ (user.isCliente === true || (user.isAdministrador === true && orgao !== '0')) &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="consumidora">Aplicação:</label>
											{this.state.consumidoras.length > 0 &&
												<select name="consumidora" onChange={this.handleConsumidorChange} value={this.state.consumidora}>
													<option value="0">Selecione uma opção</option>
													{
														this.state.consumidoras.map(consumidora =>
															<option 
																key={consumidora.id} 
																value={consumidora.id}>
																{consumidora.nome}
															</option>
													)}
												</select>
											}
											{this.state.carregandoConsumidoras === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}

											{this.state.carregandoConsumidoras === false && this.state.consumidoras.length === 0 && 'Órgão não possui aplicações cadastradas'}
										</div>
									</div>
								</div>
								
								<div className="col-md">
									<div className="field">
										{this.state.consumidora !== '0' &&
										<div className="input">
											<label>API:</label>
											{this.state.fornecedoras.length > 0 &&
											<select name="fornecedora" onChange={this.handleFornecedorChange} disabled={this.state.fornecedoras.length === 0} value={this.state.fornecedora}>
												<option value="0">Selecione uma opção</option>
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
											}
											{this.state.carregandoFornecedoras === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}

											{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'Aplicação não possui adesões cadastradas'}
										</div>
										}
									</div>
								</div>
							</div>
						}
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">
											Certificado: &nbsp;
											<InformacaoCertificado/>
										</label>
										<select name="tipoCertificado" onChange={this.handleInputChange} value={this.state.tipoCertificado} disabled={! this.habilitaTipoCertificado()}>
											{
												this.state.tiposCertificado.map(tipoCertificado =>
													<option 
														key={tipoCertificado.id} 
														value={tipoCertificado.id}>
														{tipoCertificado.nome}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">  
								<div className="field">
									<div className="input"> 
										<label htmlFor="texto">Termo de responsabilidade:</label>
										<textarea name="texto" value={this.state.texto} onChange={this.handleInputChange} readOnly disabled />
									</div>
								</div>
							</div>
						</div>
						
						{/* resultado da geração de chaves */}
						{
							(this.state.chave || this.state.senha) &&
							<div className="row">
									{this.state.chave &&
										<div className="col-md"> 
											<div className="field">
												<div className="input">
													<label htmlFor="chave">Chave:</label>
													<input type="text" value={this.state.chave} readOnly/>
												</div>
											</div>
										</div>
									}
									{this.state.senha &&
									<div className="col-md"> 
										<div className="field">
											<div className="input">
												<label htmlFor="senha">Senha:</label>
												<input type="text" value={this.state.senha} readOnly/>
											</div>
										</div>
									</div>
									}
							</div>
						}

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								{! this.state.chaveGeradaSucesso &&
								<button type="button" className="button is-primary" onClick={this.handleGeraChave} disabled={!this.state.botoesHabilitados}>Gerar Chave</button>
								}
								{this.state.chaveGeradaSucesso &&
								<button type="button" className="button is-primary" onClick={this.mostraTermoResponsabilidade}>Termo de Responsabilidade</button>
								}
								{this.state.chaveGeradaSucesso &&
								<button type="button" className="button is-secondary" onClick={this.preparaGerarNovaChave}>Gerar Nova Chave</button>
								}
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		carregaOrgaos() {
			this.setState({carregandoOrgaos : true});
			api.get('/api/orgao/perfil-usuario?ativos=true')
				.then(response => { 
					this.setState(
						{orgaos: response.data}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os órgãos " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				})
				.finally(() => {
					this.setState({carregandoOrgaos : false});
				});
		}

		carregaAplicacoesConsumidoras(orgao){
			this.setState({carregandoConsumidoras : true});
			api.get(`/api/aplicacao-consumidora/orgao/aptas_gerar_chave?orgao=${orgao ? orgao : ''}`)
				.then(response => { 
					this.setState(
						{consumidoras: response.data}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar consumidoras " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				})
				.finally(() => {
					this.setState({carregandoConsumidoras : false});
				});
		}

		carregaTiposCertificadosDigitais(){
			api.get('/api/chave-acesso-api/tipos-certificados')
				.then(response => { 
					const tiposCertificado = response.data;
					let ID_CERTIFICADO_ICPBRASIL = null;
					let ID_CERTIFICADO_GOVBR = null;

					for(var tipo of tiposCertificado){
						if(tipo['icpBrasil'] === true){
							ID_CERTIFICADO_ICPBRASIL = String(tipo.id);
						}

						if(tipo['govbr'] === true){
							ID_CERTIFICADO_GOVBR = String(tipo.id);
						}
					}
					this.setState({tiposCertificado, tipoCertificado: ID_CERTIFICADO_ICPBRASIL, ID_CERTIFICADO_ICPBRASIL, ID_CERTIFICADO_GOVBR}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar tipos certificados digitais " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		carregaTermoResponsabilidade(){
			api.get('/api/chave-acesso-api/texto-termo-responsabilidade')
				.then(response => { 
					let {texto, numeroTermo} = response.data;
					this.setState(
						{
							textoOriginal : texto,
							texto : this.preencheFornecedoraConsumidora(texto, '', ''),
							numeroTermo,
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar o texto do termo de responsabilidade " + JSON.stringify(erro))
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		getNomeConsumidora(id){
			if(id){
				const consumidoras = this.state.consumidoras.filter((c) => c.id === Number(id));
				const consumidora = consumidoras[0];
				if(consumidora){
					return consumidora.nome;
				}
			}
			return '';
		}

		getFornecedora(id){
			if(id){
				const fornecedoras = this.state.fornecedoras.filter((c) => c.id === Number(id));
				const fornecedora = fornecedoras[0]
				if(fornecedora){
					return fornecedora;
				}
			}
			return null;
		}

		getNomeFornecedora(id){
			const fornecedora = this.getFornecedora(id);
			if(fornecedora){
				return fornecedora.nome;
			}
			return '';
		}

		isAPI_RFB(id){
			const fornecedora = this.getFornecedora(id);
			if(fornecedora){
				return fornecedora.tipoApiRfb != null;
			}
			return false;
		}

		handleOrgaoChange(evento){
			const orgao =  evento.target.value;
			const texto = this.getTexto("", "");

			this.setState({
				orgao,
				consumidoras: [],
				consumidora: "0",
				fornecedoras : [],
				fornecedora : "0",
				tipoCertificado: this.state.ID_CERTIFICADO_ICPBRASIL,
				texto: texto
			});
			if(orgao && orgao !== "0"){
				this.carregaAplicacoesConsumidoras(orgao);
			}
		}

		handleConsumidorChange(evento){
			const idConsumidora =  evento.target.value;
			const nomeConsumidora = this.getNomeConsumidora(idConsumidora);
			const texto = this.getTexto("", nomeConsumidora);

			this.setState({
				fornecedoras : [],
				fornecedora : "0",
				consumidora: idConsumidora,
				tipoCertificado: this.state.ID_CERTIFICADO_ICPBRASIL,
				texto: texto
			});
			if(idConsumidora && idConsumidora !== "0"){
				this.setState({carregandoFornecedoras : true});
				api.get(`/api/aplicacao-consumidora/${idConsumidora}/aplicacao-fornecedora/adesoes`)
				.then(response => { 
					this.setState(
						{
							fornecedoras: response.data,
						}
					);
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as adesões associadas à aplicação consumidora " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento dos planos das api's.")
				})
				.finally(() => {
					this.setState({carregandoFornecedoras : false});
				});
			}
		}

		isNivelConfiabilidadeOuroPrata() {
			const nivelConfiabilidade = this.props.user.nivelConfiabilidade;
			return (nivelConfiabilidade === 'OURO' || nivelConfiabilidade === 'PRATA' );
		}

		habilitaTipoCertificado() {
			const ouroPrata = this.isNivelConfiabilidadeOuroPrata();
			const fornecedora = this.state.fornecedora;
			const apiRFB = this.isAPI_RFB(fornecedora);
			return ouroPrata && fornecedora && fornecedora !== '0' && ! apiRFB;
		}

		handleFornecedorChange(evento){
			const ouroPrata = this.isNivelConfiabilidadeOuroPrata();
			const idFornecedora =  evento.target.value;
			const nomeFornecedora = this.getNomeFornecedora(idFornecedora);
			const apiRFB = this.isAPI_RFB(idFornecedora);
			const nomeConsumidora = this.getNomeConsumidora(this.state.consumidora);
			const texto = this.getTexto(nomeFornecedora, nomeConsumidora);
			const tipoCertificado = ouroPrata && ! apiRFB ? this.state.ID_CERTIFICADO_GOVBR : this.state.ID_CERTIFICADO_ICPBRASIL;

			this.setState({
				fornecedora: idFornecedora,
				tipoCertificado,
				texto: texto
			});
		}

		getTexto(nomeFornecedora, nomeConsumidora){
			return this.preencheFornecedoraConsumidora(this.state.textoOriginal, nomeFornecedora, nomeConsumidora);
		}

		preencheFornecedoraConsumidora(texto, nomeFornecedora, nomeConsumidora){
			return texto.replace('{fornecedora}', nomeFornecedora).replace('{consumidora}', nomeConsumidora);
		}


		handleGeraChave(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const tipoCertificado = this.state.tipoCertificado;
			if(tipoCertificado === this.state.ID_CERTIFICADO_ICPBRASIL) {
				this.geraChaveToken();
			} else if(tipoCertificado === this.state.ID_CERTIFICADO_GOVBR) {
				this.geraChaveAPIGovbr();
			}
		}

		geraChaveAPIGovbr(){
			api.get('/api/chave-acesso-api/verifica-token-iti')
				.then(response => { 
					const possuiTokenAtivo = response.data;
					if(possuiTokenAtivo === true) {
						const body = {
							aplicacaoFornecedora : this.state.fornecedora, 
							aplicacaoConsumidora : this.state.consumidora,
							numeroTermo: this.state.numeroTermo,
							tipoCertificado: this.state.tipoCertificado,
							tokenItiAtivo: true,
						}
	
						this.geraChave(body);
					}else {
						this.obtemAutorizacaoIti();
					}
				})
				.catch(erro => {
					console.log("Não foi possível verificar o token iti do usuário", JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante a verificação do token do usuário");
				});
		}

		obtemAutorizacaoIti() {
			const state = strTobase64(JSON.stringify(this.state));
			localStorage.setItem('state', state);
			window.location.replace(getUrlAutorizacaoIti(pathRetorno))
		}

		geraChaveToken(){
			this.setState({botoesHabilitados:false});
			this.props.mostraAguardando();
			const textoBase64 = strTobase64(this.state.texto);
			
			window.SerproSignerClient.sign('text', textoBase64, 'UTF-8', false)
				.success((response)=>{
					const body = {
						aplicacaoFornecedora : this.state.fornecedora, 
						aplicacaoConsumidora : this.state.consumidora,
						assinatura: response.signature,
						numeroTermo: this.state.numeroTermo,
						tipoCertificado: this.state.tipoCertificado,
					}

					this.geraChave(body);
				})
				.error((e)=>{
					let mensagem = e;
					if(e.error){
						mensagem = e.error;
					}
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
					this.props.exibeMensagemErro("Não foi possível gerar as chaves para os parâmetros informados. " + mensagem);
					console.log(mensagem);
				});
		}

		geraChave(body) {
			this.setState({botoesHabilitados:false});
			this.props.mostraAguardando();
			api.post('/api/chave-acesso-api/gerar', body)
				.then(response => {
					const {idTermo, credencial, limiteConsumoUltrapassado } = response.data;
					const {id, secret } = credencial;
					this.setState({ chave: id, senha: secret, idTermo, chaveGeradaSucesso: true });

					let mensagem = "Chave gerada com Sucesso! Atenção: É altamente recomendada a guarda do termo de responsabilidade.";
					if(limiteConsumoUltrapassado){
						mensagem += " Observações complementares:"
						let mensagensComplementares = [];
						mensagensComplementares.push({mensagem: "O plano de consumo disponibilizado para o órgão consumir essa API já foi utilizado."});
						mensagensComplementares.push({mensagem: "Para utilizar credencial é necessário esperar o próximo ciclo de consumo ou incrementar o plano de consumo do órgão para essa API."});
						this.props.exibeMensagemAviso(mensagem, mensagensComplementares)
					} else {
						this.props.exibeMensagemSucesso(mensagem);
					}
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível gerar chaves:", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		

		validaFormulario(){
			let mensagens = [];
			const user = this.props.user;
			const tipoCertificado = this.state.tipoCertificado;

			if(!tipoCertificado){
				mensagens.push({mensagem: "Certificado é obrigatório."})
			}

			let assinadorConectado = window.SerproSignerClient && window.SerproSignerClient.isConnected() === true;

			if (this.state.ID_CERTIFICADO_ICPBRASIL === tipoCertificado && ! assinadorConectado){
				this.props.exibeMensagemErro("Assinador Serpro não está ativo.");
				throw new Error("Assinador Serpro inativo.");
			} 

			if(user.isAdministrador && (!this.state.orgao || this.state.orgao === "0")){
				mensagens.push({mensagem: "Órgão é obrigatório."})
			} else {
				if(!this.state.fornecedora || this.state.fornecedora === "0"){
					mensagens.push({mensagem: "API é obrigatório."})
				}
				if(! this.state.consumidora || this.state.consumidora === "0"){
					mensagens.push({mensagem: "Aplicação é obrigatório."})
				}
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há campos obrigatórios não preenchidos:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		preparaGerarNovaChave(){
			this.props.limpaMensagem();
			this.carregaTermoResponsabilidade();
			const consumidoras = this.props.user.isCliente === true ? this.state.consumidoras: [];
			this.setState({
				orgao: '0',
				consumidoras,
				consumidora: '0',
				fornecedoras: [],
				fornecedora: '0',
				tipoCertificado: this.state.ID_CERTIFICADO_ICPBRASIL,
				chave: '',
				senha: '',
				chaveGeradaSucesso: false,
				idTermo: 0,
			});
		}

		mostraTermoResponsabilidade(){
			const options = {
				'method' : 'get',
				url: `/api/termo/${this.state.idTermo}/pdf`,
				responseType: 'arraybuffer',
			}

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), 'TermoResponsabilidade.pdf');
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o pdf do termo de responsabilidade';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				});
		}

	}

) // redux
); // router
import React from 'react';
import './Notfound.css';

export function Notfound() {
  return(
    <React.Fragment>
      <main id="content" className="page-content p-3">
        <div className="not-found">
          <div className="row justify-content-center">
            <div className="col-sm mt-3">
              {/* image notfound page */}
              <img src={require('../../images/unhappy-face.png')} alt="notfound"/>
            </div>
            <div className="col-sm text-center text-sm-left">
              <p className="not-found-title">Desculpe!</p>
              <p className="not-found-message mt-3">A página que você está procurando não existe.<br className="d-none" /> Pesquise o que deseja ou acesse os links sugeridos.</p>
            </div>
          </div>
          
          <div className="row mt-8 hint justify-content-around"> 
            <div className="col-sm-auto">
              <div className="box">
                <div className="icon text-center">
                  <i className="fas fa-home fa-2x" aria-hidden="true"></i>
                </div>
                <div className="content">
                  <span className="title">Home</span>
                  <p className="message mb-0 mt-1">
                    <span className="d-sm-none">Página inicial</span>
                    <span className="d-none d-sm-block">Acesse a página inicial</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-auto vertical-divider">
              <hr className="d-block d-sm-none" />
            </div>

            <div className="col-sm-auto">
              <div className="box">
                <div className="icon text-center">
                  <i className="fas fa-question fa-2x" aria-hidden="true"></i>
                </div>
                <div className="content">
                  <span className="title">Ajuda</span>
                  <p className="message mb-0 mt-1">
                    <span className="d-sm-none">Tópicos para ajudar você</span>
                    <span className="d-none d-sm-block">Acesse tópicos pra ajudar você<br/>a encontrar o que deseja</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-auto d-sm-none">
              <hr className="d-block d-sm-none" />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { AumentaNivelGovBr } from "../AumentaNivelGovBr/AumentaNivelGovBr";
import { SubscriberStatus } from "../Subscriber/Status"
import PdfViewer from "../PdfViewer";
import api from "../../services/api";
import './PdfSigner.css';
import { getUrlAutorizacaoIti } from "../../utils/itiUtil"
import { strTobase64, base64ToStr } from "../../utils/base64Util";
import { InformacaoCertificado } from "../AumentaNivelGovBr/InformacaoCertificado";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user
	}
}

const CryptoJS = require("crypto-js");

export const PdfSigner =
withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
                tiposCertificado: [],
				tipoCertificado: '0',
			};

			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleAssinarPdf = this.handleAssinarPdf.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			const state = localStorage.getItem('state');

			if(state){
				const objState = JSON.parse(base64ToStr(state));
				this.setState(objState);
				return;
			}

            this.carregaTiposCertificadosDigitais();
		}

		renderForm() {
            const {tipoCertificado } = this.state;
			const { pdf, nomeArquivo, botoesHabilitados, assinaturaHabilitada } = this.props;
			return(
				<div className="container-fluid">

					<AumentaNivelGovBr disabled={!botoesHabilitados}/>

					{ tipoCertificado === this.state.ID_CERTIFICADO_ICPBRASIL &&
						<div className="status">
							<SubscriberStatus/>
						</div>
					}

					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="tipoCertificado">
											Certificado: &nbsp;
											<InformacaoCertificado/>
										</label>
										<select name="tipoCertificado" onChange={this.handleInputChange} value={this.state.tipoCertificado} disabled={this.isNivelConfiabilidadeBronze()}>
											{
												this.state.tiposCertificado?.map(tipoCertificado =>
													<option 
														key={tipoCertificado.id} 
														value={tipoCertificado.id}>
														{tipoCertificado.nome}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="container-pdf-viewer">
							<PdfViewer arquivo={pdf} nomeArquivo={nomeArquivo}/>
						</div>
						<br/>

						<div className="actions-button">
							<div className="actions justify-content-start">
								{assinaturaHabilitada &&
									<button type="button" className="button is-primary" onClick={this.handleAssinarPdf} disabled={!botoesHabilitados}>Assinar</button>
								}
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			);
		}

        carregaTiposCertificadosDigitais(){
			api.get('/api/chave-acesso-api/tipos-certificados')
				.then(response => { 
					const tiposCertificado = response.data;
					let ID_CERTIFICADO_ICPBRASIL = null;
					let ID_CERTIFICADO_GOVBR = null;

					for(var tipo of tiposCertificado){
						if(tipo['icpBrasil'] === true){
							ID_CERTIFICADO_ICPBRASIL = String(tipo.id);
						}

						if(tipo['govbr'] === true){
							ID_CERTIFICADO_GOVBR = String(tipo.id);
						}
					}
					const tipoCertificado = this.isNivelConfiabilidadeBronze() ? ID_CERTIFICADO_ICPBRASIL : ID_CERTIFICADO_GOVBR;

					this.setState({tiposCertificado, tipoCertificado, ID_CERTIFICADO_ICPBRASIL, ID_CERTIFICADO_GOVBR}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de certificados digitais " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		isNivelConfiabilidadeBronze() {
			return this.props.user.nivelConfiabilidade === 'BRONZE';
		}

		handleAssinarPdf(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const tipoCertificado = this.state.tipoCertificado;
			if(tipoCertificado === this.state.ID_CERTIFICADO_ICPBRASIL) {
				this.assinarPdfComToken();
			} else if(tipoCertificado === this.state.ID_CERTIFICADO_GOVBR) {
				this.assinarPdfComAPIGovbr();
			}
		}

		assinarPdfComAPIGovbr(){
			api.get('/api/chave-acesso-api/verifica-token-iti')
				.then(response => { 
					const possuiTokenAtivo = response.data;
					if(possuiTokenAtivo === true) {
						const body = {
							tipoCertificado: this.state.tipoCertificado,
							tokenItiAtivo: true,
						}

						this.props.onAssinar(body);
					}else {
						this.obtemAutorizacaoIti();
					}
				})
				.catch(erro => {
					console.log("Não foi possível verificar o token iti do usuário", JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante a verificação do token do usuário");
				});
		}

		obtemAutorizacaoIti() {
			const stateCopy = {...this.state, ...this.props.stateParent()};
			const state = strTobase64(JSON.stringify(stateCopy));
			localStorage.setItem('state', state);
			window.location.replace(getUrlAutorizacaoIti(this.props.pathRetorno))
		}

		getHashEmBase64(message){
			const messageBuffer = Buffer.from(message, 'base64').buffer;
			const wordArray = CryptoJS.lib.WordArray.create(messageBuffer);
			const hash = CryptoJS.SHA256(wordArray);
			return CryptoJS.enc.Base64.stringify(hash);
		}

		assinarPdfComToken () {
			this.setState({botoesHabilitados:false});
			this.props.mostraAguardando();
			const { pdf } = this.props;
			const base64 = this.getHashEmBase64(pdf);
			window.scrollTo(0, 0);

			window.SerproSignerClient.sign('hash', base64, 'UTF-8', false)
				.success((response)=>{
					const body = {
						assinatura: response.signature,
						tipoCertificado: this.state.tipoCertificado,
					}

					this.props.onAssinar(body);
				})
				.error((e)=>{
					let mensagem = e;
					if(e.error){
						mensagem = e.error;
					}
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
					this.props.exibeMensagemErro("Não foi possível realizar a assinatura. " + mensagem);
					console.log(mensagem);
				});
		}

		validaFormulario(){
			let mensagens = [];
			const tipoCertificado = this.state.tipoCertificado;
			if(!tipoCertificado){
				mensagens.push({mensagem: "Certificado é obrigatório."})
			}

			let assinadorConectado = window.SerproSignerClient && window.SerproSignerClient.isConnected() === true;

			if (this.state.ID_CERTIFICADO_ICPBRASIL === tipoCertificado && ! assinadorConectado){
				this.props.exibeMensagemErro("Assinador Serpro não está ativo.");
				throw new Error("Assinador Serpro inativo.");
			} 
			
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há campos obrigatórios não preenchidos:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

    }
) // redux
); // router
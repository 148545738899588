import React from 'react';
import Search from '../Search/Search';
import './Header.css';
import config from '../../config';
export function Header() {
  return (
    <React.Fragment>
      {/* Cabeçalho da página */}
      <header id="header" className="page-header">
        {/* Versão desktop */}
        <div className="desktop-header d-none d-md-block">
          <div className="bg-gray-2">
            <div className="container-fluid">
              <div className="row align-items-center py-2">
                {/* log conecetagov */}
                <div className="col">
                  <div className="row align-items-center">
                    {/* logo conectagov */}
                    <div>
                      {config.govbrOcultaLogotipo === 'false' &&
                      <img className="logo__item" src={require('../../images/conectagov.png')} alt="ConectaGOV"/>
                      }
                    </div>
                  </div>
                </div>
                {/* form to search */}
                <div className="col">
                  <div className="row align-items-center">
                    <Search />
                  </div>
                </div>
                {/* accessibility */}
                <div className="col-sm-4 col-md-4 col-xl-3">
                  <div className="accessibility">
                    <a id="contrast" href="http://www.vlibras.gov.br/">
                      <span className="accessibility-icon">
                        <i className="fas fa-adjust fa-lg"></i>
                      </span>
                      <span className="accessibility-text d-none d-sm-inline">
                        <span>Alto Contraste</span>
                      </span>
                    </a>
                    <a id="vlibras" href="http://www.vlibras.gov.br/">
                      <span className="accessibility-icon">
                        <i className="fas fa-assistive-listening-systems fa-lg"></i>
                      </span>
                      <span className="accessibility-text d-none d-sm-inline">
                        <span>VLibras</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

import React from 'react';
import './Navbar.css';

export function Navbar() {
  return (
    <React.Fragment>
      <div id="barra-brasil">
        <ul id="menu-barra-temp">
          <li>
            <a href="http://brasil.gov.br">Portal do Governo Brasileiro</a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

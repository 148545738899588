import config from "../config";

const itiUrl = config.itiUrl;
const itiServicoAutorizacao = '/oauth2.0/authorize';
const itiResponseType = 'code';
const itiClientId = config.itiClientId;
const itiScope = 'signature_session';
const ItiRedirectUri = config.itiRedirectUri;


export const getUrlAutorizacaoIti = (path) => {
  const urlAutorizacaoIti = itiUrl + itiServicoAutorizacao
  + '?response_type=' + itiResponseType 
  + '&client_id=' + itiClientId
  + '&scope=' + itiScope
  + '&redirect_uri='
  + getUrlRetorno(path);

  return urlAutorizacaoIti;
}

export const getUrlRetorno = (path) => {
    return ItiRedirectUri + path;
}


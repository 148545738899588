const CD_AGUARDANDO_AUTORIZACAO = 'AA';
const SITUACAO_AGUARDANDO_AUTORIZACAO = {
	codigo: CD_AGUARDANDO_AUTORIZACAO,
	nome: 'Aguardando Autorização'
}

const CD_AUTORIZADA = 'AU';
const SITUACAO_AUTORIZADA = {
	codigo: CD_AUTORIZADA,
	nome: 'Autorizada'
}

const CD_EM_PROCESSAMENTO = 'EP';
const SITUACAO_EM_PROCESSAMENTO = {
	codigo: CD_EM_PROCESSAMENTO,
	nome: 'Em Processamento'
}

const CD_PROCESSADA = 'PR';
const SITUACAO_PROCESSADA = {
	codigo: CD_PROCESSADA,
	nome: 'Processada'
}

export {CD_AGUARDANDO_AUTORIZACAO, CD_AUTORIZADA, CD_EM_PROCESSAMENTO, CD_PROCESSADA, SITUACAO_AGUARDANDO_AUTORIZACAO, SITUACAO_AUTORIZADA, SITUACAO_EM_PROCESSAMENTO, SITUACAO_PROCESSADA}

const getDataformatada = function(data, dateFormatOptions){
    if(!data){
        return null;
    }
    const dateFormater = new Intl.DateTimeFormat('pt-BR', dateFormatOptions);

    return dateFormater.format(data);
};


const formataData = function(data){
    const dateFormatOptions = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour12: false
      };
    
      return getDataformatada(data, dateFormatOptions);
};


const formataDataMesAno = function(data){
    const dateFormatOptions = {
        year: 'numeric', month: 'numeric',
        hour12: false
      };

      return getDataformatada(data, dateFormatOptions);
};

const formataDataHora = function(data){
    const dateTimeFormatOptions = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false
      };

      return getDataformatada(data, dateTimeFormatOptions);
};

const formataDataHoraComMilisegundos = function(data){
    const dateTimeMillisecondsFormatOptions = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        fractionalSecondDigits: 3,
        hour12: false
      };

    let dataFormatada = getDataformatada(data, dateTimeMillisecondsFormatOptions);
    if(dataFormatada)
        return dataFormatada.replace(',', '.');

    return dataFormatada;
};

export {formataData, formataDataHora, formataDataMesAno, formataDataHoraComMilisegundos}
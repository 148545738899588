import React, { Component } from "react";
import { connect } from 'react-redux';
import { login, setTermoConsentimentoMaisRecente } from "../../providers/actions";
import api from "../../services/api";
import qs from 'query-string';
import './Login.css';
import config from "../../config";
import { base64ToStr } from "../../utils/base64Util";

const mapDispatchToProps = { login, setTermoConsentimentoMaisRecente }

export const Login =

// redux
connect(
	null,
	(mapDispatchToProps)
)(
	class extends Component {

		_isMounted = false;

		constructor(props){
			super(props);
			this.state = {
				isLoading: false,
				error: ""
			};
			this.handleLogin = this.handleLogin.bind(this);
		}

		render() {
			return(
				<div>
					{this.state.isLoading && 
						<div className="load-message">
							<div className="loader"></div>
							{/* <p>Efetuando login...</p> */}
						</div>
					}

					{this.state.error &&
						<div className="br-alert is-danger" role="alert">
							<div className="icon">
								<i className="fas fa-times-circle fa-lg" aria-hidden="true"></i>
							</div>
							<div className="content">
								<span className="message-body">{this.state.error}</span>
							</div>
						</div>
					}
				</div>
			);
		}

		componentDidMount(){
			this._isMounted = true;
			this.handleLogin();
			this.carregaTermoConsentimentoMaisRecente();
		}

		componentWillUnmount() {
			this._isMounted = false;
		}

		handleLogin = async e => {
			const urlParams = qs.parse(this.props.location.search);
			const code = urlParams.code;
			
			if(!code) {
				if(this._isMounted) this.setState({ error: "Code não informado!" });
			} else {
				try {
					if(this._isMounted) this.setState({isLoading: true, error: ""});
					const estado = urlParams.state;
					const url = `/api/seguranca/login/${code}?urlRetorno=${config.govbrRedirectUri}&state=${estado}`;
					const response = await api.get(url);
					const {access_token, photo, state} = response.data;
					this.props.login(access_token, photo);

					let path = '/';
					if (state){
						try {
							const objectState = JSON.parse(base64ToStr(state));
							path = objectState.pathname;
							const queryParams = objectState.queryParams;
							if(queryParams){
								path += queryParams;
							}
						} catch (e) {
							console.log("Erro na atribuição do path", e);
						}
					}

					this.props.history.push(path);
				} catch (err) {
					console.log('Erro durante o processamento do login', err);
					if(this._isMounted) this.setState({
						error: "Erro durante o processamento do login."
					});
				} finally {
					if(this._isMounted) this.setState({isLoading: false});
				}
			}
		}

		carregaTermoConsentimentoMaisRecente(){
			api.get('/api/termo-consentimento/mais-recente')
			.then(
				response => { 
					const termoConsentimentoMaisRecente = response.data;
					this.props.setTermoConsentimentoMaisRecente(termoConsentimentoMaisRecente);
				}
			)
			.catch(erro => {
			if(erro.status !== 404) {
				console.log("Não foi possível recuperar o termo de consentimento " + erro)
			}
			});
		}


	}

); // redux

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Menu} from 'antd';
import './Container.css';

const { SubMenu } = Menu;

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const MenuContainer = 

// redux
connect(
	(mapStateToProps)
)(
	class extends Component {
		
		render() {
			const { user } = this.props;
			let itensMenu = [];
			if (user && user.menu.length > 0) {
				itensMenu = user.menu;
			}

			return (
				<Fragment>
					{(user && user.menu) && 
						<div className="menu-item" style={{width: "145px"}} >
							<Menu className="" mode="horizontal">
								<SubMenu key="sub_menu_administracao"
								title={
									<span className="submenu-title-wrapper">
									Administração
									</span>
								}
								>	
								<Menu.ItemGroup style={{ width: 350 }}>
									{itensMenu && itensMenu.map(menu => 
										<SubMenu key={menu.id} title={menu.titulo}>
											{menu.submenus && menu.submenus.map (submenu => 
												<Menu.Item key={submenu.id}>
													{! submenu.linkExterno &&
														<Link to={submenu.link}>{submenu.titulo}</Link>
													}
													{submenu.linkExterno &&
														<a rel="noopener noreferrer" target="_blank" href={submenu.link}>{submenu.titulo}</a>
													}
												</Menu.Item>
											)}
										</SubMenu>
									)}
								</Menu.ItemGroup>
								</SubMenu>
							</Menu>
						</div>
					}
				</Fragment>
			);
		}

	}
	
); // redux
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { ButtonExit } from "../../Button/Exit";
import { Avatar } from "../../Avatar/Avatar";
import './Higher.css';
import { MenuContainer } from "../Container";
import { Fragment } from "react";

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const MenuHigher =

// redux
connect(
	(mapStateToProps)
)(
	class extends Component {

		render() {
			const { user } = this.props;

			return (
				<React.Fragment>
					<div className="shadow-bottom">
						<div className="container-fluid">
							<div className="row page-subheader justify-content-end" style={{height: "68px"}}>
								{user.assinaturaTermoPendente === false &&
									<Fragment>
										<MenuContainer />
										{/* pagina inicial */}
										<div className="col">
											<ul className="br-breadcrumb">
												<li className="home">
													<Link to="/">    
														<i id="icon-home" className="fas fa-home"></i>
													</Link>
												</li>
											</ul>
										</div>
									</Fragment>
								}
								{/* user options */}
								<div className="col-auto">
									<div className="user">
										<Avatar />
										<div className="actions">
											<ButtonExit />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		}
		
	}

); // redux 
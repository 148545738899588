import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import {maskedCpf, maskedCnpj} from "../../utils/maskUtil";

import api from "../../services/api";
import './RelatorioAuditoria.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import {formataDataHoraComMilisegundos} from '../../utils/formatadorData';
import { baixarArquivo } from "../../utils/downloadUtil";

registerLocale("pt_br", pt_br);

const PF = 'PF';
const PJ = 'PJ';
const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const RelatorioAuditoria =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				relatorioAuditoria: {},
				fornecedoras: [],
				fornecedora: '',
				tipoApiRfb: '',
				cpfCnpjs: '',
				periodoInicial: '',
				periodoFinal: '',
				pesquisaExecutada : false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.mostraRelatorioAuditoriaPDF = this.mostraRelatorioAuditoriaPDF.bind(this);
			this.mostraRelatorioAuditoriaCSV = this.mostraRelatorioAuditoriaCSV.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Relatório de auditoria</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			api.get('/api/aplicacao-fornecedora/relatorio-auditoria')
			.then(response => { this.setState({fornecedoras: response.data}) })
			.catch(erro => {
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		isApiPFSelecionada(){
			const tipoApiRfb = this.state.tipoApiRfb;
			return (tipoApiRfb && tipoApiRfb === PF);
		}

		isApiPJSelecionada(){
			const tipoApiRfb = this.state.tipoApiRfb;
			return (tipoApiRfb && tipoApiRfb === PJ);
		}
		
		getLabelCpfCnpj(){
			if (this.isApiPFSelecionada()) {
				return <label>CPF's (Informe um por linha):</label>
			}
			if (this.isApiPJSelecionada()) { 
				return <label>CNPJ's (Informe um por linha):</label>
			}
			return <label/>
		}

		renderForm() {
			const { fornecedora, relatorioAuditoria, pesquisaExecutada } = this.state;
			const { acessos } = relatorioAuditoria;
			const labelCpfCnpj = this.getLabelCpfCnpj();
			const apiPfSelecionada = this.isApiPFSelecionada();
			const apiPjSelecionada = this.isApiPJSelecionada();

			return(
				<div className="container-fluid">
					<div className="br-form">
						
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										<select name="fornecedora" onChange={this.handleFornecedoraChange} value={this.state.fornecedora}>
											<option value="">Selecione</option>
												{
													this.state.fornecedoras.map(fornecedora =>
														<option 
															key={fornecedora.id} 
															value={fornecedora.id}>
															{fornecedora.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
						</div>

						{ fornecedora && 
							<React.Fragment>
								<div className="row">
									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label>{labelCpfCnpj}</label>
												<textarea name="cpfCnpjs" value={this.state.cpfCnpjs} onChange={this.handleInputChange} />
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Período Inicial:</label>
												<DatePicker locale="pt_br" showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" selected={this.state.periodoInicial} onChange={data => {this.setState({periodoInicial: data})}} />
											</div>
										</div>
									</div>
								
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Período Final:</label>
												<DatePicker locale="pt_br" showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" selected={this.state.periodoFinal} onChange={data => {this.setState({periodoFinal: data})}} />
											</div>
										</div>
									</div>
								</div>

								<div className="actions-button">
									<div className="actions justify-content-start">
										<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
										{(acessos) &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioAuditoriaPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
										{(acessos) &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioAuditoriaCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
										<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
										<Link to="/"> 
											<div className="back-button">
												<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
											</div>
										</Link>
									</div>
								</div>
							</React.Fragment>
						}
						

						{(acessos && acessos.length > 0) &&
							<div className="container-fluid">	
								<h2>Acessos:</h2>
								<div className="br-table is-collapsible">						
									<div className="table">
										<table>
											<thead>
												<tr>
												<th scope="col">CNPJ do Órgao</th>
												<th scope="col">Nome do Órgão</th>
												<th scope="col">Responsável<br/>pela Chave</th>
												<th scope="col">Usuário</th>
												<th scope="col">Data Hora Acesso</th>
												<th scope="col">Endereço IP</th>
												<th scope="col">Sistema</th>
												<th scope="col">Sistema Convenente</th>
												{ apiPfSelecionada && 
													<th scope="col">CPF Consultado</th>
												}
												{ apiPjSelecionada && 
													<th scope="col">CNPJ Consultado</th>
												}
												</tr>
											</thead>
											<tbody>
												{ acessos.map((acesso, index) =>
														<tr key={index} >
															<td>{maskedCnpj(acesso.cnpjOrgao)}</td>
															<td>{acesso.nomeOrgao}</td>
															<td>{maskedCpf(acesso.cpfResponsavelChave)}</td>
															<td>{maskedCpf(acesso.cpfUsuario)}</td>
															<td>{formataDataHoraComMilisegundos(acesso.dataHoraAcesso)}</td>
															<td>{acesso.enderecoIp}</td>
															<td>{acesso.nomeSistema}</td>
															<td>{acesso.nomeSistemaConvenente}</td>
															{ apiPfSelecionada && 
																<td>{maskedCpf(acesso.cpfCnpjConsultado)}</td>
															}
															{ apiPjSelecionada && 
																<td>{maskedCnpj(acesso.cpfCnpjConsultado)}</td>
															}
														</tr>
													)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! acessos || acessos.length === 0)) &&
							<div>
								Não foram encontrados acessos os critérios informados.
							</div>
						}

					</div>
				</div>
				
			);
		}

		validaFormulario(){
			let mensagens = [];
			const { fornecedora, tipoApiRfb, cpfCnpjs, periodoInicial, periodoFinal } = this.state;

			if(!fornecedora || fornecedora.trim().length === 0 ){
				mensagens.push({mensagem: "API é obrigatório."})
			} else {

				if(!cpfCnpjs || cpfCnpjs.trim().length === 0 ){
					const msgCpfCnpj = tipoApiRfb === PF ? "CPF's é obrigatório." : "CNPJ's é obrigatório."
					mensagens.push({mensagem: msgCpfCnpj})
				}

				if(!periodoInicial){
					mensagens.push({mensagem: "Período Inicial é obrigatório."})
				}
				if(!periodoFinal){
					mensagens.push({mensagem: "Período Final é obrigatório."})
				}

				if(periodoInicial && periodoFinal){
					const milisegundos = periodoFinal.getTime() - periodoInicial.getTime();

					if(milisegundos < 0){
						mensagens.push({mensagem: "O período inicial deve ser menor ou igual ao período final"});
					}

					if(milisegundos > 24*60*60*1000){
						mensagens.push({mensagem: "O período deve ser menor ou igual a 1 dia."});
					}
				}
			}
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		validaListaCpfCnpjs(criterioPesquisa){
			if(criterioPesquisa.listaCpfCnpjs.length > 50){
				let mensagem = this.state.tipoApiRfb === PF ? "Informe no máximo 50 CPF's" : "Informe no máximo 50 CNPJ's";
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", [{mensagem}])
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleFornecedoraChange(evento){
			const fornecedora =  evento.target.value;
			let tipoApiRfb = '';

			if(fornecedora && fornecedora !== ""){
				tipoApiRfb = this.state.fornecedoras.filter((f) => f.id === Number(fornecedora))[0].tipoApiRfb;
			} 

			this.setState({
				fornecedora,
				tipoApiRfb,
			});
		}

		pesquisar (e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {fornecedora, cpfCnpjs, periodoInicial, periodoFinal } = this.state;
			const criterioPesquisa = this.getCriteriosPesquisa(fornecedora, cpfCnpjs, periodoInicial, periodoFinal);
			const url = '/api/auditoria';
			
			try{
				this.validaListaCpfCnpjs(criterioPesquisa);
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					this.setState({
						relatorioAuditoria: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar o relatório de auditoria. ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		getCriteriosPesquisa(fornecedora, cpfCnpjs, periodoInicial, periodoFinal){
			const separadores = [' ', ';', '|', ',', '\n'];
			let cpfCnpjsStr = cpfCnpjs;
			if (separadores.includes(cpfCnpjsStr[cpfCnpjsStr.length -1 ])){
				//remove o ultimo caracter se for um separador
				cpfCnpjsStr = cpfCnpjsStr.slice(0, -1);
			}

			//remove mascara
			cpfCnpjsStr = cpfCnpjsStr.replace(new RegExp("\\.","g"),"").replace(/-/g, '').replace(new RegExp("\\/","g"),"");

			//substitui espacos, virgulas, ponto e virgulas, pipes por quebras de linhas
			cpfCnpjsStr = cpfCnpjsStr.replace(/ |,|;|\|/g, '\n');

			//substitui quebras de linhas consecutivas por apenas uma.
			cpfCnpjsStr = cpfCnpjsStr.replace(/(\r\n|\r|\n){2,}/g, '\n');

			let listaCpfCnpjs = cpfCnpjsStr.split("\n");
			if (this.isApiPFSelecionada()) {
				listaCpfCnpjs = listaCpfCnpjs.filter(cpf => cpf && cpf.trim().length > 0).map(cpf =>  ("00000000000"+cpf).slice(-11));
			} else {
				listaCpfCnpjs = listaCpfCnpjs.filter(cnpj => cnpj && cnpj.trim().length > 0).map(cnpj =>  ("00000000000000"+cnpj).slice(-14));
			}

			return {fornecedora, periodoInicial, periodoFinal, listaCpfCnpjs};
		}

		mostraRelatorioAuditoriaPDF(e){
			this.mostraRelatorioAuditoria(e, 'pdf')
		}

		mostraRelatorioAuditoriaCSV(e){
			this.mostraRelatorioAuditoria(e, 'csv')
		}

		mostraRelatorioAuditoria(e, tipo){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {fornecedora, cpfCnpjs, periodoInicial, periodoFinal } = this.state;
			const criterioPesquisa = this.getCriteriosPesquisa(fornecedora, cpfCnpjs, periodoInicial, periodoFinal);
			const url = `/api/auditoria/${tipo}`;

			try{
				this.validaListaCpfCnpjs(criterioPesquisa);
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioAuditoria.${tipo}`)
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de auditoria';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			this.setState({
				fornecedora: '',
				tipoApiRfb: '',
				cpfCnpjs: '',
				periodoInicial: '',
				periodoFinal: '',
				relatorioAuditoria: {},
				pesquisaExecutada : false,
			});
		}
	}

) // redux
); // router
const ID_TIPO_SOLICITACAO_ADESAO_INCLUSAO = 'I';
const TIPO_SOLICITACAO_ADESAO_INCLUSAO = {
	codigo: ID_TIPO_SOLICITACAO_ADESAO_INCLUSAO,
	nome: 'Adesão'
}

const ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO = 'A';
const TIPO_SOLICITACAO_ADESAO_ALTERACAO = {
	codigo: ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO,
	nome: 'Alteração de volumetria'
}

export {ID_TIPO_SOLICITACAO_ADESAO_INCLUSAO, ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO, TIPO_SOLICITACAO_ADESAO_INCLUSAO, TIPO_SOLICITACAO_ADESAO_ALTERACAO }
import React, { Component } from "react";
import { connect } from 'react-redux';
import { exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConsentTermAgreement } from "../ConsentTerm/ConsentTermAgreement";
import { PainelCards } from "./PainelCards/PanelCards";


import './Home.css';

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

const mapDispatchToProps = { exibeMensagemErro, limpaMensagem }

export const Home =

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {
		render() {

			return (
				<main id="content" className="page-content p-3" >
					<Message />
					<Loading />
					
					{this.props.user && this.props.user.assinaturaTermoPendente === true &&
						<ConsentTermAgreement/>
					}

					{this.props.user && this.props.user.assinaturaTermoPendente === false &&
						<PainelCards/>
					}
					
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const { user } = this.props;
			if(user && (! user.roles || user.roles.length === 0)){
				this.props.exibeMensagemErro("Usuário não possui perfil de gestor ou está vinculado a um órgão desativado.")
			}
		}

	}
); // router
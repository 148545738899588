import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { getMessagemErro } from "../../utils/erro";
import api from "../../services/api";
import './SolicitacaoAdesao.css';

import { SolicitacaoAdesaoDetails } from "./Details";
import { SolicitacaoAdesaoAttachmentsAndHistory } from "./AttachmentsAndHistory";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const SolicitacaoAdesaoView =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				solicitacao: {
					numero: '',
					nomeSituacao: '',
					nomeFornecedora: '',
					nomeDirigenteTi: '',
					nomeResponsavelTecnico: '',
					nomeOrgaoFornecedora: '',
					descricaoComplementarFornecedora: '',
					nomeTipo: '',
					volumetriaAtual: 0,
					volumetriaSolicitada: 0,
					volumetriaAutorizada: null,
					finalidade: '',
					justificativaOrgaoFornecedor: '',
					justificativaOrgaoAdministrador: '',
					solicitacaoAssinada: false,
				},
			};
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			api.get(`/api/solicitacao-adesao/${id}`)
				.then(response => { 
					const solicitacao = response.data;
					this.setState({ solicitacao }) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar a solicitação de adesão.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				});
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Solicitação de Adesão e Alteração de Volumetria dos Órgãos</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		renderForm() {
			const solicitacao = this.state.solicitacao;

			return(
				<div className="container-fluid">
					<div className="br-form">
						<SolicitacaoAdesaoDetails solicitacao={solicitacao} mostraDetalhesAprovacao={solicitacao.solicitacaoAssinada}/>
						<SolicitacaoAdesaoAttachmentsAndHistory solicitacao={solicitacao} habilitaEdicaoAnexo={false}/>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">

								{ solicitacao.assinaturaPermitida &&
									<Link to={`/solicitacao-adesao/sign/${solicitacao.id}`}>
										<button type="button" className="button is-primary">Assinar</button>
									</Link>
								}

								{ solicitacao.aprovacaoFornecedoraPermitida &&
									<Link to={`/solicitacao-adesao/approve/${solicitacao.id}`}>
										<button type="button" className="button is-primary">Analisar</button>
									</Link>
								}

								{ solicitacao.aprovacaoAdministradorPermitida &&
									<Link to={`/solicitacao-adesao/approve/${solicitacao.id}`}>
										<button type="button" className="button is-primary">Analisar</button>
									</Link>
								}

								{ solicitacao.alteracaoPermitida &&
									<Link to={`/solicitacao-adesao/edit/${solicitacao.id}`}>
										<button type="button" className="button is-secondary botaoNovo">Alterar</button>
									</Link>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary">Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

) // redux
); // router
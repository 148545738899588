import React from 'react';
import './Footer.css';

import {LatestConsentTermAgreementLink} from "../../components/ConsentTerm/LatestConsentTermAgreementLink";

export function Footer() {
  return (
    <React.Fragment>
      {/* Rodapé de governo */}
      <div className="footer">
        <div className="page-footer mt-5 mt-sm-0 pb-3 text-right">
          <div className="container-fluid">
              <LatestConsentTermAgreementLink textoLink="Termos de uso e de política de privacidade"/>
              &nbsp;&nbsp;&nbsp;
              <img src={require('../../images/produto-serpro.png')} alt="Produto Serpro" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React, { Component } from "react";
import { Pagination } from "../Pagination/Pagination";
import {maskedCpf, maskedCnpj} from "../../utils/maskUtil";
import {formataDataHoraComMilisegundos} from '../../utils/formatadorData';
import './RelatorioAuditoriaGenerico.css';

export const Resultado =

	class extends Component {

		render() {
			const { resultadoPesquisa, pesquisaExecutada, exibeColunaCpfsCnpj } = this.props;
			const {content} = resultadoPesquisa;
			return (
				<div>
						{(content && content.length > 0) &&
							<div className="container-fluid">	
								<h2>Acessos:</h2>
								<div className="br-table is-collapsible">						
									<div className="table">
										<table>
											<thead>
												<tr>
													<th scope="col">CNPJ do Órgao</th>
													<th scope="col">Nome do Órgão</th>
													<th scope="col">Responsável<br/>pela Chave</th>
													<th scope="col">Usuário</th>
													<th scope="col">Data Hora<br/>Acesso</th>
													<th scope="col">Data Hora<br/>Resposta</th>
													<th scope="col">Endereço IP</th>
													<th scope="col">Aplicação</th>
													<th scope="col">API</th>
													{exibeColunaCpfsCnpj &&
														<th scope="col">CPFs/CNPJ consultados</th>
													}
													<th scope="col" className="text-right">Código<br/>Retorno</th>
												</tr>
											</thead>
											<tbody>
												{ content.map((acesso, index) =>
														<tr key={index} >
															<td>{maskedCnpj(acesso.cnpjOrgao)}</td>
															<td>{acesso.nomeOrgao}</td>
															<td>{maskedCpf(acesso.cpfResponsavelChave)}</td>
															<td>{maskedCpf(acesso.cpfUsuario)}</td>
															<td>{formataDataHoraComMilisegundos(acesso.dataHoraAcesso)}</td>
															<td>{formataDataHoraComMilisegundos(acesso.dataHoraResposta)}</td>
															<td>{acesso.enderecoIp}</td>
															<td>{acesso.aplicacao}</td>
															<td>{acesso.api}</td>
															{exibeColunaCpfsCnpj &&
																<td style={{width: "10%"}}>{acesso.cpfsCnpjConsultados}</td>
															}
															<td className="text-right">{acesso.codigoRetorno}</td>
														</tr>
													)}
											</tbody>
										</table>
										<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.props.onChangePage}/>
									</div>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
							Não foram encontrados acessos para os critérios informados.
							</div>
						}
				</div>
			);
		}		
	}
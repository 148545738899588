import React, { Component, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import NumberFormat from 'react-number-format';
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { getMessagemErro } from "../../utils/erro";
import { validaCamposNumericos } from "../../utils/formValidationUtil";
import api from "../../services/api";
import { ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA } from "../../providers/constSituacoesSolicitacaoAdesao";
import './SolicitacaoAdesao.css';

import { SolicitacaoAdesaoDetails } from "./Details";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { SolicitacaoAdesaoAttachmentsAndHistory } from "./AttachmentsAndHistory";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const SolicitacaoAdesaoApproval =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				solicitacao: {
					numero: '',
					nomeSituacao: '',
					nomeFornecedora: '',
					nomeDirigenteTi: '',
					nomeResponsavelTecnico: '',
					nomeOrgaoFornecedora: '',
					descricaoComplementarFornecedora: '',
					nomeTipo: '',
					volumetriaAtual: 0,
					volumetriaSolicitada: 0,
					volumetriaAutorizada: null,
					finalidade: '',
					justificativaOrgaoFornecedor: '',
					justificativaOrgaoAdministrador: '',
				},
				botoesHabilitados: true,
				exibeModalConfirmacao: false,
				msgModal: '',
				operacao: null,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.aprovarSolicitacao = this.aprovarSolicitacao.bind(this);
			this.rejeitarSolicitacao = this.rejeitarSolicitacao.bind(this);
			this.deferirSolicitacao = this.deferirSolicitacao.bind(this);
			this.deferirSolicitacaoSemVolumetria = this.deferirSolicitacaoSemVolumetria.bind(this);
			this.validaRejeicao = this.validaRejeicao.bind(this);
			this.validaAprovacao = this.validaAprovacao.bind(this);
			this.validaAprovacaoSemVolumetria = this.validaAprovacaoSemVolumetria.bind(this);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			api.get(`/api/solicitacao-adesao/${id}`)
				.then(response => { 
					const solicitacao = response.data;
					if(solicitacao.aprovacaoAdministradorPermitida && !solicitacao.volumetriaAutorizada){
						solicitacao.volumetriaAutorizada = solicitacao.volumetriaSolicitada;
					}
					this.setState({ solicitacao }) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar a solicitação de adesão.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				});
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Solicitação de Adesão e Alteração de Volumetria dos Órgãos</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		renderForm() {
			const user = this.props.user;
			const solicitacao = this.state.solicitacao;

			return(
				<div className="container-fluid">
					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalConfirmacao}
						handleOk={() => this.executaOperacao()}
						handleCancel={() => this.fechaModal()}
						message={this.state.msgModal}/>

					<SolicitacaoAdesaoDetails solicitacao={solicitacao} mostraDetalhesAprovacao={false}/>
					

					<div className="br-form">
						<div className="row">
							<div className="col-md">  
								<div className="field">
									<div className="input"> 
										<label htmlFor="justificativaOrgaoFornecedor">Justificativa cedente dos dados:</label>
										<textarea name="justificativaOrgaoFornecedor" value={solicitacao.justificativaOrgaoFornecedor} 
											onChange={this.handleInputChange} maxLength={255} style={{height:"90px"}}
											readOnly={! solicitacao.aprovacaoFornecedoraPermitida}/>
									</div>
								</div>
							</div>

							{user.isAdministrador && 
								<Fragment>
									<div className="col-md">  
										<div className="field">
											<div className="input"> 
												<label htmlFor="justificativaOrgaoAdministrador">Justificativa conecta:</label>
												<textarea name="justificativaOrgaoAdministrador" value={solicitacao.justificativaOrgaoAdministrador} 
												onChange={this.handleInputChange} maxLength={255} style={{height:"90px"}}
												readOnly={! solicitacao.aprovacaoAdministradorPermitida}/>
											</div>
										</div>
									</div>
									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label>Volumetria Autorizada:</label>
												<NumberFormat value={solicitacao.volumetriaAutorizada} 
													thousandSeparator={'.'} 
													decimalSeparator={','} 
													decimalScale={0}
													allowNegative={false}
													isNumericString={true}
													className="texto-alinhado-direita"
													onValueChange={(valoresInput) => this.handleInputNumberChange('volumetriaAutorizada', valoresInput)}
													readOnly={! solicitacao.aprovacaoAdministradorPermitida}
													/>
											</div>
										</div>
									</div>
								</Fragment>
							}
						</div> 

						<SolicitacaoAdesaoAttachmentsAndHistory solicitacao={solicitacao} habilitaEdicaoAnexo={true}/>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								{solicitacao.aprovacaoFornecedoraPermitida && 
									<Fragment>
										<button type="button" className="button is-primary" onClick={() => this.confirmaOperacao(this.aprovarSolicitacao, "Deseja realmente APROVAR a solicitação de adesão?")}  disabled={!this.state.botoesHabilitados}>Aprovar</button>
										<button type="button" className="button is-secondary" onClick={() => this.confirmaOperacao(this.rejeitarSolicitacao, "Deseja realmente REJEITAR a solicitação de adesão?", this.validaRejeicao)}  disabled={!this.state.botoesHabilitados}>Rejeitar</button>
									</Fragment>
								}

								{solicitacao.aprovacaoAdministradorPermitida && 
									<Fragment>
										<button type="button" className="button is-primary" onClick={() => this.confirmaOperacao(this.deferirSolicitacao, "Deseja realmente deferir a solicitação de adesão?", this.validaAprovacao)}  disabled={!this.state.botoesHabilitados}>Deferir</button>
										
										{solicitacao.situacao !== ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA &&
											<button type="button" className="button is-secondary" onClick={() => this.confirmaOperacao(this.deferirSolicitacaoSemVolumetria, "Deseja realmente deferir a solicitação de adesão sem volumetria?", this.validaAprovacaoSemVolumetria)}  disabled={!this.state.botoesHabilitados}>Deferir sem volumetria</button>
										}
									</Fragment>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const solicitacao = this.state.solicitacao;
			solicitacao[nome] = valor;
			this.setState({ solicitacao });
		}

		handleInputNumberChange(nomePropriedade, valoresInput){
			const solicitacao = this.state.solicitacao;
			solicitacao[nomePropriedade] = Number(valoresInput.value);
			this.setState({ solicitacao });
		}

		confirmaOperacao(operacao, msgModal, validacao) {
			if(validacao) {
				try{
					validacao();
				}catch(erroValidacao){
					return;
				}
			}

			this.setState(
				{
					exibeModalConfirmacao: true,
					operacao,
					msgModal,
				}
			);
		}

		executaOperacao () {
			this.setState({botoesHabilitados:false});
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			window.scrollTo(0, 0)

			this.state.operacao();
			this.fechaModal();
		}

		executaAndamento (url, mensagemSucesso, mensagemErro) {
			const { solicitacao } = this.state;
			const { id, justificativaOrgaoFornecedor, justificativaOrgaoAdministrador, volumetriaAutorizada } = solicitacao;

			const options = {
				method: 'put',
				url,
				data: { id, justificativaOrgaoFornecedor, justificativaOrgaoAdministrador, volumetriaAutorizada },
			}

			api(options)
				.then(response => { 
					this.setState({
						solicitacao: response.data,
						pesquisaExecutada : true,
					}) 
					this.props.exibeMensagemSucesso(mensagemSucesso);
				})
				.catch(erro => {
					const msg = getMessagemErro(mensagemErro, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		validaRejeicao(){
			const { solicitacao } = this.state;
			const { justificativaOrgaoFornecedor } = solicitacao;
			
			if(!justificativaOrgaoFornecedor || justificativaOrgaoFornecedor.trim().length === 0 ){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", [{mensagem: "Justificativa cedente dos dados é obrigatória."}])
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		validaAprovacaoSemVolumetria(){
			const { solicitacao } = this.state;
			const { justificativaOrgaoAdministrador } = solicitacao;

			if(!justificativaOrgaoAdministrador || justificativaOrgaoAdministrador.trim().length === 0 ){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", [{mensagem: "Justificativa conecta é obrigatória."}])
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		validaAprovacao(){
			const limiteVolumetria = 2147483647;
			let mensagens = [];

			const { solicitacao } = this.state;
			const { volumetriaAutorizada } = solicitacao;

			validaCamposNumericos(mensagens, volumetriaAutorizada, "Volumetria autorizada", true, false, true, limiteVolumetria);
			
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		aprovarSolicitacao () {
			const mensagemSucesso = "Solicitação de adesão aprovada com sucesso.";
			const mensagemErro = "Não foi possível aprovar a solicitação de adesão.";
			const url = '/api/solicitacao-adesao/aprovar';
			this.executaAndamento(url, mensagemSucesso, mensagemErro);
		}

		rejeitarSolicitacao() {
			const mensagemSucesso = "Solicitação de adesão rejeitada com sucesso.";
			const mensagemErro = "Não foi possível rejeitar a solicitação de adesão.";
			const url = '/api/solicitacao-adesao/rejeitar';
			this.executaAndamento(url, mensagemSucesso, mensagemErro);
		}

		deferirSolicitacao() {
			const mensagemSucesso = "Solicitação de adesão deferida com sucesso.";
			const mensagemErro = "Não foi possível deferir a solicitação de adesão.";
			const url = '/api/solicitacao-adesao/deferir';
			this.executaAndamento(url, mensagemSucesso, mensagemErro);
		}

		deferirSolicitacaoSemVolumetria() {
			const mensagemSucesso = "Solicitação de adesão deferida sem volumetria com sucesso.";
			const mensagemErro = "Não foi possível deferir sem volumetria a solicitação de adesão.";
			const url = '/api/solicitacao-adesao/deferir-sem-volumetria';
			this.executaAndamento(url, mensagemSucesso, mensagemErro);
		}

		fechaModal () {
			this.setState(
				{
					exibeModalConfirmacao: false,
				}
			);
		}
	}

) // redux
); // router
import config from "../config";
import { faBuilding, faUserTie, faClipboardList, faChartLine, faMobileScreen, faKey, faMagnifyingGlassChart, faChartPie, faChartColumn, faGauge, faGaugeSimple, faCashRegister, faLockOpen, faHandshake} from "@fortawesome/free-solid-svg-icons";
import {PERFIL_CLIENTE, PERFIL_ADMININSTRADOR, PERFIL_AUDITOR, PERFIL_FORNECEDOR, PERFIL_GESTOR_API, PERFIL_MANTENEDOR} from './constPerfis';
import {PERMISSAO_ORGAO, PERMISSAO_GESTOR, PERMISSAO_APLICACAO_FORNECEDORA, PERMISSAO_PLANO, PERMISSAO_PERFIL_GESTOR, 
    PERMISSAO_TERMO_USO, PERMISSAO_SOLICITACAO_RELATORIO, PERMISSAO_RELATORIO, PERMISSAO_MONITORACAO, 
    PERMISSAO_APLICACAO_CONSUMIDORA, PERMISSAO_FATURAMENTO, PERMISSAO_CADASTRO_WHITELIST, PERMISSAO_CADASTRO_SOLICITACAO_ADESAO} from './constPermissoes';

const menus = [
    {
        id: "menu_solicitacao_adesao",
        titulo: "Solicitação de Adesão",
        permissao: PERMISSAO_CADASTRO_SOLICITACAO_ADESAO,
        submenus: [
            {
                id: "sub_menu_nova_solicitacao_adesao",
                link: "/solicitacao-adesao/new",
                titulo: "Cadastro de Nova Solicitação de Adesão",
                perfis: [PERFIL_CLIENTE],
            },
            {
                id: "sub_menu_lista_solicitacao_adesao",
                link: "/solicitacao-adesao/list",
                titulo: "Listar Solicitações de Adesão",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE],
                card: {
                    titulo: "Solicitações de Adesão",
                    icone: faHandshake
                }
            }
        ]
    },
    {
        id: "menu_orgao",
        titulo: "Órgão",
        permissao: PERMISSAO_ORGAO,
        submenus: [
            {
                id: "sub_menu_novo_orgao",
                link: "/organization/new",
                titulo: "Cadastro de Novo Órgão",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_GESTOR_API]
            },
            {
                id: "sub_menu_lista_orgao",
                link: "/organization/list",
                titulo: "Listar Órgãos",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_GESTOR_API],
                card: {
                    titulo: "Órgãos",
                    icone: faBuilding
                }
            },
        ]
        
    },
    {
        id: "menu_gestor",
        titulo: "Gestor",
        permissao: PERMISSAO_GESTOR,
        submenus: [
            {
                id: "sub_menu_novo_gestor",
                link: "/manager/new",
                titulo: "Cadastro de Novo Gestor",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_GESTOR_API]
            },
            {
                id: "sub_menu_lista_gestor",
                link: "/manager/list",
                titulo: "Listar Gestores",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_GESTOR_API],
                card: {
                    titulo: "Gestores",
                    icone: faUserTie
                }
            }
        ]

    },
    {
        id: "menu_api",
        titulo: "API",
        permissao: PERMISSAO_APLICACAO_FORNECEDORA,
        submenus: [
            {
                id: "sub_menu_lista_api",
                link: "/api/list",
                titulo: "Listar API's",
                perfis: [PERFIL_ADMININSTRADOR]
            },
            {
                id: "sub_menu_novo_tipo_anexo_api",
                link: "/api-attachment-type/new",
                titulo: "Cadastro de Novo Tipo de Anexo de API",
                perfis: [PERFIL_ADMININSTRADOR]
            },
            {
                id: "sub_menu_lista_tipo_anexo_api",
                link: "/api-attachment-type/list",
                titulo: "Listar Tipos de Anexos de API",
                perfis: [PERFIL_ADMININSTRADOR]
            }
        ]
    },
    {
        id: "menu_plano",
        titulo: "Plano",
        permissao: PERMISSAO_PLANO,
        submenus: [
            {
                id: "sub_menu_novo_plano",
                link: "/plan/new", 
                titulo: "Cadastro de Novo Plano de Consumo",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_GESTOR_API]
            },
            {	
                id: "sub_menu_lista_plano",
                link: "/plan/list",
                titulo: "Listar Planos de Consumo",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_GESTOR_API]
            }

        ]
    },
    {
        id: "menu_perfil_gestor",
        titulo: "Perfil Gestor",
        permissao: PERMISSAO_PERFIL_GESTOR,
        submenus: [
            {
                id: "sub_menu_novo_perfil_gestor",
                link: "/manager-profile/new", 
                titulo: "Cadastro de Novo Perfil de Gestor",
                perfis: [PERFIL_ADMININSTRADOR]
            },
            {	
                id: "sub_menu_lista_perfil_gestor",
                link: "/manager-profile/list",
                titulo: "Listar Perfis de Gestor",
                perfis: [PERFIL_ADMININSTRADOR]
            }
        ]
    },
    {
        id: "menu_aplicacao",
        titulo: "Aplicação",
        permissao: PERMISSAO_APLICACAO_CONSUMIDORA,
        submenus: [
            {
                id: "sub_menu_nova_aplicacao",
                link: "/application/new",
                titulo: "Cadastro de Nova Aplicação",
                perfis: [PERFIL_CLIENTE, PERFIL_ADMININSTRADOR]
            },
            {
                id: "sub_menu_lista_aplicacao",
                link: "/application/list",
                titulo: "Listar Aplicações",
                perfis: [PERFIL_CLIENTE, PERFIL_ADMININSTRADOR],
                card: {
                    titulo: "Aplicações",
                    icone: faMobileScreen,
                }
            },
            {
                id: "sub_menu_gera_chave",
                link: "/generate-key",
                titulo: "Gerar Chaves de Acesso",
                perfis: [PERFIL_CLIENTE, PERFIL_ADMININSTRADOR],
                card: {
                    titulo: "Chaves de Acesso",
                    icone: faKey
                }
            }
        ]
    },
    {
        id: "menu_termo_consentimento",
        titulo: "Termos de Uso e de Politica de Privacidade",
        permissao: PERMISSAO_TERMO_USO,
        submenus: [
            {
                id: "sub_menu_registrar_termo_consentimento",
                link: "/termo-consentimento/edit",
                titulo: "Cadastro de Termos de Uso e de Politica de Privacidade",
                perfis: [PERFIL_ADMININSTRADOR]
            }
        ]
    },
    {
        id: "menu_faturamento",
        titulo: "Declaração de Consumo",
        permissao: PERMISSAO_FATURAMENTO,
        submenus: [
            {
                id: "sub_menu_novo_contrato",
                link: "/contract/new",
                titulo: "Novo Contrato",
                perfis: [PERFIL_MANTENEDOR],
            },
            {
                id: "sub_menu_listar_contrato",
                link: "/contract/list",
                titulo: "Listar Contratos",
                perfis: [PERFIL_MANTENEDOR],
            },
            {
                id: "sub_menu_nova_referencia_faturamento",
                link: "/billing-reference/new",
                titulo: "Nova Referência de Faturamento",
                perfis: [PERFIL_MANTENEDOR],
            },
            {
                id: "sub_menu_listar_referencia_faturamento",
                link: "/billing-reference/list",
                titulo: "Listar Referências de Faturamento",
                perfis: [PERFIL_MANTENEDOR],
            },
            {
                id: "sub_menu_gerar_prestacao_contas",
                link: "/billing/new",
                titulo: "Gerar Declaração de Consumo",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_MANTENEDOR],
            },
            {
                id: "sub_menu_listar_prestacao_contas",
                link: "/billing/list",
                titulo: "Listar Declaração de Consumo",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE],
            }
        ]
    },
    {
        id: "menu_solcitacao_relatorio_auditoria",
        titulo: "Solicitação de Relatório",
        permissao: PERMISSAO_SOLICITACAO_RELATORIO,
        submenus: [
            {
                id: "sub_menu_nova_solicitacao_relatorio_auditoria",
                link: "/solicitacao-relatorio-auditoria/new",
                titulo: "Cadastro de Nova Solicitação de Relatório de Auditoria",
                perfis: [PERFIL_MANTENEDOR, PERFIL_AUDITOR],
            },
            {
                id: "sub_menu_lista_solicitacao_relatorio_auditoria",
                link: "/solicitacao-relatorio-auditoria/list",
                titulo: "Listar Solicitações de Relatório de Auditoria",
                perfis: [PERFIL_MANTENEDOR, PERFIL_AUDITOR],
                card: {
                    titulo: "Solicitações de Relatório",
                    icone: faClipboardList
                }
            }
        ]
    },
    {
        id: "menu_whitelist",
        titulo: "Liberação de IPs",
        permissao: PERMISSAO_CADASTRO_WHITELIST,
        submenus: [
            {
                id: "sub_menu_nova_solicitacao_cadastro_whitelist",
                link: "/solicitacao-cadastro-whitelist/new",
                titulo: "Solicitação de Liberação de IP",
                perfis: [PERFIL_ADMININSTRADOR,PERFIL_MANTENEDOR, PERFIL_CLIENTE],
            },
            {
                id: "sub_menu_lista_solicitacao_cadastro_whitelist",
                link: "/solicitacao-cadastro-whitelist/list",
                titulo: "Listar Solicitações de Liberação de IP",
                perfis: [PERFIL_ADMININSTRADOR,PERFIL_MANTENEDOR, PERFIL_CLIENTE],
                card: {
                    titulo: "Liberação de IPs",
                    icone: faLockOpen
                }
            },
            {
                id: "sub_menu_lista_whitelist",
                link: "/whitelist/list",
                titulo: "Listar IPs Liberados",
                perfis: [PERFIL_ADMININSTRADOR,PERFIL_MANTENEDOR, PERFIL_CLIENTE],
            }
        ]
    },
    {
        id: "menu_relatorio",
        titulo: "Relatórios",
        permissao: PERMISSAO_RELATORIO,
        submenus: [
            {
                id: "sub_menu_relatorio_consumo",
                link: "/relatorio-consumo",
                titulo: "Relatório de consumo",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE, PERFIL_MANTENEDOR],
                card: {
                    titulo: "Relatório de Consumo",
                    icone: faGauge
                }
            },
            {
                id: "sub_menu_relatorio_consumo_por_metodo",
                link: "/relatorio-consumo-por-metodo",
                titulo: "Relatório de consumo por método",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE, PERFIL_MANTENEDOR],
                card: {
                    titulo: "Relatório de Consumo por Método",
                    icone: faGaugeSimple
                }
            },
            {
                id: "sub_menu_relatorio_disponibilidade",
                link: "/relatorio-disponibilidade",
                titulo: "Relatório de disponibilidade",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE, PERFIL_MANTENEDOR],
                card: {
                    titulo: "Relatório de Disponibilidade",
                    icone: faChartPie
                }
            },
            {
                id: "sub_menu_relatorio_gerencial",
                link: "/relatorio-gerencial",
                titulo: "Relatório gerencial",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE, PERFIL_MANTENEDOR],
                card: {
                    titulo: "Relatório Gerencial",
                    icone: faChartColumn
                }
            },
            {
                id: "sub_menu_relatorio_prestacao_contas",
                link: "/relatorio-prestacao-contas",
                titulo: "Relatório de prestação de contas",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_MANTENEDOR],
                card: {
                    titulo: "Relatório de Prestação de Contas",
                    icone: faCashRegister
                }
            },
            {
                id: "sub_menu_relatorio_auditoria",
                link: "/relatorio-auditoria-generico",
                titulo: "Relatório de auditoria",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE, PERFIL_MANTENEDOR],
                card: {
                    titulo: "Relatório de Auditoria",
                    icone: faMagnifyingGlassChart
                }
            },
            {
                id: "sub_menu_relatorio_auditoria_rfb",
                link: "/relatorio-auditoria",
                titulo: "Relatorio de auditoria RFB",
                perfis: [PERFIL_AUDITOR],
                card: {
                    titulo: "Relatório de Auditoria",
                    icone: faMagnifyingGlassChart
                }
            },
            {
                id: "sub_menu_relatorio_primeiro_consumo_orgao",
                link: "/relatorio-primeiro-consumo-orgao",
                titulo: "Relatório de Órgãos Consumidores",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_CLIENTE, PERFIL_MANTENEDOR, PERFIL_AUDITOR]
            },
            {
                id: "sub_menu_relatorio_adesao_aplicacao",
                link: "/relatorio-adesao-aplicacao",
                titulo: "Relatório de Adesões",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_MANTENEDOR]
            }
        ]
    },
    {
        id: "menu_monitoracao",
        titulo: "Monitoração",
        permissao: PERMISSAO_MONITORACAO,
        submenus: [
            {
                id: "sub_menu_monitoracao",
                link: config.govbrMonitoracaoUrl,
                linkExterno: true,
                titulo: "Painel de acompanhamento",
                perfis: [PERFIL_ADMININSTRADOR, PERFIL_FORNECEDOR],
                card: {
                    titulo: "Painel de Acompanhamento",
                    icone: faChartLine
                }
            }
        ]
    }
];

const getPerfisUsuario = (usuario) => {
	if(!usuario) {
		return [];
	}

	const perfisUsuario = [];
	if(usuario.isAdministrador) {
		perfisUsuario.push(PERFIL_ADMININSTRADOR);
	}
	if(usuario.isCliente) {
		perfisUsuario.push(PERFIL_CLIENTE);
	}
	if(usuario.isGestorAPI) {
		perfisUsuario.push(PERFIL_GESTOR_API);
	}
	if(usuario.isFornecedor) {
		perfisUsuario.push(PERFIL_FORNECEDOR);
	}
	if(usuario.isMantenedor) {
		perfisUsuario.push(PERFIL_MANTENEDOR);
	}
	if(usuario.isAuditor) {
		perfisUsuario.push(PERFIL_AUDITOR);
	}
    return perfisUsuario;
}

export const getMenusUsuario = (usuario) => {
    const perfisUsuario = getPerfisUsuario(usuario);
    const permissoesUsuario = usuario.permissions;
    const menusUsuario = [];
    for (const menu of menus){

        if(! permissoesUsuario || permissoesUsuario.indexOf(menu.permissao) === -1){
            continue;
        }

        const submenusUsuario = [];
        for (const submenu of menu.submenus) {
            if(perfisUsuario.some(pu => submenu.perfis.indexOf(pu) !== -1)){
                submenusUsuario.push({...submenu});
            }
        }
        if(submenusUsuario.length > 0){
            let novoMenu = {...menu};
            novoMenu.submenus = submenusUsuario;
            menusUsuario.push(novoMenu);
        }
    }
    return menusUsuario;
}
import React, { Component } from "react";
import { AlertModal } from "../AlertModal/AlertModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faLongArrowAltRight, faEarthAmericas} from "@fortawesome/free-solid-svg-icons";
import './ModalInstrucoesCadastroWhitelist.css';

export const ModalIntrucoesCadastroWhitelist =

class extends Component {
	render() {
    const link = "http://meuip-app.estaleiro.serpro.gov.br/get_meu_ip";

		return <AlertModal
          titulo={this.props.titulo}
		      visible={this.props.visible}
          handleClose={this.props.handleClose}
        >
          <div className="container-icons">
            <FontAwesomeIcon icon={faCity} size="2x" />
            <FontAwesomeIcon icon={faLongArrowAltRight}  />
            <FontAwesomeIcon icon={faEarthAmericas} size="2x" />
					</div>
          <div className="container-body">
            <p>O endereço de CIDR ou IP inserido para liberação deve ser válido, isto é, um endereço de saída da rede.</p>
            <p>Caso esteja dentro da rede a ser liberada, para saber o IP de saída da rede onde está, acesse o link abaixo (abre em outra aba):</p>
            <p><a href={link} target="blank">{link}</a></p>
          </div>
        </AlertModal>
	}

}
import React, { Component } from "react";
import store from '../../providers/store';
import { limpaMensagem } from '../../providers/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	return {
		mensagem: state.mensagemStore.mensagem
	}
}

export const Message =

// redux
connect(
	(mapStateToProps)
)(
	class extends Component {

		limparMensagem() {
			store.dispatch(limpaMensagem());
		}

		render() {
			const mensagem = this.props.mensagem;
			let classeDiv;
			let classeIcone;
			let titulo;
			if( mensagem.tipo === 'SUCCESS') {
				classeDiv = 'br-alert is-success';
				classeIcone = 'fas fa-check-circle';
				titulo = 'Sucesso!';
			} else if (mensagem.tipo === 'ERROR'){
				classeDiv = 'br-alert is-danger';
				classeIcone = 'fas fa-times-circle';
				titulo = 'Erro!';
			} else if (mensagem.tipo === 'INFO') {
				classeDiv = 'br-alert is-info';
				classeIcone = 'fas fa-info-circle';
				titulo = 'Informação!';
			} else if (mensagem.tipo === 'WARNING') {
				classeDiv = 'br-alert is-warning';
				classeIcone = 'fas fa-exclamation-circle';
				titulo = 'Atenção!';
			}

			return (
				<div className="noprint">
				{
					(mensagem && mensagem.tipo) &&
					<div className={classeDiv}>
							<script>function toTop() { window.scrollTo(0, 0) }()</script>
							<div className="icon">
								<i className={classeIcone}></i>
								<span className="sr-only">{titulo}</span>
							</div>
							<div className="content">
								{mensagem.texto}
								{(mensagem.erros) && <ul>{mensagem.erros.map((erro, index) => <li key={index}>{erro.mensagem}</li>)}</ul>}
							</div>
							<div className="close">
								<button onClick={this.limparMensagem} type="button">
									<i className="fas fa-times"></i>
								</button>
							</div>
					</div>
				}
				</div>
			);
		}

	}

); // redux
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { Pagination } from "../Pagination/Pagination";
import {getMessagemErro} from "../../utils/erro";
import {formataData} from '../../utils/formatadorData';
import api from "../../services/api";
import { baixarArquivo } from "../../utils/downloadUtil";

import './Whitelist.css';
import { TIPO_AMBIENTE_HOMOLOGACAO, TIPO_AMBIENTE_PRODUCAO } from "../../providers/constTiposAmbiente";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const WhitelistList =

// router
withRouter(
// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {
				criterioPesquisa: {
					orgao: '',
					tipoAmbiente: '',
				},
				orgaos: [],
				tiposAmbiente: [TIPO_AMBIENTE_HOMOLOGACAO, TIPO_AMBIENTE_PRODUCAO],
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.mostraRelatorioCSV = this.mostraRelatorioCSV.bind(this);
			this.mostraRelatorioPDF = this.mostraRelatorioPDF.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Listar IPs Liberados</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			
			const user = this.props.user;
			if(user.isAdministrador || user.isMantenedor){
				api.get('/api/orgao/perfil-usuario?ativos=true')
					.then(response => { 
						this.setState({orgaos: response.data }) 
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar os órgãos.", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					});
			}
		}

		renderForm() {
			const user = this.props.user;

			const { criterioPesquisa, resultadoPesquisa, pesquisaExecutada, orgaos, tiposAmbiente } = this.state;
			const { content } = resultadoPesquisa;
			const exibeBotoes = content && content.length > 0;
			const { orgao, tipoAmbiente } = criterioPesquisa;

			return(
				<div className="container-fluid">
					<div className="br-form">
						{ (user.isAdministrador || user.isMantenedor) &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Órgão:</label>
											<select name="orgao" onChange={this.handleInputChange} value={orgao}>
												<option value="">Todos</option>
													{
														orgaos.map(orgao =>
															<option 
																key={orgao.id} 
																value={orgao.id}>
																{orgao.nome}
															</option>
													)}
											</select>
										</div>
									</div>
								</div>
							</div>
						}

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Ambiente:</label>
										<select name="tipoAmbiente" onChange={this.handleInputChange} value={tipoAmbiente}>
												<option value="">Todos</option>
												{
													tiposAmbiente.map(tipoAmbiente =>
														<option 
															key={tipoAmbiente.id} 
															value={tipoAmbiente.id}>
															{tipoAmbiente.nome}
														</option>
												)}
											</select>
										
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col" style={{width: "50%"}}>Órgão</th>
											<th scope="col">Ambiente</th>
											<th scope="col">CIDR</th>
											<th scope="col" style={{width: "10%"}} className="text-center">Data de Cadastro</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((whitelist, index) =>
													<tr key={index} >
														<td>{whitelist.nomeOrgao}</td>
														<td>{whitelist.nomeTipoAmbiente}</td>
														<td>{whitelist.cidr}</td>
														<td className="text-center">{formataData(whitelist.dataCadastro)}</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhum cadastro encontrado com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa[nome] = valor;
			this.setState({ criterioPesquisa });
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){
			const {criterioPesquisa} = this.state;
			criterioPesquisa['pagina'] = pagina;
			const url = '/api/whitelist/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os IPs liberados. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa.")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.setState(
				{
					criterioPesquisa: {
						orgao: '',
						tipoAmbiente: '',
					},
					resultadoPesquisa: {},
					pesquisaExecutada: false,
					botoesHabilitados : true,
				}
			)
		}

		mostraRelatorioCSV(e){
			this.mostraRelatorio(e, 'csv');
		}

		mostraRelatorioPDF(e){
			this.mostraRelatorio(e, 'pdf');
		}

		mostraRelatorio(e, tipo){
			const { criterioPesquisa } = this.state;
			const url = `/api/whitelist/${tipo}`;

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioIPsLiberados.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório com os IPs liberados';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}
		
	}

) // redux
); // router
import React, { Component } from "react";
import './Button.css';

export const ButtonInfo = 


class extends Component {

	render() {
		return (
			<button type="button" title={this.props.title} className="info">
				<span className="sr-only">{this.props.span}</span>
				<i className="fas fa-info-circle"></i>
			</button>
		);
	}

}



import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando } from "../../providers/actions";
import api from "../../services/api";
import './Manager.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { cpf } from 'cpf-cnpj-validator'; 

const ufs = ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'];
const mapDispatchToProps = { exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
registerLocale("pt_br", pt_br);

export const ManagerEditNotSiape =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleDataExpedicaoRGChange = this.handleDataExpedicaoRGChange.bind(this);
			this.atualizaPropriedadeGestor = this.atualizaPropriedadeGestor.bind(this);
			this.pesquisarDadosPessoais = this.pesquisarDadosPessoais.bind(this);
		}

		render() {
			return (
				<div className="div-form">
						{this.renderForm()}
				</div>
			);
		}

		renderForm() {
			const gestor = this.props.gestor;
			return(
					<div className="br-form">
						<div className="row">	
							<div className="col-sd mr-1 pr-1">
								<div className="field">
									<div className="input">
										<label>CPF:</label>
										<input type="text" name="cpf" maxLength="11"
											value={gestor.cpf}  
											onChange={this.handleInputChange}
											disabled={this.props.edicao}
											/>
									</div>
								</div>
							</div>
							<div className="col-sd d-flex align-items-end ml-0 pl-0">
								<div className="actions-button">
									<div className="actions">
										<div>
											<button type="button" className="button is-secondary" onClick={this.pesquisarDadosPessoais}>Pesquisar Dados Pessoais</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Nome:</label>
										<input type="text" name="nome" maxLength="100"
											value={gestor.nome} 
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
						</div>	

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Cargo/Função:</label>
										<input type="text" name="cargoFuncao" 
											value={gestor.cargoFuncao}  maxLength="30"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Matrícula:</label>
										<input type="text" name="matricula" 
											value={gestor.matricula}  maxLength="15"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
							
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Email:</label>
										<input type="text" name="email" 
											value={gestor.email} maxLength="60"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>RG:</label>
										<input type="text" name="numeroRG" 
											value={gestor.numeroRG}  maxLength="15"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Órgão Expedidor:</label>
										<input type="text" name="orgaoExpedidorRG" 
											value={gestor.orgaoExpedidorRG}  maxLength="10"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Data de Expedição:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={gestor.dataExpedicaoRG} onChange={this.handleDataExpedicaoRGChange} />
									</div>
								</div>
							</div>
						
						</div>

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Cep:</label>
										<input type="text" name="cep" 
											value={gestor.cep}  maxLength="8"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Logradouro:</label>
										<input type="text" name="logradouro" 
											value={gestor.logradouro}  maxLength="50"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Número:</label>
										<input type="text" name="numero" 
											value={gestor.numero}  maxLength="8"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
						
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Complemento:</label>
										<input type="text" name="complemento" 
											value={gestor.complemento}  maxLength="25"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Bairro:</label>
										<input type="text" name="bairro" 
											value={gestor.bairro}  maxLength="20"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Município:</label>
										<input type="text" name="municipio" 
											value={gestor.municipio}  maxLength="20"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>UF:</label>
										<select name="uf" onChange={this.handleInputChange} value={gestor.uf}>
											<option value="">Selecione</option>
											{
												ufs.map(uf =>
													<option 
														key={uf} 
														value={uf}>
														{uf}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>
						
						</div>

					</div>
				
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.atualizaPropriedadeGestor(nome, valor);
		}

		handleDataExpedicaoRGChange(dataExpedicaoRG){
			const dataExpedicao = dataExpedicaoRG;
			if(dataExpedicao){
				dataExpedicao.setHours(0, 0, 0, 0);
			}
			this.atualizaPropriedadeGestor('dataExpedicaoRG', dataExpedicao)
		}

		atualizaPropriedadeGestor(atributo, valorAtributo){
			const gestor = this.props.gestor;
			gestor[atributo] = valorAtributo;
			this.setState({gestor});

			this.props.onAtualizaServidor(atributo, valorAtributo);
		}

		pesquisarDadosPessoais(){
			this.props.limpaMensagem();
			const cpfInformado = this.props.gestor.cpf;
			if(!cpf.isValid(cpfInformado)){
				this.props.exibeMensagemErro("Informe um CPF válido.")
			}else{
				this.props.mostraAguardando();
				api.get(`/api/gestor/dados-pessoais/${cpfInformado}`)
				.then(response => { 
					const {Nome, Logradouro, NumeroLogradouro, Complemento,  Cep, Bairro, Municipio, UF} = response.data;
					this.atualizaDadosPessoaisGestor(Nome, Logradouro, NumeroLogradouro, Complemento, Cep, Bairro, Municipio, UF);
				})
				.catch(erro => {
					this.atualizaDadosPessoaisGestor("", "", "", "", "", "", "", "");
					console.log("Não foi possível recuperar os dados pessoais do CPF informado ", erro)
					this.props.exibeMensagemErro("Não foi possível recuperar os dados pessoais do CPF informado: " + erro['data']['mensagem'])
				})
				.finally(() => {
					this.props.escondeAguardando();
				});
			}
		}

		atualizaDadosPessoaisGestor(Nome, Logradouro, NumeroLogradouro, Complemento, Cep, Bairro, Municipio, UF){
			this.atualizaPropriedadeGestor('nome', Nome);
			this.atualizaPropriedadeGestor('logradouro', Logradouro);
			this.atualizaPropriedadeGestor('numero', NumeroLogradouro);
			this.atualizaPropriedadeGestor('complemento', Complemento);
			this.atualizaPropriedadeGestor('cep', Cep);
			this.atualizaPropriedadeGestor('bairro', Bairro);
			this.atualizaPropriedadeGestor('municipio', Municipio);
			this.atualizaPropriedadeGestor('uf', UF);
		}
	
	}

) // redux
); // router
import { combineReducers } from 'redux';
import { LOGIN, LOGOUT, GET_TOKEN, ASSINA_TERMO_CONSENTIMENTO } from './actions';
import { SUCCESS, ERROR,INFO, WARNING, CLEAN_MESSAGE } from './actions';
import { LOADING_ON, LOADING_OFF } from './actions';
import { SET_TERMO_CONSENTIMENTO_MAIS_RECENTE } from './actions';
import { getMenusUsuario } from './menus';
import { sessionStorageLogin } from '../utils/sessionStoreUtil';

import {PERFIL_CLIENTE, PERFIL_ADMININSTRADOR, PERFIL_AUDITOR, PERFIL_FORNECEDOR, PERFIL_GESTOR_API, PERFIL_MANTENEDOR} from './constPerfis';

import jwtbroken from 'jsonwebtoken';

const usuarioInitialState = {
    token: '',
    user: {},
    photo: '',
}

const mensagemInitialState = {
  mensagem: {},
  aguardando: false,
}

const termoConsentimentoInitialState = {
  termoConsentimentoMaisRecente: {},
}

function usuarioStore(state = usuarioInitialState, action) {
    switch (action.type) {
        case LOGIN: {
            const {access_token, photo} = action;
            let user = {};

            if(access_token){
               user = jwtbroken.decode(access_token);

               if(user){
                user['isCliente'] = false;
                user['isFornecedor'] = false;
                user['isAdministrador'] = false;
                user['isAuditor'] = false;
                user['isGestorAPI'] = false;
                user['isMantenedor'] = false;

                if(user.roles){
                  if (user.roles.includes(PERFIL_CLIENTE)){
                    user['isCliente'] = true;
                    user['nomePerfil'] = 'Gestor Consumidor de API';
                  }
                  if (user.roles.includes(PERFIL_FORNECEDOR)){
                    user['isFornecedor'] = true;
                    user['nomePerfil'] = 'Gestor Fornecedor de API';
                  }
                  if (user.roles.includes(PERFIL_GESTOR_API)){
                    user['isGestorAPI'] = true;
                    user['nomePerfil'] = 'Gestor de API';
                  }
                  if (user.roles.includes(PERFIL_ADMININSTRADOR)){
                    user['isAdministrador'] = true;
                    user['nomePerfil'] = 'Gestor da Plataforma';
                  }
                  if (user.roles.includes(PERFIL_AUDITOR)){
                    user['isAuditor'] = true;
                    user['nomePerfil'] = 'Perfil RFB';
                  }
                  if (user.roles.includes(PERFIL_MANTENEDOR)){
                    user['isMantenedor'] = true;
                    user['nomePerfil'] = 'Perfil Serpro';
                  }

                  const menu = getMenusUsuario(user);
                  user['menu'] = menu;
                }
              }
            }

            sessionStorageLogin(access_token, photo);

            return {
                ...state, 
                token: access_token,
                user,
                photo,
            } 
        }
        case LOGOUT: {
            return {
                ...state,
                token: '',
                user: {},
                photo: '',
            }
        }

        case GET_TOKEN: {
          return state.token;
        }

        case ASSINA_TERMO_CONSENTIMENTO: {
          let {user} = state;
          user = {...user, assinaturaTermoPendente: false};
          return { ...state, user};
        }

        default:
            return state
    }
}

function mensagemStore(state = mensagemInitialState, action) {
  switch (action.type) {
    case SUCCESS: 
    case ERROR:
    case INFO:
    case WARNING: {
      const {texto, erros} = action;
      return {
        ...state, 
        mensagem: {
          tipo: action.type,
          texto,
          erros,
        },
      }
    }
    case CLEAN_MESSAGE: {
      return {...state, mensagem: {},}
    }

    case LOADING_ON: {
      return {...state, aguardando: true}
    }

    case LOADING_OFF: {
      return {...state, aguardando: false}
    }

    default:
      return state
  }
}

function termoConsentimentoStore(state = termoConsentimentoInitialState, action) {
  switch (action.type) {
      case SET_TERMO_CONSENTIMENTO_MAIS_RECENTE: {
          const { termoConsentimentoMaisRecente } = action;

          return {
              ...state, 
              termoConsentimentoMaisRecente,
          } 
      }
      default:
          return state
  }
}

const reducers = combineReducers({
    usuarioStore,
    mensagemStore,
    termoConsentimentoStore,
})

export default reducers;
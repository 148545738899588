import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando } from "../../providers/actions";
import { setTermoConsentimentoMaisRecente } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './ConsentTerm.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, setTermoConsentimentoMaisRecente }
registerLocale("pt_br", pt_br);

export const ConsentTermEdit =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				termoConsentimento: {
					data: "",
					link: "",
				},
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.salvar = this.salvar.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Termos de Uso e de Politica de Privacidade</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			api.get('/api/termo-consentimento/mais-recente')
			.then(response => { 
				const termoConsentimento = response.data;
				this.setState(
					{
						termoConsentimento,
					}) 
			})
			.catch(erro => {
				if(erro.status !== 404) {
					console.log("Não foi possível recuperar o termo de consentimento " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				}
			});

		}

		renderForm() {
			return(
				<div className="container-fluid">
					<div className="br-form">

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Data de publicação :</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={this.state.termoConsentimento.data} 
										onChange={data => {
											const termoConsentimento = this.state.termoConsentimento;
											termoConsentimento['data'] = data;
											this.setState({termoConsentimento})}
										}  />
									</div>
								</div>
							</div>	
						
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Link:</label>
										<input type="text" name="link" 
											value={this.state.termoConsentimento.link}  
											onChange={this.handleInputChange} 
											maxLength="1000"/>
									</div>
								</div>
							</div>	
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>Salvar</button>

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const termoConsentimento = this.state.termoConsentimento;
			termoConsentimento[nome] = valor;
			this.setState({ termoConsentimento: termoConsentimento });
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const termoConsentimento = this.state.termoConsentimento;
			let url = "/api/termo-consentimento";

			const options = {
				method : 'post',
				url,
				"data" : termoConsentimento,
			}

			api(options)
				.then(response => {
					const mensagem = `Termos de Uso e de Politica de Privacidade registrados com sucesso!`;
					const termoConsentimento = response.data;
					this.setState({ 
						termoConsentimento,
					});
					this.props.setTermoConsentimentoMaisRecente(termoConsentimento);
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível registrar os Termos de Uso e de Politica de Privacidade.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});   

		}

		validaFormulario(){
			let mensagens = [];
			const data = this.state.termoConsentimento.data;
			if(!data){
				mensagens.push({mensagem: "Data de publicação é obrigatória."})
			}

			const link = this.state.termoConsentimento.link;
			if(!link || link.trim().length === 0 ){
				mensagens.push({mensagem: "Link é obrigatório."})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
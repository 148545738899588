const maskedCpf = function(cpf){
    if(!cpf){
        return null;
    }

    let maskedCpf = cpf;
    //remove mascara, se houver:
    maskedCpf = maskedCpf.replace(new RegExp("\\.","g"),"").replace(/-/g, "");
    //completa com zeros esquerda:
    maskedCpf = ("00000000000"+maskedCpf).slice(-11);

    return maskedCpf.substring(0,3) + "." + maskedCpf.substring(3,6) + "." + maskedCpf.substring(6,9) + "-" + maskedCpf.substring(9);
   
};

const maskedCnpj = function(cnpj){
    if(! cnpj){
        return null;
    }
    
    let maskedCnpj = cnpj;
    //remove mascara, se houver:
    maskedCnpj = maskedCnpj.replace(new RegExp("\\.","g"),"").replace(/-/g, "").replace(("/",""));
    //completa com zeros esquerda:
    maskedCnpj = ("00000000000000"+maskedCnpj).slice(-14);

    return maskedCnpj.substring(0,2) + "." + maskedCnpj.substring(2,5) + "." + maskedCnpj.substring(5,8) + "/" + maskedCnpj.substring(8,12) + "-" + maskedCnpj.substring(12);
   
};

export {maskedCpf, maskedCnpj}
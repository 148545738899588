import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { Pagination } from "../Pagination/Pagination";
import api from "../../services/api";
import './APIAttachmentType.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const APIAttachmentTypeList =

// router
withRouter(
// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {
				criterioPesquisa: {
					nome: '',
				},
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				botoesHabilitados : true,
		 	};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Tipos de Anexo de API</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
		}

		renderForm() {
			const { criterioPesquisa, resultadoPesquisa, pesquisaExecutada } = this.state;
			const { nome } = criterioPesquisa;
			const { content } = resultadoPesquisa;

			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="nome">Nome:</label>
										<input type="text" name="nome" 
											value={nome}  
											onChange={this.handleInputChange} 
											maxLength="100"/>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/api-attachment-type/new"> 
									<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Novo</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col" style={{width: "95%"}}>Nome</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((tipoAnexo) =>
													<tr key={tipoAnexo.id} >
														<td>{tipoAnexo.nome}</td>
														<td className="text-center">
															<div className="actions">
																<Link to={`/api-attachment-type/edit/${tipoAnexo.id}`}>
																	<button type="button" disabled={!this.state.botoesHabilitados} title="Editar o contrato">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																</Link>
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhum tipo de anexo de API encontrado com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa[nome] = valor;
			this.setState({ criterioPesquisa });
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){

			const {criterioPesquisa} = this.state;
			criterioPesquisa['pagina'] = pagina;
			const url = '/api/tipo-anexo-aplicacao-fornecedora/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de anexo de API. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.setState({
				criterioPesquisa: {
					nome: '',
				},
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				botoesHabilitados : true,
			});
		}
	}

) // redux
); // router
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Pagination } from "../Pagination/Pagination";
import {getMessagemErro} from "../../utils/erro";
import {formataData} from '../../utils/formatadorData';
import api from "../../services/api";
import './Contract.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const ContractList =

// router
withRouter(
// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {criterioPesquisa: {
					idTipoContrato: '',
					nomeContrato: '',
					nomeItemFaturavel: '',
					ativos : true,
				},
				tiposContratos: [],
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				exibeModalAtivar: false,
				exibeModalInativar: false,
				botoesHabilitados : true,
		 	};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.fechaModais = this.fechaModais.bind(this);
			this.handleOkModais = this.handleOkModais.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Contratos</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			api.get('/api/contrato/tipos')
				.then(response => { 
					this.setState(
						{
							tiposContratos: response.data,
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de contrato " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		renderForm() {
			const { criterioPesquisa, resultadoPesquisa, pesquisaExecutada } = this.state;
			const { idTipoContrato, nomeContrato, nomeItemFaturavel, ativos } = criterioPesquisa;
			const { content } = resultadoPesquisa;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalAtivar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente habilitar o contrato?"/>

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalInativar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente desabilitar o contrato?"/>

					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="idTipoContrato">Tipo de Contrato:</label>
										{ this.state.tiposContratos.length > 0 && 
										<select name="idTipoContrato" id="idTipoContrato" 
											onChange={this.handleInputChange} 
											value={idTipoContrato} >
											<option value="">Todos</option>
											{
												this.state.tiposContratos.map(tipoContrato =>
													<option 
														key={tipoContrato.codigo} 
														value={tipoContrato.codigo}>
														{tipoContrato.nome}
													</option>
											)}
										</select>
										}
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="nomeContrato">Nome:</label>
										<input type="text" id="nomeContrato" name="nomeContrato" 
											value={nomeContrato}  
											onChange={this.handleInputChange} 
											maxLength="100"/>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="nomeItemFaturavel">Item Faturável:</label>
										<input type="text" id="nomeItemFaturavel" name="nomeItemFaturavel" 
											value={nomeItemFaturavel}  
											onChange={this.handleInputChange} 
											maxLength="255"/>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="ativos">Selecionar:</label>
										<select id="ativos" name="ativos" value={ativos} onChange={this.handleInputChange}>
											<option value="">Todos</option>
											<option value="true">Ativos</option>
											<option value="false">Inativos</option>	
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/contract/new"> 
									<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Novo</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">Tipo de Contrato</th>
											<th scope="col">Nome</th>
											<th scope="col">Item Faturável</th>
											<th scope="col">Situação</th>
											<th scope="col">Início Vigência</th>
											<th scope="col">Fim Vigência</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((contrato, index) =>
													<tr key={index} >
														<td>{contrato.nomeTipoContrato}</td>
														<td>{contrato.nomeContrato}</td>
														<td>{contrato.nomeItemFaturavel}</td>
														<td>
															{contrato.ativo === true ? 'Ativo' : 'Inativo'}
														</td>
														<td>{formataData(contrato.inicioVigencia)}</td>
														<td>{formataData(contrato.fimVigencia)}</td>
														<td className="text-center">
															<div className="actions">
																{ contrato.ativo === true &&
																	<Link to={`/contract/edit/${contrato.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados} title="Editar o contrato">
																			<span className="sr-only">Editar</span>
																			<i className="fas fa-edit"></i>
																		</button>
																	</Link>
																}

																{ (contrato.ativo === true && contrato.idTipoContrato === 'A') &&
																	<Link to={`/contract/api-contract-details/${contrato.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados}  title="Editar as informações de faturamento das API's do contrato">
																			<span className="sr-only">Informações de faturamento das API's do contrato</span>
																			<i class="fas fa-search-dollar"></i>
																		</button>
																	</Link>
																}

																{ contrato.ativo === false &&
																	<button type="button" disabled="true" title="Editar o contrato">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																}

																{ contrato.ativo === true &&
																	<button type="button" onClick={() => this.confirmaOperacao(contrato)} disabled={!this.state.botoesHabilitados}  title="Desabilitar o contrato">
																		<span className="sr-only">Desativar</span>
																		<i className="fas fa-power-off" id="on"></i>
																	</button>
																}
																
																{contrato.ativo === false &&
																	<button type="button" onClick={() => this.confirmaOperacao(contrato)} disabled={!this.state.botoesHabilitados} title="Habilitar o contrato">
																		<span className="sr-only">Ativar</span>
																		<i className="fas fa-power-off" id="off"></i>
																	</button>
																}
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhum contrato encontrado com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa[nome] = valor;
			this.setState({ criterioPesquisa });
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){

			const {criterioPesquisa} = this.state;
			criterioPesquisa['pagina'] = pagina;
			const url = '/api/contrato/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os contratos. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.setState({
				criterioPesquisa: {
					nomeContrato: '',
					nomeItemFaturavel: '',
					ativos : true,
				},
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				exibeModalAtivar: false,
				exibeModalInativar: false,
				botoesHabilitados : true,
			});
		}

		alterarSituacao (contrato){
			const body = { ativo: !contrato.ativo }
			this.setState({botoesHabilitados:false});
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			window.scrollTo(0, 0)

			api.put(`/api/contrato/situacao/${contrato.id}`, body)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					let mensagem = contrato.ativo === true ? 'Contrato desabilitado com sucesso!' : 'Contrato habilitado com sucesso!';
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível alterar a situação do contrato.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});
		}

		handleOkModais () {
			this.alterarSituacao(this.state.contratoAlvo);
			this.fechaModais();
		}

		confirmaOperacao(contrato) {
			this.setState(
				{
					exibeModalAtivar: contrato.ativo === false,
					exibeModalInativar: contrato.ativo === true,
					contratoAlvo: contrato,
				}
			);
		}

		fechaModais () {
			this.setState(
				{
					exibeModalAtivar:false,
					exibeModalInativar:false
				}
			);
		}
	}

) // redux
); // router
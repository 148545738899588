import React, { Component } from "react";
import { Fragment } from "react";
import { connect } from 'react-redux';
import './AumentaNivelGovBr.css';

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user
	}
}

const informacaoCertificado = 'Sua conta Gov.br não tem o Selo “Ouro” ou “Prata” os quais representam um nível mais alto de segurança. Logo, a única forma de assinatura digital para geração da credencial é por meio de Certificado Digital ICP-Brasil';

export const InformacaoCertificado =

// redux
connect(
	(mapStateToProps), 
	(null)
)(

	class extends Component {

		render() {
			return (
				<Fragment>
					{this.props.user.nivelConfiabilidade === 'BRONZE' &&
						<i className="fa fa-info-circle" style={{color:'#ffcd07'}} title={informacaoCertificado}/>
					}
				</Fragment>
			);
		}

	}
);
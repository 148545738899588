import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './Application.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user
	}
}

const initialState = {
	aplicacoesConsumidoras: [],
	nome: "",
	descricao: "",
	orgao: "",
	ativo: true,
	carregandoOrgaos: false,
	pesquisaExecutada: false,
	exibeModalAtivar: false,
	exibeModalInativar: false,
	botoesHabilitados : true,
};

export const ApplicationList =

// router
withRouter(
// redux
connect(
	mapStateToProps, 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = initialState;
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.fechaModais = this.fechaModais.bind(this);
			this.handleOkModais = this.handleOkModais.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Aplicações do Órgão</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			if(this.props.user.isAdministrador === true){
				this.setState({carregandoOrgaos : true});
				api.get('/api/orgao/perfil-usuario?ativos=true')
				.then(response => { this.setState({orgaos: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar os órgãos " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				})
				.finally(() => {
					this.setState({carregandoOrgaos : false});
				});
			}
		}

		renderForm() {
			const { aplicacoesConsumidoras, pesquisaExecutada, nome, descricao, ativo } = this.state;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalAtivar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente habilitar a aplicação?"/>

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalInativar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente desabilitar a aplicação?"/>

					<div className="br-form">
						{this.props.user.isAdministrador === true &&	
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="orgao">Órgão:</label>						
										</div>
										<div className="input">
											<select id="orgao" name="orgao" onChange={this.handleInputChange} value={this.state.orgao}>
												<option value="">Selecione</option>
												{
													this.state.orgaos?.map(orgao =>
														<option 
															key={orgao.id} 
															value={orgao.id}>
															{orgao.nome}
														</option>
												)}
											</select>
											{this.state.carregandoOrgaos === true && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							</div>
						}
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Nome:</label>
										<input type="text" name="nome" 
											value={nome}  
											onChange={this.handleInputChange} 
											maxLength="15"/>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Descrição:</label>
										<input type="text" name="descricao" 
											value={descricao}  
											onChange={this.handleInputChange} 
											maxLength="255"/>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Selecionar:</label>
										<select name="ativo" value={ativo} onChange={this.handleInputChange}>
											<option value="0">Todos</option>
											<option value="true">Ativas</option>
											<option value="false">Inativas</option>	
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/application/new"> 
									<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Nova</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(aplicacoesConsumidoras && aplicacoesConsumidoras.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">Nome</th>
											<th scope="col">Descrição</th>
											<th scope="col">Adesões</th>
											<th scope="col">Situação</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ aplicacoesConsumidoras.map((aplicacao, index) =>
													<tr key={index} >
														<td>{aplicacao.nome}</td>
														<td>{aplicacao.descricao}</td>
														<td>
														{(aplicacao.adesoes && aplicacao.adesoes.length > 0) && <ul>{aplicacao.adesoes.map(adesao => <li>{adesao.nomeFornecedora}</li>)}</ul>}
														</td>
														<td>
															{aplicacao.ativo === true ? 'Ativa' : 'Inativa'}
														</td>
														<td className="text-center">
															<div className="actions">
																{ aplicacao.ativo === true &&
																	<Link to={`/application/edit/${aplicacao.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados} title="Editar a aplicação">
																			<span className="sr-only">Editar</span>
																			<i className="fas fa-edit"></i>
																		</button>
																	</Link>
																}

																{ aplicacao.ativo === false &&
																	<button type="button" disabled="true" title="Editar a aplicação">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																}

																{ aplicacao.ativo === true &&
																	<Link to={`/application/access/${aplicacao.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados} title="Editar as adesões da aplicação">
																			<span className="sr-only">Adesões</span>
																			<i className="fas fa-handshake fa-lg"></i>
																		</button>
																	</Link>
																}

																{ aplicacao.ativo === false &&
																	<button type="button" disabled="true" title="Editar as adesões da aplicação">
																		<span className="sr-only">Adesões</span>
																		<i className="fas fa-handshake fa-lg"></i>
																	</button>
																}

																{ aplicacao.ativo === true &&
																	<button type="button" onClick={() => this.confirmaOperacao(aplicacao)} disabled={!this.state.botoesHabilitados}  title="Desabilitar a aplicação">
																		<span className="sr-only">Desativar</span>
																		<i className="fas fa-power-off" id="on"></i>
																	</button>
																}
																
																{aplicacao.ativo === false &&
																	<button type="button" onClick={() => this.confirmaOperacao(aplicacao)} disabled={!this.state.botoesHabilitados} title="Habilitar a aplicação">
																		<span className="sr-only">Ativar</span>
																		<i className="fas fa-power-off" id="off"></i>
																	</button>
																}
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! aplicacoesConsumidoras || aplicacoesConsumidoras.length === 0)) &&
							<div>
								Nenhuma aplicação encontrada com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		pesquisar (e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {nome,descricao, ativo, orgao } = this.state;
			this.props.mostraAguardando();
			api.get(`/api/aplicacao-consumidora/consulta?nome=${nome}&descricao=${descricao}&ativo=${ativo}&orgao=${orgao}`)
				.then(response => { 
					this.setState({
						aplicacoesConsumidoras: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar as aplicações consumidoras. ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.setState(initialState);
		}

		validaFormulario(){
			let mensagens = [];
			const {orgao} = this.state;
			const user = this.props.user;

			if(user.isAdministrador === true && !orgao){
				mensagens.push({mensagem: "Órgão é obrigatório."})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		alterarSituacao (aplicacao){
			const body = { ativo: !aplicacao.ativo }
			this.setState({botoesHabilitados:false});
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			window.scrollTo(0, 0)

			api.put(`/api/aplicacao-consumidora/situacao/${aplicacao.id}`, body)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					let mensagem = aplicacao.ativo === true ? 'Aplicação desabilitada com sucesso!' : 'Aplicação habilitada com sucesso!';
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível alterar a situação da aplicação.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});
		}

		handleOkModais () {
			this.alterarSituacao(this.state.aplicacaoAlvo);
			this.fechaModais();
		}

		confirmaOperacao(aplicacao) {
			this.setState(
				{
					exibeModalAtivar: aplicacao.ativo === false,
					exibeModalInativar: aplicacao.ativo === true,
					aplicacaoAlvo: aplicacao,
				}
			);
		}

		fechaModais () {
			this.setState(
				{
					exibeModalAtivar:false,
					exibeModalInativar:false
				}
			);
		}
	}

) // redux
); // router
import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, exibeMensagemAviso, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {formataData, formataDataMesAno} from '../../utils/formatadorData';
import {getMessagemErro} from "../../utils/erro";

import api from "../../services/api";
import './Billing.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, exibeMensagemAviso, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const BillingEdit =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				tiposContrato: [],
				tipoContrato: '',
				contratos: [],
				contrato: '',
				referencias: [],
				referencia: '',
				fornecedoras: [],
				fornecedora: '',
				carregandoTiposRelatorio: false,
				carregandoContratos: false,
				carregandoReferencias: false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.handleTipoRelatorioChange = this.handleTipoRelatorioChange.bind(this);
			this.handleContratoChange = this.handleContratoChange.bind(this);
			this.carregarContratos = this.carregarContratos.bind(this);
			this.carregarReferencias = this.carregarReferencias.bind(this);
			this.gerarPrestacaoContas = this.gerarPrestacaoContas.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Gerar Declaração de consumo</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			this.setState({carregandoTiposRelatorio: true})

			api.get('/api/contrato/tipos')
			.then(response => { this.setState({tiposContrato: response.data}) })
			.catch(erro => {
				console.log("Não foi possível recuperar os tipos de contrato", erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			})
			.finally(() => this.setState({carregandoTiposRelatorio: false}));

		}

		renderForm() {
			const { tipoContrato, contrato, referencia, fornecedora } = this.state;

			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="tipoContrato">Tipo de Contrato:</label>
										<select name="tipoContrato" id="tipoContrato" onChange={this.handleTipoRelatorioChange} value={tipoContrato}>
											<option value=''>Selecione</option>
											{
												this.state.tiposContrato?.map(tipoContrato =>
													<option 
														key={tipoContrato.codigo} 
														value={tipoContrato.codigo}>
														{tipoContrato.nome}
													</option>
											)}
										</select>
										{this.state.carregandoTiposRelatorio === true && 
										<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}
									</div>
								</div>
							</div>

							{tipoContrato &&
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="contrato">Contrato:</label>
											<select name="contrato" id="contrato" onChange={this.handleContratoChange} value={contrato}>
												<option value="">{this.state.contratos?.length > 0 ? 'Selecione' : 'Não há contratos cadastrados para o tipo selecionado'}</option>
												{
													this.state.contratos?.map(contrato =>
														<option 
															key={contrato.id} 
															value={contrato.id}>
															{contrato.nomeContrato} {'=>'} {formataData(contrato.inicioVigencia)} a {formataData(contrato.fimVigencia)}
														</option>
												)}
											</select>
											{this.state.carregandoContratos === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							}

							{contrato &&
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="contrato">Referência:</label>
											<select name="referencia" id="referencia" onChange={this.handleInputChange} value={referencia}>
												<option value="">{this.state.referencias?.length > 0 ? 'Selecione' : 'Não há referências cadastradas para o contrato selecionado'}</option>
												{
													this.state.referencias?.map(referencia =>
														<option 
															key={referencia.id} 
															value={referencia.id}>
															({formataDataMesAno(referencia.referencia)}) {'=>'} {formataData(referencia.periodoInicial)} a {formataData(referencia.periodoFinal)}
														</option>
												)}
											</select>
											{this.state.carregandoReferencias === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							}

						</div>

						{tipoContrato === 'A'&&
							<div className="row">
								{contrato &&
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>API:</label>
												{this.state.fornecedoras.length > 0 && 
													<select name="fornecedora" onChange={this.handleInputChange} value={fornecedora}>
														<option value="">Selecione</option>
															{
																this.state.fornecedoras.map(fornecedora =>
																	<option 
																		key={fornecedora.id} 
																		value={fornecedora.id}>
																		{fornecedora.nome}
																	</option>
															)}
													</select>
												}
												{this.state.carregandoFornecedoras === true && 
													<button className="br-button is-secondary is-circle is-loading" type="button"/>
												}

												{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'Não há APIs cadastradas no contrato selecionado.'}
											</div>
										</div>
									</div>
								}

							</div>
						}

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.gerarPrestacaoContas} disabled={!this.state.botoesHabilitados}>Gerar Declaração de Consumo</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>


					</div>
				</div>
			);
		}

		validaFormulario() {

			let mensagens = [];
			const {tipoContrato, contrato, fornecedora, referencia} = this.state;

			if(!tipoContrato || tipoContrato.trim().length === 0 ){
				mensagens.push({mensagem: "Tipo de Contrato é obrigatório."})
			} else {
				if(!contrato || contrato.trim().length === 0 ){
					mensagens.push({mensagem: "Contrato é obrigatório."})
				} else {
					if(!referencia || referencia.trim().length === 0 ){
						mensagens.push({mensagem: "Referência é obrigatório."})
					}

					if(tipoContrato === 'A' && !fornecedora){
						mensagens.push({mensagem: "API é obrigatório."})
					}
				}
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleTipoRelatorioChange(evento){
			const tipoContrato =  evento.target.value;

			this.setState({
				tipoContrato,
				contrato: '',
				contratos: [],
				referencia: '',
				referencias: [],
				fornecedora: '',
				fornecedoras: [],
			});
			this.carregarContratos(tipoContrato);
		}

		handleContratoChange(evento){
			const contrato = evento.target.value;

			this.setState({
				contrato,
				referencia: "",
				referencias: [],
				fornecedora: '',
				fornecedoras:[],
			})

			this.carregarReferencias(contrato);
			if(this.state.tipoContrato === 'A') {
				this.carregarFornecedoras(contrato);
			}
		}

		carregarContratos(tipoContrato) {
			if( !tipoContrato) {
				return;
			}

			this.setState({carregandoContratos : true });
			api.get(`/api/contrato/tipo/${tipoContrato}?ativos=true`)
				.then(response => { 
					const contratos = response.data;
					this.setState({	contratos	})
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os contratos", erro)
					this.props.exibeMensagemErro("Erro durante o carregamento dos contratos")
				})
				.finally(() => {
					this.setState({carregandoContratos : false});
				});
		}

		carregarReferencias(contrato) {
			if( !contrato) {
				return;
			}
			this.setState({carregandoReferencias : true });
			api.get(`/api/referencia-faturamento/contrato/${contrato}`)
			.then(response => { 
				const referencias = response.data;
				this.setState({	referencias	})
			 })
			.catch(erro => {
				console.log("Não foi possível recuperar as referências de faturamento do contrato", erro)
				this.props.exibeMensagemErro("Erro durante o carregamento das referências de faturamento do contrato")
			})
			.finally(() => {
				this.setState({carregandoReferencias : false});
			});
		}

		carregarFornecedoras(contrato) {
			if( !contrato) {
				return;
			}

			this.setState({carregandoFornecedoras : true});

			api.get(`/api/contrato/${contrato}/apis`)
			.then(response => { this.setState(
				{
					fornecedoras: response.data,
				}
				) 
			})
			.catch(erro => {
				console.log("Não foi possível recuperar as aplicações fornecedoras do contrato ", erro)
				this.props.exibeMensagemErro("Erro durante o carregamento das APIs do contrato")
			})
			.finally(() => this.setState({carregandoFornecedoras: false}));
		}

		gerarPrestacaoContas(e){
			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {referencia, fornecedora } = this.state;
			const faturamento = { referencia, fornecedora };

			const options = {
				method: 'post',
				url: '/api/prestacao-contas',
				data: faturamento,
			}

			this.props.limpaMensagem();
			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then(response => {
					const mensagem = response.data;
					if(mensagem) {
						this.props.exibeMensagemAviso(`Declaração de consumo gerada com sucesso! ${mensagem}`);
					} else {
						this.props.exibeMensagemSucesso('Declaração de consumo gerada com sucesso!');
					}
				})
				.catch(erro => {
					const mensagemInicial = 'Não foi possível gerar a declaração de consumo.';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.props.escondeAguardando();
					this.setState({botoesHabilitados:true});
				});   
		}

		limparPesquisa(){
			this.props.limpaMensagem();

			this.setState({
				tipoContrato: '',
				fornecedora: '',
				contrato: '',
				contratos: [],
				referencia: '',
				referencias: [],
			});
		}
	}

) // redux
); // router
export const baixarArquivo = (conteudo, nomeArquivo) => {
    const url = window.URL.createObjectURL(conteudo);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nomeArquivo);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './Organization.css';
import 'react-picky/dist/picky.css';
import { cnpj } from 'cpf-cnpj-validator'; 

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const OrganizationEdit =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				orgao: {
					id: undefined,
					nome: "",
					sigla: "",
					cnpj: "",
					vinculadoSiape: true,
					nomeRepresentanteNegocial: "",
					emailRepresentanteNegocial: "",
					nomeRepresentanteTecnico: "",
					emailRepresentanteTecnico: "",
					isAdministrador : false,
				},
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
			this.pesquisarDadosEmpresariaisBasicos = this.pesquisarDadosEmpresariaisBasicos.bind(this);
			this.atualizaOrgao = this.atualizaOrgao.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Órgãos</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				api.get('/api/orgao/' + id)
				.then(response => { 
					const orgao = response.data;
					this.setState(
						{
							orgao,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar órgão " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}
		}

		renderForm() {
			return(
				<div className="container-fluid">
					<div className="br-form">

						<div className="row">

							<div className="col-sd mr-1 pr-1">
								<div className="field">
									<div className="input">
										<label htmlFor="cnpj">CNPJ:</label>
										<input type="text" name="cnpj" maxLength="14"
											value={this.state.orgao.cnpj}  
											onChange={this.handleInputChange}
											disabled={this.state.orgao.id ? true : false}
											/>
									</div>
								</div>
							</div>

							<div className="col-sd d-flex align-items-end ml-0 pl-0">
								<div className="actions-button">
									<div className="actions">
										<div>
											<button type="button" className="button is-secondary" onClick={this.pesquisarDadosEmpresariaisBasicos}>Pesquisar Nome Órgão</button>
										</div>
									</div>
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Vinculado ao Siape:</label>
										<select name="vinculadoSiape" value={this.state.orgao.vinculadoSiape} onChange={this.handleInputChange}>
											<option value="true">Sim</option>
											<option value="false">Não</option>	
										</select>
									</div>
								</div>
							</div>	
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="nome">Nome:</label>
										<input type="text" name="nome" 
											value={this.state.orgao.nome}  
											onChange={this.handleInputChange} 
											maxLength="100"/>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="sigla">Sigla:</label>
										<input type="text" name="sigla" 
											value={this.state.orgao.sigla}  
											onChange={this.handleInputChange} 
											maxLength="50"/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="representanteNegocial">Nome Representante Negocial:</label>
										<input type="text" name="nomeRepresentanteNegocial" 
											value={this.state.orgao.nomeRepresentanteNegocial}  
											onChange={this.handleInputChange} 
											maxLength="40"/>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="emailRepresentanteNegocial">Email Representante Negocial:</label>
										<input type="text" name="emailRepresentanteNegocial" 
											value={this.state.orgao.emailRepresentanteNegocial}  
											onChange={this.handleInputChange} 
											maxLength="60"/>
									</div>
								</div>
							</div>	
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="representanteTecnico">Nome Representante Técnico:</label>
										<input type="text" name="nomeRepresentanteTecnico" 
											value={this.state.orgao.nomeRepresentanteTecnico}  
											onChange={this.handleInputChange} 
											maxLength="40"/>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="emailRepresentanteTecnico">Email Representante Técnico:</label>
										<input type="text" name="emailRepresentanteTecnico" 
											value={this.state.orgao.emailRepresentanteTecnico}  
											onChange={this.handleInputChange} 
											maxLength="60"/>
									</div>
								</div>
							</div>	
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.orgao.id ? 'Alterar' : 'Incluir'}</button>
								
								{this.state.orgao.id && 
									<Link to={`/organization/access/${this.state.orgao.id}`}> 
										<div className="back-button">
											<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Adesões</button>
										</div>
									</Link>
								}

								{this.state.orgao.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Novo Órgão</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const orgao = this.state.orgao;
			orgao[nome] = valor;
			this.setState({ orgao });
		}

		novo(e){
			const rotaParaNovo = "/organization/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const orgao = this.state.orgao;
			const method = orgao.id ? 'put' : 'post';
			let url = "/api/orgao";
			if(orgao.id){
				url = url + '/' + orgao.id;
			}
			const options = {
				method,
				url,
				"data" : orgao,
			}

			api(options)
				.then(response => {
					const mensagem = `Órgão ${this.state.orgao.id ? 'alterado' : 'incluído'} com sucesso!`;
					this.setState({ 
						orgao: response.data ,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.orgao.id ? 'alterar' : 'incluir'} o órgão.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);

				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});   

		}

		validaFormulario(){
			let mensagens = [];
			const {cnpj, nome, sigla, nomeRepresentanteNegocial, emailRepresentanteNegocial, nomeRepresentanteTecnico, emailRepresentanteTecnico, isAdministrador } = this.state.orgao;
			
			if(!cnpj || cnpj.trim().length === 0 ){
				mensagens.push({mensagem: "CNPJ é obrigatório."})
			}

			if(!nome || nome.trim().length === 0 ){
				mensagens.push({mensagem: "Nome é obrigatório."})
			}

			if(!sigla || sigla.trim().length === 0 ){
				mensagens.push({mensagem: "Sigla é obrigatória."})
			}

			if (isAdministrador === false) {
				if(!nomeRepresentanteNegocial || nomeRepresentanteNegocial.trim().length === 0 ){
					mensagens.push({mensagem: "Nome Representante Negocial é obrigatório."})
				}

				if(!emailRepresentanteNegocial || emailRepresentanteNegocial.trim().length === 0 ){
					mensagens.push({mensagem: "Email Representante Negocial é obrigatório."})
				}

				if(!nomeRepresentanteTecnico || nomeRepresentanteTecnico.trim().length === 0 ){
					mensagens.push({mensagem: "Nome Representante Técnico é obrigatório."})
				}

				if(!emailRepresentanteTecnico || emailRepresentanteTecnico.trim().length === 0 ){
					mensagens.push({mensagem: "Email Representante Técnico é obrigatório."})
				}
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		pesquisarDadosEmpresariaisBasicos(){
			this.props.limpaMensagem();
			const cnpjInformado = this.state.orgao.cnpj;
			if(!cnpj.isValid(cnpjInformado)){
				this.props.exibeMensagemErro("Informe um CNPJ válido.")
			}else{
				this.props.mostraAguardando();
				api.get(`/api/orgao/dados_empresariais/${cnpjInformado}`)
				.then(response => { 
					const {nomeEmpresarial} = response.data;
					this.atualizaOrgao(nomeEmpresarial);
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os dados empresariais do CNPJ informado ", erro)
					this.props.exibeMensagemErro("Não foi possível recuperar o nome do órgão: " + erro['data']['mensagem'])
				})
				.finally(() => {
					this.props.escondeAguardando();
				});
			}
		}

		atualizaOrgao(nomeEmpresarial){
			const orgao = this.state.orgao;
			orgao['nome'] = nomeEmpresarial;
			this.setState({orgao});
		}

	}

) // redux
); // router
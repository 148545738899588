import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { getMessagemErro } from "../../utils/erro";
import api from "../../services/api";
import { getUrlRetorno } from "../../utils/itiUtil"
import { base64ToStr } from "../../utils/base64Util";
import qs from 'query-string';
import './SolicitacaoAdesao.css';
import { PdfSigner } from "../PdfSigner/PdfSigner";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const pathRetorno = "/solicitacao-adesao/sign";

export const SolicitacaoAdesaoSigner =
withRouter(

// redux
connect(
	(null), 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
                solicitacao: {},
				termoAdesaoPDF : '',
                nomeArquivo: '',
				botoesHabilitados : true,
			};

			this.assinaTermoAdesao = this.assinaTermoAdesao.bind(this);
			this.getState = this.getState.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Assinar Solicitação de Adesão/Alteração de Volumetria</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			const id = this.props.match.params.id;
            if(! id || (isNaN(id))){
                this.props.exibeMensagemErro("Id da Solicitação não informado corretamente");
                return;
            }

			const state = localStorage.getItem('state');

			const urlParams = qs.parse(this.props.location.search);
			const code = urlParams.code;
			const error = urlParams.error;

			if((code || error) && state){
				const objState = JSON.parse(base64ToStr(state));
				this.setState(objState);

				if(!error){
					const body = {
						tipoCertificado: objState.tipoCertificado,
						urlRetorno: getUrlRetorno(`${pathRetorno}/${id}`),
						code
					}
					this.assinaTermoAdesao(body);
				}
				return;
			} 

			this.carregaSolicitacao(id);
            this.baixarTermoAdesaoPDF(id)
		}

		renderForm() {
            const {solicitacao, termoAdesaoPDF, nomeArquivo, botoesHabilitados} = this.state;
			return(
                <Fragment>
                    <div className="container-fluid">
					    <div className="br-form">
							<PdfSigner pdf={termoAdesaoPDF} 
								nomeArquivo={nomeArquivo} 
								botoesHabilitados={botoesHabilitados} 
								assinaturaHabilitada={solicitacao.assinaturaPermitida} 
								onAssinar={this.assinaTermoAdesao}
								pathRetorno={`${pathRetorno}/${solicitacao.id}`}
								stateParent={this.getState}
								/>
                        </div>
                    </div>
					
                </Fragment>
			);
		}

		carregaSolicitacao(id){
			api.get(`/api/solicitacao-adesao/${id}`)
				.then(response => { 
					this.setState({solicitacao: response.data}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar a solicitacao de adesão ", erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		baixarTermoAdesaoPDF(id){
            const options = {
				method: 'post',
				url: `/api/solicitacao-adesao/termo-adesao/pdf/${id}`,
				responseType: 'arraybuffer'
			}

			this.props.limpaMensagem();
			this.props.mostraAguardando();
			this.setState({ botoesHabilitados : false });

			api(options)
				.then((response) => {
                    let nomeArquivo = 'TermoAdesao.pdf';

					const contentDisposition = response['headers']['content-disposition'];
					if(contentDisposition) {
						nomeArquivo = contentDisposition.split("filename=")[1];
					}

					const termoAdesaoPDF = Buffer.from(response.data).toString('base64')

                    this.setState({
                        termoAdesaoPDF, 
                        nomeArquivo,
                    })
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o termo de adesão';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		getState() {
			const stateCopy = { ...this.state };
			return stateCopy;
		}

		assinaTermoAdesao(body) {
			this.setState({botoesHabilitados:false});
			this.props.mostraAguardando();

			localStorage.removeItem('state');
			body.id = this.props.match.params.id;;

			api.post('/api/solicitacao-adesao/assinar', body)
				.then(response => {
					this.props.exibeMensagemSucesso("Solicitação de adesão assinada com sucesso.");
					this.setState({solicitacao: response.data})
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível assinar a solicitação de adesão:", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

    }
) // redux
); // router
import React, { Component } from "react";
import { withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { assinaTermoConsentimento } from "../../providers/actions";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import {LatestConsentTermAgreementLink} from "../../components/ConsentTerm/LatestConsentTermAgreementLink";
import './ConsentTerm.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando, assinaTermoConsentimento }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const ConsentTermAgreement =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				aceite : false,
				botoesHabilitados : true,
			};

			this.handleInputChange = this.handleInputChange.bind(this);
			this.salvar = this.salvar.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						
						<div  style={{margin: "10px"}}>
							<h1>Termo de Uso e Política de Privacidade</h1>

							<h3>Você ainda não aceitou ou o Termo de Uso e Política de Privacidade foi atualizado. Para continuar acessando o Gerenciador de API você deve aceitar as condições do termo.</h3>

							<h3><LatestConsentTermAgreementLink textoLink="Clique aqui para visualizar"/></h3>
							
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		renderForm() {
			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="check">
									<div className="input">
										<input type="checkbox" name="aceite" checked={this.state.aceite} onChange={this.handleInputChange} />
										&nbsp;&nbsp;
										<label> Li e concordo com o Termo de Uso e Política de Privacidade.</label>
									</div>
								</div>
							</div>
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								{this.state.aceite === true &&
									<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>Enviar</button>
								}
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});

			const options = {
				method: 'post',
				url: `/api/termo-consentimento/assinar-termo-pendente`
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => {
					const mensagem = `Os termos de uso e de politica de privacidade foram aceitos com sucesso!`;
					this.props.exibeMensagemSucesso(mensagem);
					this.props.assinaTermoConsentimento();
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível cadastrar dos termos de uso e de politica de privacidade.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});   

		}

		validaFormulario(){
			let mensagens = [];

			const aceite = this.state.aceite;
			if(aceite !== true ){
				mensagens.push({'mensagem' : 'É necessário concordar com os termos de uso e de politica de privacidade.'})
			} 

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
import React, { Component } from "react";
import { connect } from 'react-redux';
import {logout} from "../../providers/actions";
import config from '../../config';
import { sessionStorageLogout } from "../../utils/sessionStoreUtil";

const mapDispatchToProps = { logout }

export const ButtonExit = 

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.handleLogout = this.handleLogout.bind(this);
		}

		render() {
			return (
				<button className="br-button is-secondary" type="button" onClick={this.handleLogout}>Sair</button>
			);
		}

		// async handleLogout(){
		// 	await this.props.logout();
			
		// 	const iframe = document.createElement('iframe');
		// 	const form = document.createElement('form');

		// 	form.action = config.govbrUrl + '/logout?post_logout_redirect_uri=' + config.govbrLougoutUrl;
		// 	form.method = "POST";
			
		// 	iframe.appendChild(form);
		// 	document.body.appendChild(iframe);
		// 	form.submit();
		// }

		handleLogout() {
			sessionStorageLogout();
			localStorage.clear();
			sessionStorage.clear();
			window.location.href = config.govbrUrl + '/logout?post_logout_redirect_uri=' + config.govbrLougoutUrl;
		}

	}

); // redux

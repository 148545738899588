import React, { Component, Fragment } from "react";
import NumberFormat from 'react-number-format';
import './SolicitacaoAdesao.css';
import { ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO } from "../../providers/constTiposSolicitacaoAdesao";

export const SolicitacaoAdesaoDetails =

class extends Component {

	render() {
		return (
			<div>
				{this.renderForm()}
			</div>
		);
	}

	renderForm() {
		const { solicitacao, mostraDetalhesAprovacao } = this.props;

		return(
			<div className="br-form">
				<div className="row">
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Número:</label>
								<input value={solicitacao.numero} readOnly/>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Órgão solicitante:</label>
								<input value={solicitacao.nomeOrgao} readOnly/>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Situação:</label>
								<input value={solicitacao.nomeSituacao} readOnly/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>API Necessária:</label>
								<input value={solicitacao.nomeFornecedora} readOnly/>
							</div>
						</div>
					</div>

					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Órgão cedente dos dados:</label>
								<input value={solicitacao.nomeOrgaoFornecedora} className="input-bold" readOnly/>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Descrição da API:</label>
								<textarea value={solicitacao.descricaoComplementarFornecedora} readOnly={true} className="input-bold" style={{height:"90px"}}/>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Dirigente de TI:</label>
								<input value={solicitacao.nomeDirigenteTi} readOnly/>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Responsável Técnico:</label>
								<input value={solicitacao.nomeResponsavelTecnico} readOnly/>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-sd">
						<div className="field">
							<div className="input">
								<label>Tipo de Solicitação:</label>
								<input value={solicitacao.nomeTipo} readOnly/>
							</div>
						</div>
					</div>
					{ solicitacao.volumetriaAtual > 0 &&
						<div className="col-sd">
							<div className="field">
								<div className="input">
									<label>Volumetria Atual:</label>
									<NumberFormat value={solicitacao.volumetriaAtual} 
										thousandSeparator={'.'} 
										decimalSeparator={','} 
										decimalScale={0}
										allowNegative={false}
										isNumericString={true}
										className="texto-alinhado-direita"
										readOnly
										/>
								</div>
							</div>
						</div>
					}
					<div className="col-sd">
						<div className="field">
							<div className="input">
								<label>Volumetria Anual Solicitada:</label>
								<NumberFormat value={solicitacao.volumetriaSolicitada} 
									thousandSeparator={'.'} 
									decimalSeparator={','} 
									decimalScale={0}
									allowNegative={false}
									isNumericString={true}
									className="texto-alinhado-direita"
									readOnly
									/>
							</div>
						</div>
					</div>
				</div>

				{solicitacao.tipo === ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO &&
					<div className="row">
						<div className="col-md">  
							<div className="field">
								<div className="input"> 
									<label htmlFor="finalidade">Justificativa para alteração de volumetria:</label>
									<textarea value={solicitacao.justificativaAlteracaoVolumetria} readOnly={true} style={{height:"300px"}}/>
								</div>
							</div>
						</div>
					</div>
				}
					
				<div className="row">
					<div className="col-md">  
						<div className="field">
							<div className="input"> 
								<label htmlFor="finalidade">Finalidade:</label>
								<textarea value={solicitacao.finalidade} readOnly={true} style={{height:"300px"}}/>
							</div>
						</div>
					</div>
				</div>

				{mostraDetalhesAprovacao &&
					<Fragment>
						<div className="row">
							<div className="col-md">  
								<div className="field">
									<div className="input"> 
										<label htmlFor="justificativaOrgaoFornecedor">Justificativa cedente dos dados:</label>
										<textarea value={solicitacao.justificativaOrgaoFornecedor} readOnly={true} onChange={this.handleInputChange} maxLength={255} style={{height:"90px"}} />
									</div>
								</div>
							</div>

							<div className="col-md">  
								<div className="field">
									<div className="input"> 
										<label htmlFor="justificativaOrgaoAdministrador">Justificativa conecta:</label>
										<textarea value={solicitacao.justificativaOrgaoAdministrador} readOnly={true} style={{height:"90px"}} />
									</div>
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Volumetria Autorizada:</label>
										<NumberFormat value={solicitacao.volumetriaAutorizada} 
											thousandSeparator={'.'} 
											decimalSeparator={','} 
											decimalScale={0}
											allowNegative={false}
											isNumericString={true}
											className="texto-alinhado-direita"
											readOnly
											/>
									</div>
								</div>
							</div>
						</div> 
					</Fragment>
				}
			</div>
		);
	}
}

import React, { Component } from 'react';
// import '/Search.css';

export default class Search extends Component {
  render() {
    return(
      <div className="col br-search py-half">
        <div className="br-input">
          <label className="sr-only">Autocomplete</label>
          <input id="search" type="text" placeholder="O que você procura?" />
        </div>
        <div className="action">
          <button className="br-button is-primary is-circle" type="button">
            <i className="fas fa-search"></i>
          </button>
          <button className="clear-button" type="button">
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    );
  }
}

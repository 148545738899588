import React, { Component } from "react";
import { Pagination } from "../Pagination/Pagination";

import './RelatorioGerencial.css';
import '../Sort/SortButton';
import { SortButton } from "../Sort/SortButton";

export const Resultado =

	class extends Component {

		render() {
			const { resultadoPesquisa, pesquisaExecutada, ordenacao } = this.props;
			const {content} = resultadoPesquisa;
			const numberFormaterInt = new Intl.NumberFormat('pt-BR', {useGrouping: true });
			const numberFormaterDec = new Intl.NumberFormat('pt-BR', {useGrouping: true, minimumFractionDigits:2, maximumFractionDigits:2 });
			const onOrderChange = this.props.onOrderChange;
			const botoesHabilitados = this.props.botoesHabilitados;
			return (
				<div>
					{(content && content.length > 0) &&	
						<div className="br-table">						
							<div className="table">
								<table>
									<thead>
										<tr>
										<th scope="col">
											Órgão Consumidor
											<SortButton title="Ordenar por Nome do Órgão" onClick={onOrderChange} disabled={!botoesHabilitados} ordenacaoAtual={ordenacao} ordenarPor={1}/>
										</th>
										<th scope="col">API</th>
										<th scope="col">Plano de Consumo</th>
										<th scope="col">
											Limite do Plano
											<SortButton title="Ordenar por Limite do Plano" onClick={onOrderChange} disabled={!botoesHabilitados} ordenacaoAtual={ordenacao} ordenarPor={2}/>
										</th>
										<th scope="col">Data do Início do Plano</th>
										<th scope="col">Data de Fim do Plano</th>
										<th scope="col" className="text-right">
											Consumo Total
											<SortButton title="Ordenar por Consumo Total" onClick={onOrderChange} disabled={!botoesHabilitados} ordenacaoAtual={ordenacao} ordenarPor={3}/>
										</th>
										<th scope="col" className="text-right">
											% Consumido
											<SortButton title="Ordenar por Percentual Consumido" onClick={onOrderChange} disabled={!botoesHabilitados} ordenacaoAtual={ordenacao} ordenarPor={4}/>
										</th>
										<th scope="col" className="text-right">
											% A Consumir
											<SortButton title="Ordenar por Percentual a Consumir" onClick={onOrderChange} disabled={!botoesHabilitados} ordenacaoAtual={ordenacao} ordenarPor={5}/>
										</th>										
										<th scope="col" className="text-right">
											Média de Consumo
											<SortButton title="Ordenar por Média de Consumo" onClick={onOrderChange} disabled={!botoesHabilitados} ordenacaoAtual={ordenacao} ordenarPor={6}/>
										</th>
										<th></th>
										</tr>
									</thead>
									
									<tbody>
										{ content.map((gerencial, index) =>
												<tr key={index} className={gerencial.adesaoAtiva === true ? 'bolded' : ''}>
													<td>{gerencial.nomeOrgao}</td>
													<td>{gerencial.nomeFornecedora}</td>
													<td>{gerencial.planoConsumo}</td>
													<td className="text-right">{numberFormaterInt.format(gerencial.limite)}/{gerencial.codigoPeriodicidade}</td>
													<td>{gerencial.dataInicio}</td>
													<td>{gerencial.dataFim}</td>
													<td className="text-right">{numberFormaterInt.format(gerencial.consumoTotal)}</td>
													<td className="text-right">{numberFormaterDec.format(gerencial.porcentagemConsumida)}</td>
													<td className="text-right">{numberFormaterDec.format(gerencial.porcentagemAConsumir)}</td>
													<td className="text-right">{numberFormaterDec.format(gerencial.mediaConsumo)}</td>
													<td><strong style={{'font-size': '22px'}}>{gerencial.adesaoAtiva === true ? '*' : ''}</strong></td>
												</tr>
											)}
									</tbody>
								</table>
								<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.props.onChangePage}/>
								<div>
									Legenda: <strong style={{'font-size': '25px'}}>*</strong> - Adesão ativa
								</div>
							</div>
						</div>
					}

					{(pesquisaExecutada && (! content || content.length === 0)) &&
						<div>
							Nenhum dado gerencial encontrado com os critérios informados.
						</div>
					}
				</div>
			);
		}
		
		
	}
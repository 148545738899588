import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './Plan.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
const limiteMaximo = 2147483647;

export const PlanEdit =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				plano: {
					idFornecedora: '',
					idPlano: '',	
					descricaoPlano: '',
					limite: '',
				},
				fornecedoras: [],
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleLimiteChange = this.handleLimiteChange.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Planos de Consumo</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const idFornecedora = this.props.match.params.idFornecedora;
			const idPlano = this.props.match.params.idPlano;

			if(idFornecedora && idPlano){
				api.get(`/api/plano/${idFornecedora}/${idPlano}`)
				.then(response => { 
					const plano = response.data;
					this.setState(
						{
							plano,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar o plano " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			api.get('/api/aplicacao-fornecedora/gestor')
			.then(response => { this.setState({fornecedoras: response.data}) })
			.catch(erro => {
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});

		}

		renderForm() {
			const edicao = this.state.plano.idFornecedora && this.state.plano.idPlano ? true : false;
			return(
				<div className="container-fluid">
					<div className="br-form">

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										<select name="idFornecedora" onChange={this.handleInputChange} value={this.state.plano.idFornecedora} disabled={edicao}>
											<option value="">Selecione</option>
												{
													this.state.fornecedoras.map(fornecedora =>
														<option 
															key={fornecedora.id} 
															value={fornecedora.id}>
															{fornecedora.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="descricaoPlano">Nome:</label>
										<input type="text" name="descricaoPlano" 
											value={this.state.plano.descricaoPlano}  
											onChange={this.handleInputChange} 
											maxLength="50"/>
									</div>
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="limite">Limite:</label>
										<input type="text" name="limite" 
											value={this.state.plano.limite}  
											onChange={this.handleLimiteChange} 
											disabled={edicao}/>
									</div>
								</div>
							</div>	
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.plano.idPlano ? 'Alterar' : 'Incluir'}</button>

								{this.state.plano.idPlano && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Novo Plano</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const plano = this.state.plano;
			plano[nome] = valor;
			this.setState({ plano });
		}

		handleLimiteChange(evento){
			const limite = evento.target.value;
			const reg = new RegExp('^[0-9]{0,10}$')

			if(!reg.test(limite)){
				return;
			}

			const plano = this.state.plano;
			plano['limite'] = limite;
			this.setState({ plano });
		}

		novo(e){
			const rotaParaNovo = "/plan/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const plano = this.state.plano;
			const method = plano.idPlano ? 'put' : 'post';
			let url = "/api/plano";
			if(plano.idPlano){
				url = url + '/' + plano.idPlano;
			}
			const options = {
				method,
				url,
				"data" : plano,
			}

			this.props.mostraAguardando();

			api(options)
				.then(response => {
					const mensagem = `Plano ${this.state.plano.idPlano ? 'alterado' : 'incluído'} com sucesso!`;
					this.setState({ 
						plano: response.data ,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.plano.idPlano ? 'alterar' : 'incluir'} o plano.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);

				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});   

		}

		validaFormulario(){
			let mensagens = [];
			const {idFornecedora, descricaoPlano, limite } = this.state.plano;
			
			if(!idFornecedora ){
				mensagens.push({mensagem: "API é obrigatória."});
			}

			if(!descricaoPlano || descricaoPlano.trim().length === 0 ){
				mensagens.push({mensagem: "Nome é obrigatóro."});
			}

			if (!limite || limite <= 0) {
				mensagens.push({mensagem: "O limite é obrigatóro e deve ser maior que zero."});
			} else {
				const numLimite = Number(limite);
				if(numLimite > limiteMaximo){
					mensagens.push({mensagem: "O limite deve ser um número menor que " + limiteMaximo + "."});
				}

				if(String(limite).indexOf(".") >= 0 || String(limite).indexOf(",") >= 0 || !Number.isInteger (numLimite)) {
					mensagens.push({mensagem: "O limite deve ser um número inteiro."});
				}
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens);
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
import React, { Component } from "react";
import './SolicitacaoAdesao.css';
import { SolicitacaoAdesaoHistory } from "./History";
import { SolicitacaoAdesaoAttachments } from "./Attachments";

export const SolicitacaoAdesaoAttachmentsAndHistory =

class extends Component {

	render() {
		return (
			<div>
				{this.renderForm()}
			</div>
		);
	}

	renderForm() {
		const { solicitacao, habilitaEdicaoAnexo, keyHistory } = this.props;

		return(
			<div className="br-form">
				<div className="row">
					<div className="col-md">
						<div className="field">
							<div className="input">
								<label>Anexos:</label>
								{solicitacao.id &&
									<SolicitacaoAdesaoAttachments solicitacao={solicitacao} habilitaEdicaoAnexo={habilitaEdicaoAnexo}/>
								}
							</div>
						</div>
					</div>
					<div className="col-sd">
						<div className="field">
							<div className="input">
								<label>Histórico:</label>
								{solicitacao.id &&
									<SolicitacaoAdesaoHistory solicitacao={solicitacao.id} key={keyHistory ? keyHistory : solicitacao.situacao}/>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

import React, { Component, Fragment } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { getMessagemErro } from "../../utils/erro";
import { ID_TIPO_AMBIENTE_AMBOS, TIPO_AMBIENTE_HOMOLOGACAO, TIPO_AMBIENTE_PRODUCAO, TIPO_AMBIENTE_AMBOS } from "../../providers/constTiposAmbiente";
import { CD_INCLUSAO, CD_EXCLUSAO, OPERACAO_INCLUSAO, OPERACAO_EXCLUSAO } from "../../providers/constOperacoesWhitelist";
import api from "../../services/api";
import './SolicitacaoCadastroWhitelist.css';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle} from "@fortawesome/free-solid-svg-icons";


import isCidr from "is-cidr";
import { overlapCidr } from "cidr-tools";
import { isIP } from 'is-ip';
import { getRangeIp } from "../../utils/cidrUtil";
import { ModalIntrucoesCadastroWhitelist } from "./ModalIntrucoesCadastroWhitelist";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

const RANGE_IP = "Range de IP's";

const CIDR = "CIDR";

const TIPOS_CADASTRO = [RANGE_IP, CIDR]

export const SolicitacaoCadastroWhitelistEdit =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				solicitacao: {
					id: '',
					numero: '',
					orgao: '',
					cpfSolicitante: '',
					dataSituacao: '',
					situacao: '',
					operacao: CD_INCLUSAO,
					cidrs: [],
					whitelistExclusao: []
				},
				cidrEmEdicao : {
					tipoCadastro: CIDR,
					id: '',
					tipoAmbiente: '',
					cidr: '',
					ipInicial: '',
					ipFinal: '',
				},
				indiceCidrEdicao: -1,
				indiceCidrExclusao: -1,
				orgaos: [],
				whitelistOrgao: [],
				tiposAmbiente: [TIPO_AMBIENTE_AMBOS, TIPO_AMBIENTE_HOMOLOGACAO, TIPO_AMBIENTE_PRODUCAO],
				operacoes: [OPERACAO_INCLUSAO, OPERACAO_EXCLUSAO],
				exibeModalExcluir: false,
				exibeModalInstrucao: true,
				botoesHabilitados : true,
				carregandoWhitelist: false,
			};
			this.handleInputChangeSolicitacao = this.handleInputChangeSolicitacao.bind(this);
			this.handleInputChangeCidr = this.handleInputChangeCidr.bind(this);
			this.handleTipoCadastro = this.handleTipoCadastro.bind(this);
			this.salvarCidr = this.salvarCidr.bind(this);
			this.editarCidr = this.editarCidr.bind(this);
			this.excluirCidr = this.excluirCidr.bind(this);
			this.confirmarExclusaoCidr = this.confirmarExclusaoCidr.bind(this);
			this.limparInfoCidr = this.limparInfoCidr.bind(this);
			this.handleOperacaoChange = this.handleOperacaoChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.carregarWhitelistOrgao = this.carregarWhitelistOrgao.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Solicitação de Liberação de IPs</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;
			const user = this.props.user;

			if(id){
				api.get(`/api/solicitacao-cadastro-whitelist/${id}`)
					.then(response => { 
						const solicitacao = response.data;
						solicitacao.cidrs = solicitacao.cidrs.map((cidr) => {return {...cidr, tipoCadastro: CIDR}});
						this.setState(
							{
								solicitacao,
							}) 
						if(solicitacao.operacao === CD_EXCLUSAO){
							this.carregarWhitelistOrgao(solicitacao.orgao);
						}
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar a solicitação de liberaçao de IPs. ", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					});
			}

			if(user.isAdministrador || user.isMantenedor){
				api.get('/api/orgao/perfil-usuario?ativos=true')
					.then(response => { 
						this.setState({orgaos: response.data }) 
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar os órgãos.", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					});
			} else {
				this.carregarWhitelistOrgao(user.idOrgao);
			}
		}

		renderForm() {
			const numberFormater = new Intl.NumberFormat('pt-BR', {useGrouping: true });
			const { solicitacao, cidrEmEdicao, indiceCidrEdicao, whitelistOrgao } = this.state;
			const { cidrs, whitelistExclusao } = solicitacao;
			const user = this.props.user;
			const usuarioAdmMan = user.isAdministrador === true || user.isMantenedor === true;

			const exibeWhitelistExclusao = solicitacao.operacao === CD_EXCLUSAO && (( usuarioAdmMan && solicitacao.orgao) || !(usuarioAdmMan)) ;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalExcluir}
						handleOk={() => this.excluirCidr()}
						handleCancel={() => this.fecharModais()}
						message="Deseja realmente remover o CIDR?"/>

					<ModalIntrucoesCadastroWhitelist
						titulo="CIDR's e IP's devem ser válidos" 
						visible={this.state.exibeModalInstrucao}
						handleClose={() => this.setState({exibeModalInstrucao: false})}
					/>

					<div className="br-form">
						{ solicitacao.id &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Número:</label>
											{solicitacao.numero}
										</div>
									</div>
								</div>
							</div>
						}
						
						<div className="row">
							{ (usuarioAdmMan) && 
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Órgão:</label>
											<select name="orgao" onChange={this.handleOrgaoChange} value={solicitacao.orgao}>
												<option value="">Selecione</option>
													{
														this.state.orgaos?.map(orgao =>
															<option 
																key={orgao.id} 
																value={orgao.id}>
																{orgao.nome}
															</option>
													)}
											</select>
										</div>
									</div>
								</div>
							}

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Operação:</label>
										<select name="operacao" onChange={this.handleOperacaoChange} value={solicitacao.operacao} disabled={solicitacao.id}>
												{
													this.state.operacoes?.map(operacao =>
														<option 
															key={operacao.codigo} 
															value={operacao.codigo}>
															{operacao.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
						</div>

						{solicitacao.operacao === CD_INCLUSAO &&
							<Fragment>
								<div className="row">
									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label>Ambiente:</label>
												<select name="tipoAmbiente" onChange={this.handleInputChangeCidr} value={cidrEmEdicao.tipoAmbiente}>
													<option value="">Selecione</option>
														{
															this.state.tiposAmbiente?.map(tipoAmbiente =>
																<option 
																	key={tipoAmbiente.id} 
																	value={tipoAmbiente.id}>
																	{tipoAmbiente.nome}
																</option>
														)}
												</select>
											</div>
										</div>
									</div>

									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label>
													Informar:&nbsp;
													<button onClick={() => this.setState({exibeModalInstrucao: true})} 
														className="link-button"
														disabled={!this.state.botoesHabilitados}>
														<FontAwesomeIcon icon={faQuestionCircle} style={{color: "#1351b4"}} /> 
													</button>
												</label>
												<select name="tipoCadastro" onChange={this.handleTipoCadastro} value={cidrEmEdicao.tipoCadastro} disabled={indiceCidrEdicao >=0}>
													{
														TIPOS_CADASTRO?.map(tipoCadastro =>
															<option 
																key={tipoCadastro} 
																value={tipoCadastro}>
																{tipoCadastro}
															</option>
													)}
												</select>
											</div>
										</div>
									</div>

									{cidrEmEdicao.tipoCadastro === CIDR &&
										<div className="col-md">
											<div className="field">
												<div className="input">
													<label>CIDR:</label>
													<input type="text" name="cidr" 
														value={cidrEmEdicao.cidr}  
														onChange={this.handleInputChangeCidr} 
														maxLength="50"/>
												</div>
											</div>
										</div>
									}

									{cidrEmEdicao.tipoCadastro === RANGE_IP &&
										<Fragment>
											<div className="col-md">
												<div className="field">
													<div className="input">
														<label>IP Inicial:</label>
														<input type="text" name="ipInicial" 
															value={cidrEmEdicao.ipInicial}  
															onChange={this.handleInputChangeCidr} 
															maxLength="50"/>
													</div>
												</div>
											</div>

											<div className="col-md">
												<div className="field">
													<div className="input">
														<label>IP Final:</label>
														<input type="text" name="ipFinal" 
															value={cidrEmEdicao.ipFinal}  
															onChange={this.handleInputChangeCidr} 
															maxLength="50"/>
													</div>
												</div>
											</div>
										</Fragment>
									}

									<div className="col-sd">
										<div className="actions justify-content-start">
											<button type="button" className="button is-secondary" onClick={this.salvarCidr} disabled={!this.state.botoesHabilitados}>{indiceCidrEdicao < 0 ? 'Incluir' : 'Alterar'}</button>
											{this.state.indiceCidrEdicao >= 0 && 
												<button type="button" className="button is-secondary" onClick={this.limparInfoCidr} disabled={!this.state.botoesHabilitados}>Cancelar</button>
											}
										</div>
									</div>
								</div>
								
								<fieldset>
									<legend>Lista de CIDR's a serem cadastrados</legend>
									{(cidrs && cidrs.length > 0) &&	
										<div className="br-table">						
											<div className="table">
												<table>
													<thead>
														<tr>
														<th scope="col">Ambiente</th>
														<th scope="col">CIDR</th>
														<th scope="col">Range</th>
														<th scope="col" className="text-right">Quantidade IP's</th>
														<th className="text-center" scope="col">Ações</th>
														</tr>
													</thead>
													
													<tbody>
														{ cidrs?.map((cidrTabela, index) => {
															const rangeIp = getRangeIp(cidrTabela.cidr)
															return ( 
																<tr key={index} >
																	<td>{this.getNomeTipoAmbiente(cidrTabela.tipoAmbiente)}</td>
																	<td>{cidrTabela.cidr}</td>
																	<td>{rangeIp.ipInicial} -{'>'} {rangeIp.ipFinal}</td>
																	<td className="text-right">{numberFormater.format(rangeIp.quantidade)}</td>
																	<td className="text-center">
																		<div className="actions">
																			<button type="button" onClick={() => this.confirmarExclusaoCidr(index)} disabled={this.state.botoesHabilitados === false} title="Excluir CIDR">
																				<span className="sr-only">Excluir</span>
																				<i className="fas fa-trash"></i>
																			</button>

																			<button type="button" onClick={() => this.editarCidr(index)} disabled={this.state.botoesHabilitados === false} title="Editar CIDR">
																				<span className="sr-only">Editar</span>
																				<i className="fas fa-edit"></i>
																			</button>

																		</div>
																	</td>
																</tr>
															)
														})}
													</tbody>
												</table>
											</div>
										</div>
									}

									{(! cidrs || cidrs.length === 0) &&
										<div>
											Nenhum CIDR adicionado.
										</div>
									}
								</fieldset>
							</Fragment>
						}

						{ exibeWhitelistExclusao && 
							<div className="row">	
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="cidrsExclusao">CIDR's para exclusão:</label>						
										</div>
										<div>
											{this.state.carregandoWhitelist === true && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
											<Picky
												name="cidrsExclusao"
												options={whitelistOrgao}
												value={whitelistExclusao}
												valueKey="id"
												labelKey='nome' 
												multiple={true}
												includeSelectAll={true}
												includeFilter={true}
												onChange={values => this.handlePickyChange(values)}
												selectAllText="Selecionar todos"
												placeholder="Nenhum CIDR selecionado"
												keepOpen={true}
												/>
										</div>
									</div>
								</div>
							</div>
						}

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.solicitacao.id ? 'Alterar' : 'Incluir'}</button>

								{this.state.solicitacao.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Nova Solicitação</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
				
			);
		}

		validaCidr(){
			let mensagens = [];
			const { cidrEmEdicao} = this.state;

			if(!cidrEmEdicao.tipoAmbiente){
				mensagens.push({mensagem: "Ambiente é obrigatório."})
			} 

			if (cidrEmEdicao.tipoCadastro === CIDR) {
				if(!cidrEmEdicao.cidr || cidrEmEdicao.cidr.trim().length === 0 ){
					mensagens.push({mensagem: "CIDR é obrigatório."})
				} else if (isCidr(cidrEmEdicao.cidr) === 0) {
					mensagens.push({mensagem: `${cidrEmEdicao.cidr} não é um CIDR válido.`})
				} 
			} else {
				this.adicionaMensagemSeIPInvalido(cidrEmEdicao.ipInicial, mensagens, "IP Inicial");
				this.adicionaMensagemSeIPInvalido(cidrEmEdicao.ipFinal, mensagens, "IP Final");
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Selecione as informações corretamente:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		adicionaMensagemSeIPInvalido(ip, mensagens, campoFormulario){
			if(!ip || ip.trim().length === 0 ){
				mensagens.push({mensagem: `${campoFormulario} obrigatório.`})
			} else if (isIP(ip) === false) {
				mensagens.push({mensagem: `${ip} não é um IP válido.`})
			}
		}

		getNomeTipoAmbiente(id){
			if(id){
				const tipoAmbiente = this.state.tiposAmbiente.filter((c) => c.id === id)[0];
				if(tipoAmbiente){
					return tipoAmbiente.nome;
				}
			}
			return null;
		}

		validaFormulario(){
			let mensagens = [];
			const { solicitacao } = this.state;
			const { operacao, orgao, cidrs, whitelistExclusao} = solicitacao;
			const user = this.props.user;

			if(! operacao) {
				mensagens.push({mensagem: "Operação é obrigatória."});
			} else {
				if(CD_INCLUSAO === operacao) {
					let cidrsValidos = true;
					if(!cidrs || cidrs.length === 0 ){
						mensagens.push({mensagem: "Informe pelo menos 1 CIDR"});
						cidrsValidos = false;
					} else if(cidrs.length > 10){
						mensagens.push({mensagem: "Informe no máximo 10 CDIR's"});
						cidrsValidos = false;
					}
					if(cidrsValidos === true) {
						this.validaSobreposicaoCIDRs(mensagens);
					}
				}

				if(CD_EXCLUSAO === operacao) {
					if(!whitelistExclusao || whitelistExclusao.length === 0 ){
						mensagens.push({mensagem: "Informe pelo menos 1 CIDR"})
					} else if(whitelistExclusao.length > 10){
						mensagens.push({mensagem: "Informe no máximo 10 CDIR's"});
					}
				}
			}

			if((user.isAdministrador === true || user.isMantenedor === true) && ! orgao){
				mensagens.push({mensagem: "Órgão é obrigatório."});
			}
		
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		validaSobreposicaoCIDRs(mensagens){
			const { solicitacao } = this.state;
			const {cidrs} = solicitacao;

			for (let i = 0; i < cidrs.length -1; i++) {
				for (let j=i+1; j < cidrs.length; j++) {
					const cidr1 = cidrs[i];
					const cidr2 = cidrs[j];

					const mesmoTipoAmbiente = ID_TIPO_AMBIENTE_AMBOS === cidr1.tipoAmbiente 
							|| ID_TIPO_AMBIENTE_AMBOS === cidr2.tipoAmbiente
							|| cidr1.tipoAmbiente === cidr2.tipoAmbiente;

					if(mesmoTipoAmbiente && overlapCidr (cidr1.cidr,  cidr2.cidr) === true){
						mensagens.push({mensagem: `Os CIDR's ${cidr1.cidr} (${this.getNomeTipoAmbiente(cidr1.tipoAmbiente)}) e ${cidr2.cidr} (${this.getNomeTipoAmbiente(cidr2.tipoAmbiente)}) estão no mesmo range`})
					}
				}
			}
		}

		handleInputChangeSolicitacao(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const solicitacao = this.state.solicitacao;
			solicitacao[nome] = valor;
			this.setState({ solicitacao });
		}

		handleInputChangeCidr(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const cidrEmEdicao = this.state.cidrEmEdicao;
			cidrEmEdicao[nome] = valor;
			this.setState({ cidrEmEdicao });
		}

		handleTipoCadastro(evento){
			const tipoCadastro = evento.target.value;
			const { cidrEmEdicao }  = this.state;
			cidrEmEdicao.tipoCadastro = tipoCadastro;
			cidrEmEdicao.cidr = '';
			cidrEmEdicao.ipInicial = '';
			cidrEmEdicao.ipFinal = '';
			this.setState({ cidrEmEdicao });
		}

		handleOperacaoChange(evento){
			const operacao = evento.target.value;
			const { solicitacao} = this.state;
			solicitacao.operacao = operacao;
			solicitacao.cidrs = [];
			solicitacao.whitelistExclusao = [];
			const user = this.props.user;

			this.setState({
				solicitacao,
				cidrEmEdicao: { tipoCadastro: CIDR },
			})
			if(operacao === CD_EXCLUSAO && (user.isAdministrador || user.isMantenedor)){
				this.carregarWhitelistOrgao(solicitacao.orgao);
			}
		}

		handleOrgaoChange(evento){
			const orgao = evento.target.value;
			const { solicitacao} = this.state;
			solicitacao.orgao = orgao;
			solicitacao.whitelistExclusao = [];
			const user = this.props.user;

			this.setState({
				solicitacao,
			})
			if(solicitacao.operacao === CD_EXCLUSAO && (user.isAdministrador || user.isMantenedor)){
				this.carregarWhitelistOrgao(orgao);
			}
		}

		handlePickyChange(values){
			const whitelistExclusao = values;
			const { solicitacao } = this.state;
			solicitacao.whitelistExclusao = whitelistExclusao;
			this.setState(
				{ 
					solicitacao,
				});
		}

		carregarWhitelistOrgao(orgao) {
			if( !orgao) {
				return;
			}

			this.setState({carregandoWhitelist : true});

			api.get(`/api/whitelist/orgao/${orgao}`)
			.then(response => { this.setState({
					whitelistOrgao: response.data,
				}) 
			})
			.catch(erro => {
				console.log("Não foi possível recuperar os CIDR's liberados para o órgão ", erro)
				this.props.exibeMensagemErro("Erro durante o carregamento dos IPs liberados para o órgão")
			})
			.finally(() => this.setState({carregandoWhitelist: false}));
		}

		editarCidr (index) {
			const cidrEmEdicao = {...this.state.solicitacao.cidrs[index]};
			this.setState({ cidrEmEdicao , indiceCidrEdicao: index});
		}

		confirmarExclusaoCidr(index) {
			this.setState(
				{
					exibeModalExcluir: true,
					indiceCidrExclusao: index,
				}
			);
		}

		excluirCidr () {
			const { solicitacao, indiceCidrExclusao } = this.state;
			solicitacao.cidrs.splice(indiceCidrExclusao, 1);
			this.setState({ solicitacao });
			this.fecharModais();
			this.limparInfoCidr ();
		}

		limparInfoCidr () {
			this.setState({
				cidrEmEdicao : {
					id: '',
					tipoAmbiente: '',
					tipoCadastro: CIDR,
					cidr: '',
					ipInicial: '',
					ipFinal: '',
				},
				indiceCidrEdicao: -1,
				indiceCidrExclusao: -1,
			})
		}

		async salvarCidr (e){
			this.props.limpaMensagem();
			const { solicitacao, indiceCidrEdicao } = this.state;
			let { cidrEmEdicao } = this.state;
			const { cidrs } = solicitacao

			try{
				this.validaCidr();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			if(cidrEmEdicao.tipoCadastro === RANGE_IP) {
				const rangeIp = await this.getCidrsPorRangeIp(cidrEmEdicao.ipInicial, cidrEmEdicao.ipFinal);

				if(rangeIp.subnetsCount > 1) {
					this.props.exibeMensagemErro("Informe um range de IP que esteja em uma mesma subrede.")
					return;
				}

				const cidrsRangeIp = rangeIp.cidrs.map(cidr => ({tipoCadastro: CIDR, tipoAmbiente: cidrEmEdicao.tipoAmbiente, cidr }))
				cidrs.push(...cidrsRangeIp);
			} else {
				if(indiceCidrEdicao < 0) {
					cidrs.push({...cidrEmEdicao});
				} else {
					cidrs[indiceCidrEdicao] = {...cidrEmEdicao};
				}
			}

			this.setState({solicitacao});
			this.limparInfoCidr ();
		}

		async getCidrsPorRangeIp (ipInicial, ipFinal) {
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			try {
				const response = await api.get(`/api/cidr/por-range-ip?ipInicial=${ipInicial}&ipFinal=${ipFinal}`);
				return response.data;
			} catch (erro) {
				const msg = getMessagemErro("Não foi possível recuperar os CIDRs", erro);
				console.log(JSON.stringify(msg));
				this.props.exibeMensagemErro(msg.mensagem, msg.erros);
			} finally {
				this.props.escondeAguardando();
				this.setState({botoesHabilitados : true});
			}
		}

		fecharModais () {
			this.setState(
				{
					exibeModalExcluir:false,
				}
			);
		}

		novo(e){
			const rotaParaNovo = "/solicitacao-cadastro-whitelist/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar (e){
			this.props.limpaMensagem();
			const { solicitacao } = this.state;

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const method = solicitacao.id ? 'put' : 'post';
			let url = '/api/solicitacao-cadastro-whitelist';
			if(solicitacao.id){
				url = url + '/' + solicitacao.id;
			}

			const options = {
				method,
				url,
				data: solicitacao
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					const mensagem = `Solicitação de liberação de IP's ${this.state.solicitacao.id ? 'alterada' : 'incluída'} com sucesso!`;

					const solicitacao = response.data;
					solicitacao.cidrs = solicitacao.cidrs.map((cidr) => {return {...cidr, tipoCadastro: CIDR}});
			
					this.setState({ 
						solicitacao,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.solicitacao.id ? 'alterar' : 'incluir'} a solicitação de liberação de IPs.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}
	
	}

) // redux
); // router
const ID_SITUACAO_SOLICITACAO_ADESAO_RASCUNHO = 1;
const SITUACAO_SOLICITACAO_ADESAO_RASCUNHO = {
	id: ID_SITUACAO_SOLICITACAO_ADESAO_RASCUNHO,
	nome: 'Rascunho'
}

const ID_SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA = 2;
const SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA = {
	id: ID_SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA,
	nome: 'Aguardando assinatura'
}

const ID_SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_CEDENTE = 3;
const SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_CEDENTE = {
	id: ID_SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_CEDENTE,
	nome: 'Em análise pelo cedente dos dados'
}

const ID_SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_GESTOR_PLATAFORMA = 4;
const SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_GESTOR_PLATAFORMA = {
	id: ID_SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_GESTOR_PLATAFORMA,
	nome: 'Em análise pelo gestor da plataforma'
}

const ID_SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE = 5;
const SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE = {
	id: ID_SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE,
	nome: 'Rejeitada pelo cedente dos dados'
}

const ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA = 6;
const SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA = {
	id: ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA,
	nome: 'Deferida sem volumetria'
}

const ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA = 7;
const SITUACAO_SOLICITACAO_ADESAO_DEFERIDA = {
	id: ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA,
	nome: 'Deferida'
}

export {
	ID_SITUACAO_SOLICITACAO_ADESAO_RASCUNHO, SITUACAO_SOLICITACAO_ADESAO_RASCUNHO,
	ID_SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA, SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA,
	ID_SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_CEDENTE, SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_CEDENTE,
	ID_SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_GESTOR_PLATAFORMA, SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_GESTOR_PLATAFORMA,
	ID_SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE, SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE,
	ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA, SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA,
	ID_SITUACAO_SOLICITACAO_ADESAO_DEFERIDA, SITUACAO_SOLICITACAO_ADESAO_DEFERIDA,
 }
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './APIAttachmentType.css';
import 'react-picky/dist/picky.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando }

export const APIAttachmentTypeEdit =

withRouter(

// redux
connect(
	(null),
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				tipoAnexoAplicacaoFornecedora: {
					id: undefined,
					nome: "",
				},
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Tipos de Anexos de API</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				api.get('/api/tipo-anexo-aplicacao-fornecedora/' + id)
				.then(response => { 
					this.setState(
						{
							tipoAnexoAplicacaoFornecedora: response.data,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar o tipo de anexo de API", erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}
		}

		renderForm() {
			const { tipoAnexoAplicacaoFornecedora } = this.state;
			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="nome">Nome:</label>
										<input type="text" name="nome" 
											value={tipoAnexoAplicacaoFornecedora.nome}  
											onChange={this.handleInputChange} 
											maxLength="100"/>
									</div>
								</div>
							</div>	
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{tipoAnexoAplicacaoFornecedora.id ? 'Alterar' : 'Incluir'}</button>

								{tipoAnexoAplicacaoFornecedora.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Novo Tipo de Anexo de API</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const { tipoAnexoAplicacaoFornecedora } = this.state;
			tipoAnexoAplicacaoFornecedora[nome] = valor;
			this.setState({ tipoAnexoAplicacaoFornecedora });
		}

		novo(e){
			const rotaParaNovo = "/api-attachment-type/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const { tipoAnexoAplicacaoFornecedora } = this.state;
			const method = tipoAnexoAplicacaoFornecedora.id ? 'put' : 'post';
			let url = "/api/tipo-anexo-aplicacao-fornecedora";
			if(tipoAnexoAplicacaoFornecedora.id){
				url = url + '/' + tipoAnexoAplicacaoFornecedora.id;
			}
			const options = {
				method,
				url,
				"data" : tipoAnexoAplicacaoFornecedora,
			}

			api(options)
				.then(response => {
					const tipoAnexoAplicacaoFornecedora =  response.data;
					const mensagem = `Tipo de anexo de API ${this.state.tipoAnexoAplicacaoFornecedora.id ? 'alterado' : 'incluído'} com sucesso!`;
					this.setState({ 
						tipoAnexoAplicacaoFornecedora,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.tipoAnexoAplicacaoFornecedora.id ? 'alterar' : 'incluir'} o tipo de anexo de API.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});   

		}

		validaFormulario(){
			let mensagens = [];
			const { nome } = this.state.tipoAnexoAplicacaoFornecedora;

			if(!nome || nome.trim().length === 0 ){
				mensagens.push({mensagem: "Nome é obrigatório."})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}
	}

) // redux
); // router
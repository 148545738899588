import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Styles.css';

export const Card =	class extends Component {
		render() {

			return (
				<div className='card' onClick={this.props.onClick}>
					<div className="card-icon">
						<FontAwesomeIcon icon={this.props.icone} />
					</div>
					<div className='card-footer'>
						<p>{this.props.titulo}</p>
					</div>
				</div>
			);
		}
	}

const ID_TIPO_AMBIENTE_HOMOLOGACAO = 'H';
const TIPO_AMBIENTE_HOMOLOGACAO = {
	id: ID_TIPO_AMBIENTE_HOMOLOGACAO,
	nome: 'Homologação'
}

const ID_TIPO_AMBIENTE_PRODUCAO = 'P';
const TIPO_AMBIENTE_PRODUCAO = {
	id: ID_TIPO_AMBIENTE_PRODUCAO,
	nome: 'Produção'
}

const ID_TIPO_AMBIENTE_AMBOS = 'A';
const TIPO_AMBIENTE_AMBOS = {
	id: ID_TIPO_AMBIENTE_AMBOS,
	nome: 'Ambos'
}

export {ID_TIPO_AMBIENTE_HOMOLOGACAO, ID_TIPO_AMBIENTE_PRODUCAO, ID_TIPO_AMBIENTE_AMBOS, TIPO_AMBIENTE_HOMOLOGACAO, TIPO_AMBIENTE_PRODUCAO, TIPO_AMBIENTE_AMBOS}
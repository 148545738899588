import React, { Component, Fragment } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './ManagerProfile.css';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const ManagerProfileEdit =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				perfilGestor: {
					id: '',
					nome: '',
					perfilOrgao: '',
					transacoes: [],
				},
				perfisOrgao: [],
				transacoesPerfilOrgao: [],
				botoesHabilitados : true,
				carregandoPerfisOrgao: false,
				carregandoTransacoes: false,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handlePerfilOrgaoChange = this.handlePerfilOrgaoChange.bind(this);
			this.carregaPerfisOrgaoTransacao = this.carregaPerfisOrgaoTransacao.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Perfil de Gestor</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				this.props.mostraAguardando();
				api.get(`/api/perfil-gestor/${id}`)
					.then(response => { 
						const perfilGestor = response.data;
						this.setState(
							{
								perfilGestor,
							})
						this.carregaPerfisOrgaoTransacao(perfilGestor.perfilOrgao) 
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar o perfil de gestor. ", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					})
					.finally(() => {
						this.props.escondeAguardando();
					});
			}

			this.setState({carregandoPerfisOrgao : true});
			api.get('/api/perfil-orgao')
				.then(response => { this.setState({perfisOrgao: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar os Perfis de Órgão", erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				})
				.finally(() => {
					this.setState({carregandoPerfisOrgao : false});
				});
		}

		renderForm() {
			const { perfilGestor } = this.state;

			return(
				<div className="container-fluid">
					<div className="br-form">
						
						<div className="row">

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="nome">Nome:</label>
										<input type="text" name="nome" 
											value={perfilGestor.nome}  
											onChange={this.handleInputChange} 
											maxLength="100"/>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Perfil de Órgão:</label>
										<select name="perfilOrgao" onChange={this.handlePerfilOrgaoChange} value={perfilGestor.perfilOrgao}>
											<option value="">Selecione</option>
												{
													this.state.perfisOrgao.map(perfilOrgao =>
														<option 
															key={perfilOrgao.id} 
															value={perfilOrgao.id}>
															{perfilOrgao.nome}
														</option>
												)}
										</select>
									</div>
									&nbsp;&nbsp;
									{this.state.carregandoPerfisOrgao === true && 
										<button className="br-button is-secondary is-circle is-loading" type="button"/>
									}
								</div>
							</div>
						</div>

						{(perfilGestor.perfilOrgao !== '') &&	
							<Fragment>
								<div className="row">
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label htmlFor="fornecedoras">Transacões:</label>						
											</div>
											<div>
												<Picky
													options={this.state.transacoesPerfilOrgao}
													value={perfilGestor.transacoes}
													valueKey="id"
													labelKey="nomeTransacao"
													multiple={true}
													includeSelectAll={true}
													includeFilter={true}
													onChange={values => this.handlePickyChange(values)}
													selectAllText="Selecionar todas"
													placeholder="Nenhuma transação selecionada"
													filterPlaceholder="Filtro..."
													keepOpen={true}
													/>
											</div>
										</div>
									</div>
								</div>
								{this.state.carregandoTransacoes === true && 
									<div className="row">
										<div className="col-md">
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										</div>
									</div>
								}
							</Fragment>
						}


						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.perfilGestor.id ? 'Alterar' : 'Incluir'}</button>

								{this.state.perfilGestor.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Novo Perfil Gestor</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
				
			);
		}

		validaFormulario(){
			let mensagens = [];

			const { perfilGestor } = this.state;
			const { perfilOrgao, nome, transacoes } = perfilGestor;

			if(!nome || nome.trim().length === 0 ){
				mensagens.push({mensagem: "Nome é obrigatório."})
			} 

			if(!perfilOrgao || perfilOrgao.toString().trim().length === 0 ){
				mensagens.push({mensagem: "Perfil de Órgão é obrigatório."})
			} else {
				if(! transacoes || transacoes.length === 0) {
					mensagens.push({mensagem: "Selecione pelo menos uma transação."})
				}
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const perfilGestor = this.state.perfilGestor;
			perfilGestor[nome] = valor;
			this.setState({ perfilGestor });
		}

		handlePerfilOrgaoChange(evento){
			const perfilOrgao =  evento.target.value;
			const perfilGestor = this.state.perfilGestor;
			perfilGestor['perfilOrgao'] = perfilOrgao;
			perfilGestor['transacoes'] = [];

			this.setState({
				perfilGestor: perfilGestor,
			});
			this.carregaPerfisOrgaoTransacao(perfilOrgao);
		}

		carregaPerfisOrgaoTransacao(idPerfilOrgao){
			if(idPerfilOrgao && idPerfilOrgao !== ""){
				this.setState({carregandoTransacoes : true});
				api.get(`/api/perfil-orgao-transacao/perfil-orgao/${idPerfilOrgao}`)
				.then(response => { 
					this.setState(
						{
							transacoesPerfilOrgao: response.data,
							carregandoTransacoes : false
						}
					);
				})
				.catch(erro => {
					this.setState({carregandoTransacoes : false});
					console.log("Não foi possível recuperar as transações ao perfil de órgão ", erro);
					this.props.exibeMensagemErro("Erro durante o carregamento das transações do perfil de órgão.")
				});
			}
		}

		handlePickyChange(values){
			const transacoesSelecionadas = values;
			const perfilGestor = this.state.perfilGestor;
			perfilGestor.transacoes = transacoesSelecionadas;
			this.setState(
				{ 
					perfilGestor: perfilGestor,
				});
		}

		novo(e){
			const rotaParaNovo = "/manager-profile/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar (e){
			this.props.limpaMensagem();
			const { perfilGestor } = this.state;

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const method = perfilGestor.id ? 'put' : 'post';
			let url = '/api/perfil-gestor';
			if(perfilGestor.id){
				url = url + '/' + perfilGestor.id;
			}

			const options = {
				method,
				url,
				data: perfilGestor
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					const mensagem = `Perfil Gestor ${this.state.perfilGestor.id ? 'alterado' : 'incluído'} com sucesso!`;
					const perfilGestor = response.data;
			
					this.setState({ 
						perfilGestor,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.perfilGestor.id ? 'alterar' : 'incluir'} o perfil gestor.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}
		
	}

) // redux
); // router
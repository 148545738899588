import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactLoading from "react-loading";

const mapStateToProps = (state) => {
	return {
		aguardando: state.mensagemStore.aguardando
	}
}

export const Loading = 

// redux
connect(
	(mapStateToProps)
)(
	class extends Component {
		render() {
			return (
				<div className="noprint">
				{
					(this.props.aguardando === true) &&
					<ReactLoading type="spin" color="#3498db"/>
				}
				</div>
			);
		}

	}

); // redux
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { FileUpload } from "../FileUpload/FileUpload";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import { baixarArquivo } from "../../utils/downloadUtil";
import './API.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
const fileTypes = ["doc", "docx", "odt", "pdf"];

export const APIAttachments =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				fornecedora: {},
				anexo: {
					id: '',
					fornecedora: '',
					tipo: '',
					arquivo: null,
					nomeArquivo: null,
					obrigatorioAdesao: false,
					obrigatorioAlteracaoVolumetria: false,
				},
				tipos: [],
				anexos: [],
				carregandoTipos: false,
				exibeModalExcluir: false,
				botoesHabilitados: true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.carregarAnexosFornecedora = this.carregarAnexosFornecedora.bind(this);
			this.salvar = this.salvar.bind(this);
			this.editarAnexo = this.editarAnexo.bind(this);
			this.excluirAnexo = this.excluirAnexo.bind(this);
			this.limpar = this.limpar.bind(this);
			this.confirmarExclusao = this.confirmarExclusao.bind(this);
			this.fecharModais = this.fecharModais.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de API - Anexos</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;
			if(id){
				api.get(`/api/aplicacao-fornecedora/${id}`)
					.then(response => { 
						this.setState(
							{
								fornecedora: response.data,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar a API " + erro);
						this.props.exibeMensagemErro("Erro durante o carregamento da página");
					});
			}

			this.carregarTiposAnexos();
			this.carregarAnexosFornecedora();
		}

		carregarTiposAnexos(){
			this.setState({carregarTiposAnexos: true});
			api.get('/api/tipo-anexo-aplicacao-fornecedora')
				.then(response => { 
					this.setState({
							tipos: response.data,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de anexo", erro);
					this.props.exibeMensagemErro("Erro durante o carregamento da página");
				})
				.finally(() => {
					this.setState({carregarTiposAnexos: false});
				})
		}

		carregarAnexosFornecedora(){
			const id = this.props.match.params.id;
			if(id){
				api.get(`/api/aplicacao-fornecedora/${id}/anexo`)
					.then(response => { 
						this.setState(
							{
								anexos: response.data,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar os anexos da API", erro);
						this.props.exibeMensagemErro("Erro durante o carregamento da página");
					});
			}
		}

		renderForm() {
			const { tipos, anexos, anexo, fornecedora, carregandoTipos } = this.state;
			return(
				<div className="container-fluid">
					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalExcluir}
						handleOk={() => this.excluirAnexo()}
						handleCancel={() => this.fecharModais()}
						message="Deseja realmente remover o anexo?"/>

					<div className="br-form">

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{fornecedora.nome}
									</div>
								</div>
							</div>
						</div>	

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Tipo de Anexo:</label>
											{tipos.length > 0 &&
												<select name="tipo" onChange={this.handleInputChange} value={anexo.tipo}>
													<option value="0">Selecione uma opção</option>
													{
														this.state.tipos.map(tipo =>
															<option 
																key={tipo.id} 
																value={tipo.id}>
																{tipo.nome}
															</option>
													)}
												</select>
											}
											{carregandoTipos === true && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
											{carregandoTipos === false && tipos.length === 0 && 'Não há tipos de anexos cadastrados'}
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="check">
									<div className="input">
										<input type="checkbox" name="obrigatorioAdesao" checked={anexo.obrigatorioAdesao} onChange={this.handleInputChange} />
										<label>Obrigatório para adesões</label>
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="check">
									<div className="input">
										<input type="checkbox" name="obrigatorioAlteracaoVolumetria" checked={anexo.obrigatorioAlteracaoVolumetria} onChange={this.handleInputChange} />
										<label>Obrigatório para alteração de volumetria</label>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input" >
										<label>Arquivo:</label>
										<div className="download-container">
											{anexo.nomeArquivo && 
												<div className="col-md">
													{anexo.nomeArquivo}
													<button type="button" className="button-download" disabled={this.state.botoesHabilitados === false} title="Download do arquivo" onClick={() => this.downloadAnexoEmEdicao(anexo)}>
														<span className="sr-only">Download</span>
														<i className="fas fa-download"></i>
													</button>
												</div>
											}
											<FileUpload onFileChange={this.handleFileChange} fileTypes={fileTypes} multiple={false}/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={this.state.botoesHabilitados === false}>{anexo.id ? 'Alterar' :'Incluir'}</button>

								<button type="button" className="button is-secondary" onClick={this.limpar} disabled={this.state.botoesHabilitados === false}>Limpar</button>
								
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={this.state.botoesHabilitados === false}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
						
						<fieldset>
							<legend>Anexos cadastrados</legend>
							{(anexos && anexos.length > 0) &&	
								<div className="br-table">						
									<div className="table">
										<table>
											<thead>
												<tr>
													<th scope="col">Tipo de Anexo</th>
													<th scope="col">Nome do Arquivo</th>
													<th scope="col" className="text-center" style={{width: "15%"}}>Obrigatório para adesões</th>
													<th scope="col" className="text-center" style={{width: "15%"}}>Obrigatório para alteração de volumetria</th>
													<th className="text-center" scope="col" style={{width: "5%"}}>Ações</th>
												</tr>
											</thead>
											
											<tbody>
												{ anexos.map((anexo, index) =>
														<tr key={index} >
															<td>{anexo.nomeTipo}</td>
															<td>{anexo.nomeArquivo}</td>
															<td className="text-center">{anexo.obrigatorioAdesao ? 'Sim': 'Não'}</td>
															<td className="text-center">{anexo.obrigatorioAlteracaoVolumetria ? 'Sim': 'Não'}</td>
															<td className="text-center">
																<div className="actions">
																	<button type="button" onClick={() => this.confirmarExclusao(anexo)} disabled={this.state.botoesHabilitados === false} title="Excluir Anexo">
																		<span className="sr-only">Excluir</span>
																		<i className="fas fa-trash"></i>
																	</button>

																	<button type="button" onClick={() => this.editarAnexo(anexo)} disabled={this.state.botoesHabilitados === false} title="Editar Anexo">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>

																	<button type="button" disabled={this.state.botoesHabilitados === false} title="Download do arquivo" onClick={() => this.downloadAnexo(anexo)}>
																		<span className="sr-only">Download</span>
																		<i className="fas fa-download"></i>
																	</button>
																</div>
															</td>
														</tr>
													)}
											</tbody>
										</table>
									</div>
								</div>
							}

							{(! anexos || anexos.length === 0) &&
								<div>
									Nenhum anexo cadastrado para a API.
								</div>
							}
						</fieldset>
					</div>

				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const anexo = this.state.anexo;
			anexo[nome] = valor;
			this.setState({ anexo });
		}

		handleFileChange = (arquivos) => {
			const arquivo = arquivos[0];
			const { anexo } = this.state;
			anexo.arquivo = arquivo;
			anexo.nomeArquivo = arquivo.name;
			this.setState({anexo});
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const { anexo, fornecedora } = this.state;
			let method = 'post';
			let url = '/api/aplicacao-fornecedora/anexo';
			
			if(anexo.id){
				method = 'put';
				url = url + `/${anexo.id}`;
			}

			const data = new FormData();
			data.append('fornecedora', fornecedora.id);
			data.append('tipo', anexo.tipo);
			data.append('arquivo', anexo.arquivo);
			data.append("obrigatorioAdesao", anexo.obrigatorioAdesao);
			data.append("obrigatorioAlteracaoVolumetria", anexo.obrigatorioAlteracaoVolumetria);

			const options = {
				method,
				url,
				data,
			}
			this.props.mostraAguardando();

			api(options)
				.then(response => {
					const mensagem = `Anexo ${anexo.id ? 'alterado' : 'incluído'} com sucesso!`;
					this.carregarAnexosFornecedora();
					this.setState({
						anexo: {id: '', fornecedora: '', tipo: '', arquivo: null, obrigatorioAdesao: false, obrigatorioAlteracaoVolumetria: false,}
					})
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${anexo.id ? 'alterar' : 'incluir'} o anexo.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});   

		}

		editarAnexo(anexo){
			this.props.limpaMensagem();
			this.setState({anexo: {...anexo}});
			window.scrollTo(0, 0);
		}

		confirmarExclusao(anexo) {
			this.setState(
				{
					exibeModalExcluir: true,
					anexoAlvo: anexo,
				}
			);
		}

		fecharModais () {
			this.setState(
				{
					exibeModalExcluir:false,
				}
			);
		}

		excluirAnexo(){
			this.props.limpaMensagem();
			const anexo = this.state.anexoAlvo;
			let url = `/api/aplicacao-fornecedora/anexo/${anexo.id}`;
			const options = {
				method: 'delete',
				url,
			}
			this.props.mostraAguardando(); 
			this.setState({botoesHabilitados:false});

			api(options)
				.then(response => {
					const mensagem = 'Anexo excluído com sucesso!';
					this.carregarAnexosFornecedora();
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível excluir o anexo.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					
				})
				.then (() =>{
					this.props.escondeAguardando();
					this.setState({botoesHabilitados:true});
				});   
			
			this.fecharModais();
		}

		downloadAnexoEmEdicao(anexo){
			const arquivo = anexo.arquivo;
			if(arquivo) {
				arquivo.arrayBuffer()
					.then(conteudo => {
						baixarArquivo(new Blob([conteudo]), anexo.nomeArquivo);	
					})
			} else {
				this.downloadAnexo(anexo);
			}
		}

		downloadAnexo(anexo){
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			const options = {
				method: 'get',
				url: `/api/aplicacao-fornecedora/anexo/${anexo.id}/download`,
				responseType: 'arraybuffer'
			}

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), anexo.nomeArquivo);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o arquivo anexo.';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limpar(){
			this.props.limpaMensagem();
			this.setState({
				anexo: {id: '', fornecedora: '', tipo: '', arquivo: null, obrigatorioAdesao: false, obrigatorioAlteracaoVolumetria: false},
			})
		}

		validaFormulario(){
			let mensagens = [];
			const anexo = this.state.anexo;

			if(anexo.tipo === '' ){
				mensagens.push({mensagem: "Tipo de anexo obrigatório."})
			}

			if(anexo.arquivo === null ){
				mensagens.push({mensagem: "Selecione o arquivo."})
			}

			if(anexo.obrigatorioAdesao === false && anexo.obrigatorioAlteracaoVolumetria === false) {
				mensagens.push({mensagem: "Pelo menos uma das opções deve ser marcada: 'Obrigatório para adesões' ou 'Obrigatório para alteração de volumetria'."})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
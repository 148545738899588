import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { ButtonInfo } from "../Button/Info";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './Organization.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
const LIMITE_EXIBICAO_FINALIDADE = 500;

export const OrganizationAccess =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				orgao: {},
				adesao: {
					idFornecedora: '0',
					idPlano: '0',
					idPeriodicidade: '0',
					finalidade: '',
				},
				adesoes: [],
				fornecedoras: [],
				planos: [],
				periodicidades: [],
				carregandoPlanos: false,
				exibeModalExcluir: false,
				exibeModalAlterar: false,
				botoesHabilitados: true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.getAdesaoCadastradaOrgao = this.getAdesaoCadastradaOrgao.bind(this);
			this.carregarAdesoesOrgao = this.carregarAdesoesOrgao.bind(this);
			this.carregaPlanosFornecedora = this.carregaPlanosFornecedora.bind(this);
			this.salvar = this.salvar.bind(this);
			this.editarAdesao = this.editarAdesao.bind(this);
			this.alterarAdesao = this.alterarAdesao.bind(this);
			this.excluirAdesao = this.excluirAdesao.bind(this);
			this.limpar = this.limpar.bind(this);

			this.confirmarExclusao = this.confirmarExclusao.bind(this);
			this.confirmarAlteracao = this.confirmarAlteracao.bind(this);
			this.fecharModais = this.fecharModais.bind(this);
			this.toggleFinalidade = this.toggleFinalidade.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Órgão - Adesões às API'S</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;
			if(id){
				api.get('/api/orgao/' + id)
					.then(response => { 
						const orgao = response.data;
						this.setState(
							{
								orgao,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar o órgão " + erro)
						this.props.exibeMensagemErro("Erro durante o carregamento da página")
					});
			}

			this.carregarAdesoesOrgao();

			api.get('/api/aplicacao-fornecedora/gestor')
				.then(response => { 
					const fornecedoras = response.data;
					this.setState(
						{
							fornecedoras,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as aplicações fornecedoras. " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});

				api.get('/api/orgao/adesao/periodicidades')
				.then(response => { 
					const periodicidades = response.data;
					this.setState(
						{
							periodicidades,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as periodicidades. " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		carregarAdesoesOrgao(){
			const id = this.props.match.params.id;
			if(id){
				api.get(`/api/orgao/${id}/adesoes-atribuicao`)
					.then(response => { 
						let adesoes = response.data;
						adesoes = adesoes.map((adesao) => {
							const finalidade = adesao.finalidade;
							const exibeExpandirComrimir = finalidade.length > LIMITE_EXIBICAO_FINALIDADE;
							adesao['exibeExpandirComrimir'] = exibeExpandirComrimir;
							adesao['mostraFinalidadeResumida'] = exibeExpandirComrimir;
							return adesao;
						})

						this.setState(
							{
								adesoes,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar as adesões do órgão " + erro)
						this.props.exibeMensagemErro("Erro durante o carregamento da página")
					});
			}
		}

		renderForm() {
			const { adesoes } = this.state;
			return(
				<div className="container-fluid">
					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalExcluir}
						handleOk={() => this.excluirAdesao()}
						handleCancel={() => this.fecharModais()}
						message="Deseja realmente remover a adesão? As chaves geradas para as aplicações desse órgão acessarem essa API serão desabilitadas."/>

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalAlterar}
						handleOk={() => this.alterarAdesao()}
						handleCancel={() => this.fecharModais()}
						message="Deseja realmente alterar a adesão? As chaves geradas para as aplicações desse órgão acessarem essa API terão seus planos alterados."/>

					<div className="br-form">

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Órgão:</label>
										{this.state.orgao.nome}
									</div>
								</div>
							</div>
						</div>	

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										<select name="idFornecedora" onChange={this.handleFornecedoraChange} value={this.state.adesao.idFornecedora}>
											<option value="0">Selecione uma opção</option>
											{
												this.state.fornecedoras.map(fornecedora =>
													<option 
														key={fornecedora.id} 
														value={fornecedora.id}>
														{fornecedora.nome}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									{this.state.adesao.idFornecedora !== '0' &&
									<div className="input">
										<label>Plano:</label>
										{this.state.planos.length > 0 &&
										<select name="idPlano" onChange={this.handleInputChange} value={this.state.adesao.idPlano}>
											<option value="0">Selecione uma opção</option>
												{
													this.state.planos.map(plano =>
														<option 
															key={plano.idPlano} 
															value={plano.idPlano} >
															{plano.descricaoPlano}
														</option>
												)}
										</select>
										}
										{this.state.carregandoPlanos === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}

										{this.state.carregandoPlanos === false && this.state.planos.length === 0 && 'API não possui planos cadastrados'}
									</div>
									}
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									{this.state.adesao.idFornecedora !== '0' &&
									<div className="input">
										<label>Periodicidade:</label>
										{this.state.periodicidades.length > 0 &&
										<select name="idPeriodicidade" onChange={this.handleInputChange} value={this.state.adesao.idPeriodicidade}>
											<option value="0">Selecione uma opção</option>
												{
													this.state.periodicidades.map(periodicidade =>
														<option 
															key={periodicidade.codigo} 
															value={periodicidade.codigo} >
															{periodicidade.nome}
														</option>
												)}
										</select>
										}

									</div>
									}
								</div>
							</div>
						</div>
						{this.state.adesao.idFornecedora !== '0' &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<div className="row">
												<div className="col-md">
													<label>Finalidade:</label>
													&nbsp;
													<ButtonInfo title="Conforme o artigo 6º da Lei nº 13.709 (Lei Geral de Proteção de Dados – LGPD), o órgão público consumidor de dados pessoais deve informar a finalidade específica à qual se destina o uso daquela informação.  A finalidade aqui declarada poderá ser informada ao cidadão titular do dado consultado, portanto, deve ser descrita com clareza e objetividade"/>
												</div>
											</div>											
											<textarea name="finalidade" value={this.state.adesao.finalidade} onChange={this.handleInputChange} maxLength={3000} style={{height:"90px"}} />
										</div>
									</div>
								</div>
							</div>
						}
					

						<div className="actions-button">
							<div className="actions justify-content-start">
								{! this.state.adesao.id &&
									<button type="button" className="button is-primary" onClick={this.salvar} disabled={this.state.botoesHabilitados === false}>Incluir</button>
								}

								{this.state.adesao.id &&
									<button type="button" className="button is-primary" onClick={this.confirmarAlteracao} disabled={this.state.botoesHabilitados === false}>Alterar</button>
								}

								<button type="button" className="button is-secondary" onClick={this.limpar} disabled={this.state.botoesHabilitados === false}>Limpar</button>
								
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={this.state.botoesHabilitados === false}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
						
						<fieldset>
							<legend>Adesões cadastradas</legend>
							{(adesoes && adesoes.length > 0) &&	
								<div className="br-table">						
									<div className="table">
										<table>
											<thead>
												<tr>
												<th scope="col">API</th>
												<th scope="col">Plano</th>
												<th scope="col">Periodicidade</th>
												<th scope="col">Finalidade</th>
												<th className="text-center" scope="col">Ações</th>
												</tr>
											</thead>
											
											<tbody>
												{ adesoes.map((adesao, index) =>
														<tr key={index} >
															<td>{adesao.nomeFornecedora}</td>
															<td>{adesao.descricaoPlano}</td>
															<td>{adesao.descricaoPeriodicidade}</td>
															<td style={{width: "45%"}}>
																{this.getFinalidade(adesao)}
															</td>
															<td className="text-center">
																<div className="actions">
																	<button type="button" onClick={() => this.confirmarExclusao(adesao)} disabled={this.state.botoesHabilitados === false} title="Excluir Adesão">
																		<span className="sr-only">Excluir</span>
																		<i className="fas fa-trash"></i>
																	</button>

																	<button type="button" onClick={() => this.editarAdesao(adesao)} disabled={this.state.botoesHabilitados === false} title="Editar Adesão">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>

																	{adesao.exibeExpandirComrimir === true &&
																		<button type="button" onClick={() => this.toggleFinalidade(adesao)} disabled={this.state.botoesHabilitados === false} title={adesao.mostraFinalidadeResumida ? "Expandir Finalidade" : "Comprimir Finalidade"}>
																			<span className="sr-only">Expandir/Comprimir Finalidade</span>
																			<i className={adesao.mostraFinalidadeResumida ? "fas fa-plus-square" : "fas fa-minus-square"}></i>
																		</button>
																	}

																</div>
															</td>
														</tr>
													)}
											</tbody>
										</table>
									</div>
								</div>
							}

							{(! adesoes || adesoes.length === 0) &&
								<div>
									Nenhuma adesão cadastrada para o órgão.
								</div>
							}
						</fieldset>
					</div>

				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const adesao = this.state.adesao;
			adesao[nome] = valor;
			this.setState({ adesao });
		}

		handleFornecedoraChange(evento){
			this.props.limpaMensagem();
			const idFornecedora =  evento.target.value;
			const adesaoCadastradaOrgao = this.getAdesaoCadastradaOrgao(idFornecedora);

			if (adesaoCadastradaOrgao){
				this.editarAdesao(adesaoCadastradaOrgao);
			}else{
				const adesao = {idFornecedora: '0', idPlano: '0', idPeriodicidade: '0', finalidade: ''}
				adesao['idFornecedora'] = idFornecedora;

				this.setState({
					planos : [],
					adesao,
				});

				this.carregaPlanosFornecedora(idFornecedora);
			}
		}

		getAdesaoCadastradaOrgao(idFornecedora){
			for(var adesao of this.state.adesoes){
				if(adesao['idFornecedora'] === parseInt(idFornecedora)){
					return adesao;
				}
			}
			return;
		}

		carregaPlanosFornecedora(idFornecedora){
			if(idFornecedora && idFornecedora !== "0"){
				this.setState({carregandoPlanos : true});
				api.get(`/api/plano/aplicacao-fornecedora/${idFornecedora}`)
				.then(response => { 
					this.setState(
						{
							planos: response.data,
							carregandoPlanos : false,
						}
					);
				})
				.catch(erro => {
					this.setState({carregandoPlanos : false});
					console.log("Não foi possível recuperar os planos associados à aplicação fornecedora " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento dos planos das api's.")
				});
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const adesao = this.state.adesao;
			const method = adesao.id ? 'put' : 'post';
			let url = `/api/orgao/${this.state.orgao.id}/adesao`;

			const options = {
				method,
				url,
				"data" : adesao,
			}
			this.props.mostraAguardando();

			api(options)
				.then(response => {
					const mensagem = `Adesão ${this.state.adesao.id ? 'alterada' : 'incluída'} com sucesso!`;
					this.carregarAdesoesOrgao();
					this.setState({
						adesao: {idFornecedora: '0', idPlano: '0', idPeriodicidade: '0', finalidade: ''}
					})
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.adesao.id ? 'alterar' : 'incluir'} a adesão.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});   

		}

		confirmarAlteracao(e) {
			this.setState(
				{
					exibeModalAlterar: true,
					e,
				}
			);
		}

		alterarAdesao(){
			this.salvar(this.state.e);
			this.fecharModais();
		}

		editarAdesao(adesao){
			this.props.limpaMensagem();
			this.carregaPlanosFornecedora(adesao.idFornecedora);
			this.setState({adesao: {...adesao}});
			window.scrollTo(0, 0);
		}

		confirmarExclusao(adesao) {
			this.setState(
				{
					exibeModalExcluir: true,
					adesaoAlvo: adesao,
				}
			);
		}

		getFinalidade(adesao) {
			return adesao.mostraFinalidadeResumida ? adesao.finalidade.substring(0, LIMITE_EXIBICAO_FINALIDADE) + '...' : adesao.finalidade;
		}

		toggleFinalidade(adesao) {
			const adesoes = this.state.adesoes;
			this.getAdesaoCadastradaOrgao(adesao.idFornecedora)['mostraFinalidadeResumida'] = ! adesao.mostraFinalidadeResumida;
			this.setState({adesoes});
		}

		fecharModais () {
			this.setState(
				{
					exibeModalExcluir:false,
					exibeModalAlterar:false
				}
			);
		}

		excluirAdesao(){
			this.props.limpaMensagem();
			const adesao = this.state.adesaoAlvo;
			let url = `/api/orgao/${this.state.orgao.id}/adesao`;
			const options = {
				method: 'delete',
				url,
				"data" : adesao,
			}
			this.props.mostraAguardando(); 
			this.setState({botoesHabilitados:false});

			api(options)
				.then(response => {
					const mensagem = `Adesão excluída com sucesso!`;
					this.carregarAdesoesOrgao();
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível excluir a adesão.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					
				})
				.then (() =>{
					this.props.escondeAguardando();
					this.setState({botoesHabilitados:true});
				});   
			
			this.fecharModais();
		}

		limpar(){
			this.props.limpaMensagem();
			this.setState({
				adesao: {idFornecedora: '0', idPlano: '0', idPeriodicidade: '0', finalidade: ''},
				planos: [],
			})
		}

		validaFormulario(){
			let mensagens = [];
			const adesao = this.state.adesao;
			if(adesao.idFornecedora === "0" ){
				mensagens.push({mensagem: "API é obrigatório."})
			}

			if(adesao.idPlano === "0" ){
				mensagens.push({mensagem: "Plano é obrigatório."})
			}
			if(adesao.idPeriodicidade === "0" ){
				mensagens.push({mensagem: "Periodicidade é obrigatória."})
			}

			if(!adesao.finalidade || adesao.finalidade.trim().length === 0 ){
				mensagens.push({mensagem: "Finalidade é obrigatória."})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
import React, { Component } from "react";

export const Pagination =

class extends Component {

	render() {
		const totalPaginas = this.props.totalPaginas;
		let paginaAtual = this.props.paginaAtual;

		const PAGINAS_VISIVEIS = 10;
		const centro = PAGINAS_VISIVEIS/2;
		const tamanho = Math.min(totalPaginas, PAGINAS_VISIVEIS);
		if(paginaAtual > totalPaginas) {
			paginaAtual = 0;
		}
		let ultima = Math.min(totalPaginas, paginaAtual + centro);
		ultima = Math.max(tamanho, ultima);
		const primeira = Math.max(0, ultima - tamanho);

		const paginas = [];
		for (let pagina=primeira; pagina<ultima; pagina++){
			paginas.push(pagina)
		}	

		const paginaAnterior = Math.max(0, paginaAtual - 1);
		const paginaPosterior = Math.min(totalPaginas - 1, paginaAtual + 1);

		return <nav>
			<ul className="br-pagination">
				<li className={`is-first ${paginaAtual === 0 ? 'is-disabled' : ''}`} onClick={() => this.props.onChangePage(paginaAnterior)}>
					<a href="/#" onClick={ev => {ev.preventDefault();}}>
						<i className="fas fa-angle-double-left"></i>
						<span>Anterior</span>
					</a>
				</li>
				{ paginas.map((pagina, index) => 
					 <li key={index} className={pagina === paginaAtual ? 'is-active' : ''} onClick={() => this.props.onChangePage(pagina)}>
						<a href="/#" onClick={ev => {ev.preventDefault();}}>
							 <span>{pagina + 1}</span>
						</a>
					</li>								
				)}
				<li className={`is-last ${paginaAtual === (totalPaginas - 1) ? 'is-disabled' : ''}`} onClick={() => this.props.onChangePage(paginaPosterior)}>
				<a href="/#" onClick={ev => {ev.preventDefault();}}>
						<span>Próxima</span>
						<i className="fas fa-angle-double-right"></i>
					</a>
				</li>
			</ul>
		</nav>
	}

}
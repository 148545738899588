import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import './Status.css';

export const SubscriberStatus =

// router
withRouter(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				conectado: false,
			};
			this.intervalID = 0;
			this.atualizaSituacaoAssinador = this.atualizaSituacaoAssinador.bind(this);
		}

		render() {
			const { conectado } = this.state;

			if (conectado !== true) {
				return(
					<div>
						<span className="status-message">Assinador digital SERPRO inativo</span>
						<span className="fa fa-times-circle" id="off"></span>
						
						<div className="alert">
							<span>Verificar:</span>							
							<ul>
								<li>Se o assinador SERPRO está instalado. Caso não esteja, <a href="http://www.serpro.gov.br/links-fixos-superiores/assinador-digital/assinador-serpro">clique aqui para obtê-lo</a></li>
								<li>Se o assinador SERPRO está sendo executado.</li>
								<li>Se o assinador SERPRO não estiver habilitado. Se não estiver, <a href="http://assinador-desktop.serpro.gov.br:65066">clique aqui para habilitá-lo</a></li>
							</ul>
						</div>
					</div>
				);
			}

			return(
				<div className="alert">
					<span className="status-message">Assinador digital SERPRO ativo</span>
					<span className="fa fa-check-circle" id="on"></span>
				</div>
			);
		}

		componentDidMount(){
			this.intervalID = setInterval(this.atualizaSituacaoAssinador, 1000);
		}
		
		componentWillUnmount() {
			clearInterval(this.intervalID);
		}

		atualizaSituacaoAssinador(){
			if (window.SerproSignerClient && window.SerproSignerClient.isConnected() === true) {
				this.setState({conectado:true});
			} else {
				this.setState({conectado: false});
			}
		}
		
	}

); // router
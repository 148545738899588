export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_TOKEN = 'GET_TOKEN';
export const ASSINA_TERMO_CONSENTIMENTO = 'ASSINA_TERMO_CONSENTIMENTO';

export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const INFO = 'INFO';
export const WARNING = 'WARNING';
export const CLEAN_MESSAGE = 'CLEAN_MESSAGE';
export const LOADING_ON = 'LOADING_ON';
export const LOADING_OFF = 'LOADING_OFF';

export const SET_TERMO_CONSENTIMENTO_MAIS_RECENTE = 'SET_TERMO_CONSENTIMENTO_MAIS_RECENTE';

export const login = (access_token, photo) => ({type: LOGIN, access_token, photo });

export const logout = () => ({ type: LOGOUT});

export const getToken = () => ({ type: GET_TOKEN});

export const assinaTermoConsentimento = () => ({ type: ASSINA_TERMO_CONSENTIMENTO});

export const exibeMensagemSucesso = (texto,erros) => ({type: SUCCESS, texto, erros });

export const exibeMensagemErro = (texto, erros) => ({type: ERROR, texto, erros });

export const exibeMensagemInformacao = (texto, erros) => ({type: INFO, texto, erros });

export const exibeMensagemAviso = (texto, erros) => ({type: WARNING, texto, erros });

export const limpaMensagem = () => ({type: CLEAN_MESSAGE});

export const mostraAguardando = () => ({type: LOADING_ON});

export const escondeAguardando = () => ({type: LOADING_OFF});

export const setTermoConsentimentoMaisRecente = (termoConsentimentoMaisRecente) => ({type: SET_TERMO_CONSENTIMENTO_MAIS_RECENTE, termoConsentimentoMaisRecente});

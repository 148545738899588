import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import {getMessagemErro} from "../../utils/erro";
import { validaCamposNumericos } from "../../utils/formValidationUtil";
import {formataData} from '../../utils/formatadorData';
import api from "../../services/api";
import './Contract.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const APIContractDetails =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				contrato: {},
				contratoApi: {
					idFornecedora: '0',
					valorUnitarioConsultaAPI: '', 
					nivelServicoAcordado: ''
				},
				contratoApis: [],
				fornecedoras: [],
				exibeModalExcluir: false,
				botoesHabilitados: true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleInputNumberChange = this.handleInputNumberChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.carregarAPIsContrato = this.carregarAPIsContrato.bind(this);
			this.salvar = this.salvar.bind(this);
			this.editarContratoAPI = this.editarContratoAPI.bind(this);
			this.alterarContratoAPI = this.alterarContratoAPI.bind(this);
			this.excluirContratoAPI = this.excluirContratoAPI.bind(this);
			this.limpar = this.limpar.bind(this);

			this.confirmarExclusao = this.confirmarExclusao.bind(this);
			this.fecharModais = this.fecharModais.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Contrato - Informações de faturamento das API'S</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;
			if(id){
				api.get('/api/contrato/' + id)
					.then(response => { 
						const contrato = response.data;
						this.setState(
							{
								contrato,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar o contrato " + erro)
						this.props.exibeMensagemErro("Erro durante o carregamento da página")
					});
			}

			this.carregarAPIsContrato();

			api.get('/api/aplicacao-fornecedora/faturavel')
				.then(response => { 
					const fornecedoras = response.data;
					this.setState(
						{
							fornecedoras,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as aplicações fornecedoras. " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
				
		}

		carregarAPIsContrato(){
			const id = this.props.match.params.id;
			if(id){
				api.get(`/api/contrato/${id}/contrato-apis`)
					.then(response => { 
						this.setState(
							{
								contratoApis: response.data,
							}) 
					})
					.catch(erro => {
						console.log("Não foi possível recuperar as APIs do contrato " + erro)
						this.props.exibeMensagemErro("Erro durante o carregamento da página")
					});
			}
		}

		renderForm() {
			const numberFormater2 = new Intl.NumberFormat('pt-BR', {useGrouping: true, minimumFractionDigits:2, maximumFractionDigits:2 });
			const numberFormater7 = new Intl.NumberFormat('pt-BR', {useGrouping: true, minimumFractionDigits:7, maximumFractionDigits:7 });
			const { contrato, contratoApi, contratoApis } = this.state;

			return(
				<div className="container-fluid">
					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalExcluir}
						handleOk={() => this.excluirContratoAPI()}
						handleCancel={() => this.fecharModais()}
						message="Deseja realmente remover as informações de faturamento da API?"/>

					<div className="br-form">
						<fieldset>
							<legend>Contrato</legend>
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Nome:</label>
											{contrato.nomeContrato}
										</div>
									</div>
								</div>
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Item Faturável:</label>
											{contrato.nomeItemFaturavel}
										</div>
									</div>
								</div>
							</div>	
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Início Vigência:</label>
											{formataData(contrato.inicioVigencia)}
										</div>
									</div>
								</div>
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Fim Vigência:</label>
											{formataData(contrato.fimVigencia)}
										</div>
									</div>
								</div>
							</div>	
						</fieldset>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										<select name="idFornecedora" onChange={this.handleFornecedoraChange} 
											value={contratoApi.idFornecedora}
											disabled={contratoApi.id}>
											<option value="0">Selecione</option>
											{
												this.state.fornecedoras.map(fornecedora =>
													<option 
														key={fornecedora.id} 
														value={fornecedora.id}>
														{fornecedora.nome}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>
						</div>

						{contratoApi.idFornecedora !== '0' &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Nível de serviço acordado:</label>
											<NumberFormat value={contratoApi.nivelServicoAcordado} 
												thousandSeparator={'.'} 
												decimalSeparator={','} 
												decimalScale={2}
												allowNegative={false}
												isNumericString={true}
												className="texto-alinhado-direita"
												onValueChange={(valoresInput) => this.handleInputNumberChange('nivelServicoAcordado', valoresInput)}
												/>
										</div>
									</div>
								</div>
		
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Valor do unitário da consulta:</label>
											<NumberFormat value={contratoApi.valorUnitarioConsultaAPI} 
												thousandSeparator={'.'} 
												decimalSeparator={','} 
												decimalScale={7}
												allowNegative={false}
												isNumericString={true}
												prefix={'R$ '}
												className="texto-alinhado-direita"
												onValueChange={(valoresInput) => this.handleInputNumberChange('valorUnitarioConsultaAPI', valoresInput)}
												/>
										</div>
									</div>
								</div>
							</div>
						}


						<div className="actions-button">
							<div className="actions justify-content-start">
								{! contratoApi.id &&
									<button type="button" className="button is-primary" onClick={this.salvar} disabled={this.state.botoesHabilitados === false}>Incluir</button>
								}

								{contratoApi.id &&
									<button type="button" className="button is-primary" onClick={this.alterarContratoAPI} disabled={this.state.botoesHabilitados === false}>Alterar</button>
								}

								<button type="button" className="button is-secondary" onClick={this.limpar} disabled={this.state.botoesHabilitados === false}>Limpar</button>
								
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={this.state.botoesHabilitados === false}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
						
						<fieldset>
							<legend>API's cadastradas</legend>
							{(contratoApis && contratoApis.length > 0) &&	
								<div className="br-table">						
									<div className="table">
										<table>
											<thead>
												<tr>
												<th scope="col">API</th>
												<th scope="col" className="text-right">Nível de Serviço Acordado</th>
												<th scope="col" className="text-right">Valor Unitário da Consulta</th>
												<th className="text-center" scope="col">Ações</th>
												</tr>
											</thead>
											
											<tbody>
												{ contratoApis.map((contratoApi, index) =>
														<tr key={index} >
															<td>{contratoApi.nomeFornecedora}</td>
															<td className="text-right">{numberFormater2.format(contratoApi.nivelServicoAcordado)}</td>
															<td className="text-right">{numberFormater7.format(contratoApi.valorUnitarioConsultaAPI)}</td>
															<td className="text-center">
																<div className="actions">
																	<button type="button" onClick={() => this.confirmarExclusao(contratoApi)} disabled={this.state.botoesHabilitados === false} title="Excluir API">
																		<span className="sr-only">Excluir</span>
																		<i className="fas fa-trash"></i>
																	</button>

																	<button type="button" onClick={() => this.editarContratoAPI(contratoApi)} disabled={this.state.botoesHabilitados === false} title="Editar API">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																</div>
															</td>
														</tr>
													)}
											</tbody>
										</table>
									</div>
								</div>
							}

							{(! contratoApis || contratoApis.length === 0) &&
								<div>
									Nenhuma API cadastrada no contrato.
								</div>
							}
						</fieldset>
					</div>

				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const contratoApi = this.state.contratoApi;
			contratoApi[nome] = valor;
			this.setState({ contratoApi });
		}

		handleInputNumberChange(nomePropriedade, valoresInput){
			const contratoApi = this.state.contratoApi;
			contratoApi[nomePropriedade] = Number(valoresInput.value);
			this.setState({ contratoApi });
		}

		handleFornecedoraChange(evento){
			this.props.limpaMensagem();
			const idFornecedora =  evento.target.value;
			const apiCadastradaContrato = this.getApiCadastradaContrato(idFornecedora);

			if (apiCadastradaContrato){
				this.editarContratoAPI(apiCadastradaContrato);
			}else{
				const contratoApi = {idFornecedora: '0', valorUnitarioConsultaAPI: '', nivelServicoAcordado: ''};
				contratoApi['idFornecedora'] = idFornecedora;

				this.setState({
					contratoApi,
				});
			}
		}

		getApiCadastradaContrato(idFornecedora){
			for(var contratoApi of this.state.contratoApis){
				if(contratoApi['idFornecedora'] === parseInt(idFornecedora)){
					return contratoApi;
				}
			}
			return;
		}
		
		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const contratoApi = this.state.contratoApi;
			contratoApi['idContrato'] = this.state.contrato.id;
			const method = contratoApi.id ? 'put' : 'post';
			let url = `/api/contrato/contrato-api`;

			const options = {
				method,
				url,
				"data" : contratoApi,
			}
			this.props.mostraAguardando();

			api(options)
				.then(response => {
					const mensagem = `API ${contratoApi.id ? 'alterada' : 'incluída'} com sucesso!`;
					this.carregarAPIsContrato();
					this.setState({
						contratoApi: {idFornecedora: '0', valorUnitarioConsultaAPI: '', nivelServicoAcordado: ''}
					})
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.contratoApi.id ? 'alterar' : 'incluir'} a API do contrato.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});   

		}

		alterarContratoAPI(){
			this.salvar(this.state.e);
			this.fecharModais();
		}

		editarContratoAPI(contratoApi){
			this.props.limpaMensagem();
			this.setState({contratoApi: {...contratoApi}});
			window.scrollTo(0, 0);
		}

		confirmarExclusao(contratoApi) {
			this.setState(
				{
					exibeModalExcluir: true,
					contratoApiAlvo: contratoApi,
				}
			);
		}

		fecharModais () {
			this.setState(
				{
					exibeModalExcluir:false,
				}
			);
		}

		excluirContratoAPI(){
			this.props.limpaMensagem();
			const contratoApi = this.state.contratoApiAlvo;
			let url = `/api/contrato/contrato-api/${contratoApi.id}`;
			const options = {
				method: 'delete',
				url,
				"data" : contratoApi,
			}
			this.props.mostraAguardando(); 
			this.setState({botoesHabilitados:false});

			api(options)
				.then(response => {
					const mensagem = `API excluída com sucesso!`;
					this.carregarAPIsContrato();
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível excluir a API.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					
				})
				.then (() =>{
					this.props.escondeAguardando();
					this.setState({botoesHabilitados:true});
				});   
			
			this.fecharModais();
		}

		limpar(){
			this.props.limpaMensagem();
			this.setState({
				contratoApi: {idFornecedora: '0', valorUnitarioConsultaAPI: '', nivelServicoAcordado: ''},
			})
		}

		validaFormulario(){
			const LIMITE_VALOR_UNITARIO_CONSULTA_API = 9999999999.99;
			let mensagens = [];
			const contratoApi = this.state.contratoApi;
			const {idFornecedora, valorUnitarioConsultaAPI, nivelServicoAcordado} = contratoApi
			if(idFornecedora === "0" ){
				mensagens.push({mensagem: "API é obrigatório."})
			} else {
				validaCamposNumericos(mensagens, nivelServicoAcordado, "Nível de serviço acordado", true, false, false, 1);
				validaCamposNumericos(mensagens, valorUnitarioConsultaAPI, "Valor unitário da consulta", true, false, false, LIMITE_VALOR_UNITARIO_CONSULTA_API);
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { validaCamposNumericos } from "../../utils/formValidationUtil";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './API.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const ApiEdit =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				aplicacaoFornecedora: {
					id: '',	
					nome: '',	
					orgao: '',	
					descricaoComplementar: '',
					informacoesComplementares: '',
					economiaTransacaoSociedade: '',	
					economiaTransacaoGoverno: '',	
					economiaTransacaoContratual: '',	
					economiaTransacaoTotal: '',
					limiteConsultaAnual: '',	
					consumoAnoCorrente: '',
					disponivelAdesaoOrgao: false,
					fornecedorAutorizaInclusaoAdesao: false,
					fornecedorAutorizaAlteracaoAdesao: false,
				},
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleInputNumberChange = this.handleInputNumberChange.bind(this);
			this.handleDisponivelAdesaoOrgaoChange = this.handleDisponivelAdesaoOrgaoChange.bind(this);
			this.salvar = this.salvar.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de API's</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				api.get(`/api/aplicacao-fornecedora/${id}`)
				.then(response => { 
					const aplicacaoFornecedora = response.data;
					this.setState(
						{
							aplicacaoFornecedora,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar a Aplicação Fornecedora (API) " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}
		}

		renderForm() {
			const edicao = this.state.aplicacaoFornecedora.id ? true : false;
			return(
				<div className="container-fluid">
					<div className="br-form">

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{this.state.aplicacaoFornecedora.nome}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Órgão Fornecedor:</label>
										{this.state.aplicacaoFornecedora.orgao}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Economia por transação para a sociedade:</label>
										<NumberFormat value={this.state.aplicacaoFornecedora.economiaTransacaoSociedade} 
											thousandSeparator={'.'} 
											decimalSeparator={','} 
											decimalScale={2}
											allowNegative={false}
											isNumericString={true}
											prefix={'R$ '}
											className="texto-alinhado-direita"
											onValueChange={(valoresInput) => this.handleInputNumberChange('economiaTransacaoSociedade', valoresInput)}
											/>
									</div>
								</div>
							</div>
						
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Economia por transação para o governo:</label>
										<NumberFormat value={this.state.aplicacaoFornecedora.economiaTransacaoGoverno} 
											thousandSeparator={'.'} 
											decimalSeparator={','} 
											decimalScale={2}
											allowNegative={false}
											isNumericString={true}
											prefix={'R$ '}
											className="texto-alinhado-direita"
											onValueChange={(valoresInput) => this.handleInputNumberChange('economiaTransacaoGoverno', valoresInput)}
											/>
									</div>
								</div>
							</div>
						
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Economia por transação contratual:</label>
										<NumberFormat value={this.state.aplicacaoFornecedora.economiaTransacaoContratual} 
											thousandSeparator={'.'} 
											decimalSeparator={','} 
											decimalScale={2}
											allowNegative={false}
											isNumericString={true}
											prefix={'R$ '}
											className="texto-alinhado-direita"
											onValueChange={(valoresInput) => this.handleInputNumberChange('economiaTransacaoContratual', valoresInput)}
											/>
									</div>
								</div>
							</div>
						
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Economia por transação total:</label>
										<NumberFormat value={this.state.aplicacaoFornecedora.economiaTransacaoTotal} 
											thousandSeparator={'.'} 
											decimalSeparator={','} 
											decimalScale={2}
											allowNegative={false}
											isNumericString={true}
											displayType={'text'}
											prefix={'R$ '}
											className="texto-alinhado-direita caixa-texto"
											/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Limite de consultas por ano:</label>
										<NumberFormat value={this.state.aplicacaoFornecedora.limiteConsultaAnual} 
											thousandSeparator={'.'} 
											decimalSeparator={','} 
											decimalScale={0}
											allowNegative={false}
											isNumericString={true}
											className="texto-alinhado-direita"
											onValueChange={(valoresInput) => this.handleInputNumberChange('limiteConsultaAnual', valoresInput)}
											/>
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Total consumido no ano:</label>
										<NumberFormat value={this.state.aplicacaoFornecedora.consumoAnoCorrente} 
											thousandSeparator={'.'} 
											decimalSeparator={','} 
											decimalScale={0}
											allowNegative={false}
											isNumericString={true}
											displayType={'text'}
											className="texto-alinhado-direita caixa-texto"
											/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="disponivelAdesaoOrgao">Disponível para adesão dos órgãos:</label>
										<select name="disponivelAdesaoOrgao" value={this.state.aplicacaoFornecedora.disponivelAdesaoOrgao} onChange={this.handleDisponivelAdesaoOrgaoChange}>
											<option value="true">Sim</option>
											<option value="false">Não</option>	
										</select>
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="fornecedorAutorizaInclusaoAdesao">Adesão exige aprovação do órgão cedente dos dados:</label>
										<select name="fornecedorAutorizaInclusaoAdesao" value={this.state.aplicacaoFornecedora.fornecedorAutorizaInclusaoAdesao} onChange={this.handleInputChange} disabled={! this.state.aplicacaoFornecedora.disponivelAdesaoOrgao}>
											<option value="true">Sim</option>
											<option value="false">Não</option>	
										</select>
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="fornecedorAutorizaAlteracaoAdesao">Alteração de volumetria exige aprovação do órgão cedente dos dados:</label>
										<select name="fornecedorAutorizaAlteracaoAdesao" value={this.state.aplicacaoFornecedora.fornecedorAutorizaAlteracaoAdesao} onChange={this.handleInputChange} disabled={! this.state.aplicacaoFornecedora.disponivelAdesaoOrgao}>
											<option value="true">Sim</option>
											<option value="false">Não</option>	
										</select>
									</div>
								</div>
							</div>	
						</div>

						<div className="row">
							<div className="col-md">  
								<div className="field">
									<div className="input"> 
										<label htmlFor="descricaoComplementar">Descrição:</label>
										<textarea name="descricaoComplementar" value={this.state.aplicacaoFornecedora.descricaoComplementar} onChange={this.handleInputChange} maxLength={255} style={{height:"90px"}}/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">  
								<div className="field">
									<div className="input"> 
										<label htmlFor="informacoesComplementares">Informações Complementares:</label>
										<textarea name="informacoesComplementares" value={this.state.aplicacaoFornecedora.informacoesComplementares} onChange={this.handleInputChange} maxLength={255} style={{height:"90px"}}/>
									</div>
								</div>
							</div>
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{edicao === true ? 'Alterar' : 'Incluir'}</button>
								<Link to={`/api/attachments/${this.state.aplicacaoFornecedora.id}`}> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Anexos</button>
									</div>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const aplicacaoFornecedora = this.state.aplicacaoFornecedora;
			aplicacaoFornecedora[nome] = valor;
			this.setState({ aplicacaoFornecedora });
		}

		handleInputNumberChange(nomePropriedade, valoresInput){
			const aplicacaoFornecedora = this.state.aplicacaoFornecedora;
			aplicacaoFornecedora[nomePropriedade] = Number(valoresInput.value);

			const {economiaTransacaoSociedade, economiaTransacaoGoverno, economiaTransacaoContratual} = aplicacaoFornecedora;	
			const economiaTransacaoTotal = economiaTransacaoSociedade + economiaTransacaoGoverno + economiaTransacaoContratual;
			aplicacaoFornecedora['economiaTransacaoTotal'] = economiaTransacaoTotal;

			this.setState({ aplicacaoFornecedora });
		}

		handleDisponivelAdesaoOrgaoChange(evento){
			const disponivelAdesaoOrgao =  evento.target.value === 'true';
			const fornecedorAutorizaInclusaoAdesao = disponivelAdesaoOrgao ? this.state.fornecedorAutorizaInclusaoAdesao : false;
			const fornecedorAutorizaAlteracaoAdesao = disponivelAdesaoOrgao ? this.state.fornecedorAutorizaAlteracaoAdesao : false;

			const aplicacaoFornecedora = this.state.aplicacaoFornecedora;
			aplicacaoFornecedora.disponivelAdesaoOrgao = disponivelAdesaoOrgao;
			aplicacaoFornecedora.fornecedorAutorizaInclusaoAdesao = fornecedorAutorizaInclusaoAdesao;
			aplicacaoFornecedora.fornecedorAutorizaAlteracaoAdesao = fornecedorAutorizaAlteracaoAdesao;

			this.setState({
				aplicacaoFornecedora
			});
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const aplicacaoFornecedora = this.state.aplicacaoFornecedora;
			const method = aplicacaoFornecedora.id ? 'put' : 'post';
			let url = "/api/aplicacao-fornecedora";
			if(aplicacaoFornecedora.id){
				url = url + '/' + aplicacaoFornecedora.id;
			}
			const options = {
				method,
				url,
				"data" : aplicacaoFornecedora,
			}

			this.props.mostraAguardando();

			api(options)
				.then(response => {
					const mensagem = `API ${this.state.aplicacaoFornecedora.id ? 'alterada' : 'incluída'} com sucesso!`;
					this.setState({ 
						aplicacaoFornecedora: response.data ,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.aplicacaoFornecedora.id ? 'alterar' : 'incluir'} a API.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);

				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});   

		}

		validaFormulario(){
			let mensagens = [];
			const {economiaTransacaoSociedade, economiaTransacaoGoverno, economiaTransacaoContratual, limiteConsultaAnual } = this.state.aplicacaoFornecedora;
			const LIMITE_CAMPOS_TRANSACAO = 99999999;
			const LIMITE_CONSULTA_ANUAL = 1000000000;

			validaCamposNumericos(mensagens, economiaTransacaoSociedade, "Economia por transação para a sociedade", false, false, false, LIMITE_CAMPOS_TRANSACAO);
			validaCamposNumericos(mensagens, economiaTransacaoGoverno, "Economia por transação para o governo", false, false, false, LIMITE_CAMPOS_TRANSACAO);
			validaCamposNumericos(mensagens, economiaTransacaoContratual, "Economia por transação contratual", false, false, false, LIMITE_CAMPOS_TRANSACAO);
			validaCamposNumericos(mensagens, limiteConsultaAnual, "Limite de consultas por ano", false, false, true, LIMITE_CONSULTA_ANUAL);

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens);
				throw new Error("Formulário não preenchido corretamente.");
			}
		}
	}

) // redux
); // router
import React, { Component } from "react";
import './RelatorioConsumoPorMetodo.css';

export const ConsumoPorMetodoMensal =

	class extends Component {

		render() {
			const { resultadoPesquisa, pesquisaExecutada } = this.props;
			const numberFormater = new Intl.NumberFormat('pt-BR', {useGrouping: true });
			return (
				<div>
					{(resultadoPesquisa && resultadoPesquisa.length > 0) &&	
						<div className="br-table">						
							<div className="table">
								<table>
									<thead>
										<tr>
										<th scope="col" style={{width: "1%"}}></th>	
										<th scope="col">API</th>
										<th scope="col">Órgão Consumidor</th>
										<th scope="col">Metodo</th>
										<th scope="col">Aplicação</th>
										<th scope="col">Mês/Ano</th>
										<th scope="col" className="text-right">Limite do Órgão</th>
										<th scope="col">Periodicidade</th>
										<th scope="col" className="text-right">Consumo</th>
										</tr>
									</thead>
									
									<tbody>
										{ resultadoPesquisa.map((consumo, index) =>
												<tr key={index} className={consumo.totalizacaoOrgaoAPIMetodo === true ? 'tr-totalizacao-orgao-api-metodo' : consumo.totalizacaoOrgaoAPI === true ? 'tr-totalizacao-orgao-api' : consumo.totalizacaoAPI === true ? 'tr-totalizacao-api' : ''}>
													<td>{consumo.totalizacaoOrgaoAPIMetodo === true || consumo.totalizacaoOrgaoAPI === true ? 'Total' : consumo.totalizacaoAPI === true ? 'Total API' : ''}</td>
													<td>{consumo.nomeFornecedora}</td>
													<td>{consumo.nomeOrgao}</td>
													<td>{consumo.metodo}</td>
													<td>{consumo.nomeConsumidora}</td>
													{(consumo.totalizacaoOrgaoAPIMetodo === false && consumo.totalizacaoOrgaoAPI === false && consumo.totalizacaoAPI === false) &&
														<td>{consumo.mes}/{consumo.ano}</td>
													}
													{(consumo.totalizacaoOrgaoAPIMetodo === true || consumo.totalizacaoOrgaoAPI === true || consumo.totalizacaoAPI === true) &&
														<td></td>
													}
													<td className="text-right" >{consumo.totalizacaoOrgaoAPIMetodo === false && consumo.totalizacaoOrgaoAPI === false && consumo.totalizacaoAPI === false ? numberFormater.format(consumo.limite) : ''}</td>
													<td>{consumo.periodicidade}</td>
													<td className="text-right">{numberFormater.format(consumo.consumoMensal)}</td>
												</tr>
											)}
									</tbody>
								</table>
							</div>
						</div>
					}

					{(pesquisaExecutada && (! resultadoPesquisa || resultadoPesquisa.length === 0)) &&
						<div>
							Nenhum consumo encontrado com os critérios informados.
						</div>
					}
				</div>
			);
		}

		
		

		
	}


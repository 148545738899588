import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { Pagination } from "../Pagination/Pagination";
import {getMessagemErro} from "../../utils/erro";
import {maskedCnpj} from "../../utils/maskUtil";

import api from "../../services/api";
import './RelatorioPrimeiroConsumoOrgao.css';
import {formataData} from '../../utils/formatadorData';
import { baixarArquivo } from "../../utils/downloadUtil";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const RelatorioPrimeiroConsumoOrgao =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				resultadoPesquisa: {},
				fornecedoras: [],
				fornecedora: '',
				tiposVisao: [],
				tipoVisao: 'C',
				considerarAplicacaoMonitora: false,
				pesquisaExecutada : false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleTipoVisaoChange = this.handleTipoVisaoChange.bind(this);
			this.carregarFornecedoras = this.carregarFornecedoras.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.mostraRelatorioPrimeiroConsumoOrgaoPDF = this.mostraRelatorioPrimeiroConsumoOrgaoPDF.bind(this);
			this.mostraRelatorioPrimeiroConsumoOrgaoCSV = this.mostraRelatorioPrimeiroConsumoOrgaoCSV.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Relatório de Órgãos Consumidores</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			if(this.props.user.isFornecedor === true){
				api.get('/api/consumo/tipos-visao-relatorio')
				.then(response => { this.setState({tiposVisao: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de visão para o relatório " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}
			this.carregarFornecedoras(this.state.tipoVisao);
		}

		renderForm() {
			const { resultadoPesquisa, pesquisaExecutada } = this.state;
			const { content } = resultadoPesquisa;
			const exibeBotoes = resultadoPesquisa.content && resultadoPesquisa.content.length > 0;
			const user = this.props.user;
			const exibeConsiderarAplicacaoMonitora = user && (user.isAdministrador === true || user.isMantenedor === true);

			return(
				<div className="container-fluid">
					<div className="br-form">
					{user && user.isFornecedor === true &&
							<div className="row">
								<div className="col-sd">
									<div className="field">
										<div className="input">
											<label htmlFor="tipoVisao">Mostrar:</label>
											<select name="tipoVisao" onChange={this.handleTipoVisaoChange} value={this.state.tipoVisao}>
												{
													this.state.tiposVisao.map(tipoVisao =>
														<option 
															key={tipoVisao.codigo} 
															value={tipoVisao.codigo}>
															{tipoVisao.nome}
														</option>
												)}
											</select>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										<select name="fornecedora" onChange={this.handleInputChange} value={this.state.fornecedora}>
											<option value="">Selecione</option>
												{
													this.state.fornecedoras.map(fornecedora =>
														<option 
															key={fornecedora.id} 
															value={fornecedora.id}>
															{fornecedora.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
						</div>

						{ exibeConsiderarAplicacaoMonitora &&	
							<div className="row">		
								<div className="col-md">
									<div className="check">
										<div className="input">
											<input type="checkbox" name="considerarAplicacaoMonitora" checked={this.state.considerarAplicacaoMonitora} onChange={this.handleInputChange} />
											<label>Mostrar consumo das aplicações de monitoração</label>
										</div>
									</div>
								</div>
							</div>
						}

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{(exibeBotoes) &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioPrimeiroConsumoOrgaoPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{(exibeBotoes) &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioPrimeiroConsumoOrgaoCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
							

						{(content && content.length > 0) &&
							<div className="container-fluid">	
								<h2>Primeiro acesso por órgão:</h2>
								<div className="br-table is-collapsible">						
									<div className="table">
										<table>
											<thead>
												<tr>
													<th scope="col">CNPJ do Órgao</th>
													<th scope="col">Nome do Órgão</th>
													<th scope="col">Data Primeiro Acesso</th>
													<th scope="col">Adesão Ativa</th>
												</tr>
											</thead>
											<tbody>
												{ content.map((consumo, index) =>
														<tr key={index} >
															<td style={{width: "12%"}}>{maskedCnpj(consumo.cnpjOrgao)}</td>
															<td>{consumo.nomeOrgao}</td>
															<td style={{width: "12%"}}>{formataData(consumo.dataConsumo)}</td>
															<td style={{width: "10%"}}>{consumo.possuiAdesaoAtiva ? "Sim" : "Não"}</td>
														</tr>
													)}
											</tbody>
										</table>
										<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
									</div>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Não foram encontrados consumos os critérios informados.
							</div>
						}

					</div>
				</div>
				
			);
		}

		validaFormulario(){
			let mensagens = [];
			const { fornecedora } = this.state;

			if(!fornecedora || fornecedora.trim().length === 0 ){
				mensagens.push({mensagem: "API é obrigatório."})
			} 

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleTipoVisaoChange(evento){
			const tipoVisao =  evento.target.value;
			let fornecedoras = [];

			this.setState({
				tipoVisao,
				fornecedoras,
				fornecedora: "",
			});

			this.carregarFornecedoras(tipoVisao);
		}

		carregarFornecedoras(idTipoVisao) {
			this.setState(
				{
					fornecedora: "",
					carregandoFornecedoras : true
				}
			);
			api.get(`/api/aplicacao-fornecedora/tipo-visao/${idTipoVisao}/orgao-consumidor`)
			.then(response => { 
				const fornecedoras = response.data;
				this.setState(
					{
						fornecedoras,
						carregandoFornecedoras : false,
					}
				)
			 })
			.catch(erro => {
				this.setState({carregandoFornecedoras : false});
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0, e);
		}

		executaPesquisaPaginada(pagina, e){
			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {fornecedora, tipoVisao, considerarAplicacaoMonitora} = this.state;
			const criterioPesquisa = {fornecedora, tipoVisao, considerarAplicacaoMonitora, pagina};
			const url = `/api/auditoria/primeiro-acesso-orgao/paginado`

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar o relatório de auditoria. ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		mostraRelatorioPrimeiroConsumoOrgaoPDF(e){
			this.mostraRelatorioPrimeiroConsumoPorOrgao(e, 'pdf')
		}

		mostraRelatorioPrimeiroConsumoOrgaoCSV(e){
			this.mostraRelatorioPrimeiroConsumoPorOrgao(e, 'csv')
		}

		mostraRelatorioPrimeiroConsumoPorOrgao(e, tipo){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {fornecedora, tipoVisao, considerarAplicacaoMonitora} = this.state;
			const criterioPesquisa = {fornecedora, tipoVisao, considerarAplicacaoMonitora};
			const url = `/api/auditoria/primeiro-acesso-orgao/${tipo}`;

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioPrimeiroConsumoPorOrgao.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de primeiro consumo por órgão';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			const tipoVisao = 'C';
			this.setState({
				fornecedora: '',
				tipoVisao,
				considerarAplicacaoMonitora: false,
				resultadoPesquisa: {},
				pesquisaExecutada : false,
			});
			this.carregarFornecedoras(tipoVisao);
		}
	}

) // redux
); // router
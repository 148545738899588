import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { Pagination } from "../Pagination/Pagination";
import {getMessagemErro} from "../../utils/erro";

import api from "../../services/api";
import './RelatorioAdesaoAplicacao.css';
import { baixarArquivo } from "../../utils/downloadUtil";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
const LIMITE_EXIBICAO_FINALIDADE = 500;
const RELATORIO_ADESAO_ORGAO = 'ORGAO';
const RELATORIO_ADESAO_APLICACAO_CONSUMIDORA = 'CONSUMIDORA';

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const RelatorioAdesaoAplicacao =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				relatorio: RELATORIO_ADESAO_APLICACAO_CONSUMIDORA,
				orgaos: [],
				orgao: '',
				fornecedoras: [],
				fornecedora: '',
				nomeConsumidora: "",
				ativo: true,
				carregandoFornecedoras: false,
				resultadoPesquisa: {},
				pesquisaExecutada : false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.handleTipoRelatorioChange = this.handleTipoRelatorioChange.bind(this);
			this.carregarFornecedoras = this.carregarFornecedoras.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.mostraRelatorioCSV = this.mostraRelatorioCSV.bind(this);
			this.mostraRelatorioPDF = this.mostraRelatorioPDF.bind(this);
			this.toggleFinalidade = this.toggleFinalidade.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Relatório de Adesões</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			api.get('/api/orgao/consumidores/perfil-usuario')
				.then(response => { this.setState({orgaos: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar órgãos consumidores " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});

			this.carregarFornecedoras(this.state.orgao);
		}

		renderForm() {
			const { resultadoPesquisa, pesquisaExecutada } = this.state;
			const { content } = resultadoPesquisa;
			const exibeBotoes = resultadoPesquisa.content && resultadoPesquisa.content.length > 0;

			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Tipo de Relatório:</label>
										<select name="relatorio" value={this.state.relatorio} onChange={this.handleTipoRelatorioChange}>
											<option value={RELATORIO_ADESAO_APLICACAO_CONSUMIDORA}>Adesões das Aplicações Consumidoras</option>
											<option value={RELATORIO_ADESAO_ORGAO}>Adesões dos Órgãos</option>
										</select>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label >Órgao Consumidor:</label>
										<select name="orgao" onChange={this.handleOrgaoChange} value={this.state.orgao}>
											<option value="">Todos</option>
											{
												this.state.orgaos.map(orgao =>
													<option 
														key={orgao.id} 
														value={orgao.id}>
														{orgao.nome}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{this.state.fornecedoras.length > 0 &&
											<select name="fornecedora" onChange={this.handleInputChange} value={this.state.fornecedora}>
											<option value="0">Selecione uma opção</option>
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{this.state.carregandoFornecedoras === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}

										{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'Não há APIs para serem selecionadas.'}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							{this.state.relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA &&
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Nome Aplicação:</label>
											<input type="text" name="nomeConsumidora" 
												value={this.state.nomeConsumidora}  
												onChange={this.handleInputChange} 
												maxLength="15"/>
										</div>
									</div>
								</div>
							}
							
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>{this.state.relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA ? 'Selecionar aplicações:' : 'Selecionar adesões'}</label>
										<select name="ativo" value={this.state.ativo} onChange={this.handleInputChange}>
											<option value="0">Todos</option>
											<option value="true">Ativas</option>
											<option value="false">Inativas</option>	
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

						{(content && content.length > 0) &&
							<div className="container-fluid">	
								<h2>Adesões:</h2>
								<div className="br-table">						
									<div className="table">
										<table>
											<thead>
												<tr>
													<th scope="col">Órgao</th>
													{this.state.relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA &&
														<th scope="col">Aplicação</th>
													}
													<th scope="col">{this.state.relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA ? 'Situação Aplicação' : 'Situação Adesão'}</th>
													<th scope="col">API</th>
													<th style={{width: "30%"}} scope="col">Finalidade</th>
													<th style={{width: "4%"}} className="text-center" scope="col">Ações</th>
												</tr>
											</thead>
											<tbody>
												{ content.map((adesao, index) =>
														<tr key={index} >
															<td>{adesao.nomeOrgaoConsumidor}</td>
															{this.state.relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA &&
																<td>{adesao.nomeConsumidora}</td>
															}

															{this.state.relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA &&
																<td>{adesao.consumidoraAtiva ? "Ativa" : "Inativa"}</td>
															}

															{this.state.relatorio === RELATORIO_ADESAO_ORGAO &&
																<td>{adesao.adesaoAtiva ? "Ativa" : "Inativa"}</td>
															}


															<td>{adesao.nomeFornecedora}</td>
															<td>{this.getFinalidade(adesao)}</td>
															<td className="text-center">
																<div className="actions">
																	{adesao.exibeExpandirComprimir === true &&
																		<button type="button" onClick={() => this.toggleFinalidade(index)} disabled={this.state.botoesHabilitados === false} title={adesao.mostraFinalidadeResumida ? "Expandir Finalidade" : "Comprimir Finalidade"}>
																			<span className="sr-only">Expandir/Comprimir Finalidade</span>
																			<i className={adesao.mostraFinalidadeResumida ? "fas fa-plus-square" : "fas fa-minus-square"}></i>
																		</button>
																	}
																</div>
															</td>
														</tr>
													)}
											</tbody>
										</table>
										<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
									</div>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Não foram encontrados dados para o filtro do relatório informado.
							</div>
						}

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleOrgaoChange(evento){
			const idOrgao =  evento.target.value;

			this.setState({
				fornecedora: "",
				orgao: idOrgao,
			});

			this.carregarFornecedoras(idOrgao);
		}

		handleTipoRelatorioChange(evento){
			const relatorio =  evento.target.value;
			this.setState({
				relatorio,
				resultadoPesquisa: {},
				pesquisaExecutada : false,
			});
		}

		carregarFornecedoras(idOrgao) {
			this.setState(
				{
					fornecedora: "",
					carregandoFornecedoras : true
				}
			);

			api.get(`/api/aplicacao-fornecedora/tipo-visao/C/orgao-consumidor?idOrgaoConsumidor=${idOrgao}`)
			.then(response => { 
				const fornecedoras = response.data;
				this.setState(
					{
						fornecedoras,
						carregandoFornecedoras : false,
					}
				)
			 })
			.catch(erro => {
				this.setState({carregandoFornecedoras : false});
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada(pagina){

			const {fornecedora, orgao, nomeConsumidora, ativo, relatorio } = this.state;
			const criterioPesquisa = {fornecedora, orgao, nomeConsumidora, ativo };

			let url;
			if (relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA){
				url = '/api/aplicacao-consumidora/adesao/paginado';
			} else {
				url = '/api/orgao/adesao/paginado';
			}
			criterioPesquisa['pagina'] = pagina;

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					let resultadoPesquisa = response.data;

					for (const aac of resultadoPesquisa.content) { 
						const finalidade = aac.finalidade;
						const exibeExpandirComprimir = finalidade.length > LIMITE_EXIBICAO_FINALIDADE;
						aac['exibeExpandirComprimir'] = exibeExpandirComprimir;
						aac['mostraFinalidadeResumida'] = exibeExpandirComprimir;
					}

					this.setState({
						resultadoPesquisa,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar as aplicações ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		getFinalidade(adesaoAplicacaoConsumidora) {
			return adesaoAplicacaoConsumidora.mostraFinalidadeResumida ? adesaoAplicacaoConsumidora.finalidade.substring(0, LIMITE_EXIBICAO_FINALIDADE) + '...' : adesaoAplicacaoConsumidora.finalidade;
		}

		toggleFinalidade(index) {
			const resultadoPesquisa = this.state.resultadoPesquisa;
			const adesaoAplicacaoConsumidora = resultadoPesquisa.content[index];
			
			adesaoAplicacaoConsumidora['mostraFinalidadeResumida'] = ! adesaoAplicacaoConsumidora.mostraFinalidadeResumida;
			this.setState({resultadoPesquisa});
		}

		mostraRelatorioCSV(e){
			this.mostraRelatorio(e, 'csv');
		}

		mostraRelatorioPDF(e){
			this.mostraRelatorio(e, 'pdf');
		}

		mostraRelatorio(e, tipo){
			const {fornecedora, orgao, nomeConsumidora, ativo, relatorio } = this.state;
			const criterioPesquisa = {fornecedora, orgao, nomeConsumidora, ativo };
			let url;
			if (relatorio === RELATORIO_ADESAO_APLICACAO_CONSUMIDORA){
				url = `/api/aplicacao-consumidora/adesao/${tipo}`;
			} else {
				url = `/api/orgao/adesao/${tipo}`;
			}

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioAdesoes.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de aplicação';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			const orgao = '';
			this.setState({
				relatorio: RELATORIO_ADESAO_APLICACAO_CONSUMIDORA,
				orgao,
				fornecedora: '',
				nomeConsumidora: "",
				ativo: true,
				resultadoPesquisa: {},
				pesquisaExecutada : false,
			});
			this.carregarFornecedoras(orgao);
		}

	}

) // redux
); // router
import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import {formataData} from '../../utils/formatadorData';

import api from "../../services/api";
import './RelatorioDisponibilidade.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { baixarArquivo } from "../../utils/downloadUtil";

registerLocale("pt_br", pt_br);

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

function IconeDisponibilidade(props){
	const otimo = props.percentualDisponibilidadeOtimo;
	const bom = props.percentualDisponibilidadeBom;
	const disponibilidade =props.disponibilidade;

	let id = null;
	if(disponibilidade >= otimo){
		id='otimo';
	}else if(disponibilidade >= bom){
		id='bom';
	} else {
		id='ruim';
	}
	
	let classe = null;
	if(disponibilidade >= 0.95){
		classe='fa fa-battery-full';
	}else if(disponibilidade >= 0.75){
		classe='fa fa-battery-three-quarters';
	}else if(disponibilidade >= 0.50){
		classe='fa fa-battery-half';
	} else if(disponibilidade >= 0.10){
		classe='fa fa fa-battery-quarter';
	} 
	else {
		classe='fa fa fa-battery-empty';
	}

	return <span className={classe} id={id}></span>
}

export const RelatorioDisponibilidade =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				fornecedoras: [],
				fornecedora: '',
				relatorioDisponibilidade: {},
				dataInicial: '',
				dataFinal: '',
				pesquisaExecutada : false,
				botoesHabilitados : true,
				carregandoFornecedoras: false,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.botaoPesquisarHabilitado = this.botaoPesquisarHabilitado.bind(this);
			this.mostraRelatorioDisponibilidadePDF = this.mostraRelatorioDisponibilidadePDF.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Relatório de disponibilidade</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			let url = '/api/aplicacao-fornecedora/monitorada';
			this.setState({carregandoFornecedoras : true});

			api.get(url)
			.then(response => { this.setState(
				{
					fornecedoras: response.data,
					carregandoFornecedoras: false
				}
				) 
			})
			.catch(erro => {
				this.setState({carregandoFornecedoras : false});
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});

		}

		renderForm() {
			const { relatorioDisponibilidade, pesquisaExecutada } = this.state;
			const { disponibilidadesDiarias } = relatorioDisponibilidade;
			const numberFormater = new Intl.NumberFormat('pt-BR', {minimumFractionDigits:2, maximumFractionDigits:2 });

			return(
				<div className="container-fluid">
					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{ this.state.fornecedoras.length > 0 && 
											<select name="fornecedora" onChange={this.handleInputChange} value={this.state.fornecedora}>
												<option value="">Selecione a API</option>
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'O órgão do usuário não é fornecedor ou consumidor de API monitorada.'}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Data Inicial:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={this.state.dataInicial} onChange={data => {this.setState({dataInicial: data})}} />
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Data Final:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={this.state.dataFinal} onChange={data => {this.setState({dataFinal: data})}} />
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.botaoPesquisarHabilitado()}>Pesquisar</button>
								{(disponibilidadesDiarias && disponibilidadesDiarias.length > 0) &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioDisponibilidadePDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

						{(disponibilidadesDiarias && disponibilidadesDiarias.length > 0) &&
							<div className="container-fluid">	
								<h2>Disponibilidade diária:</h2>
								<div className="br-table is-collapsible">						
									<div className="table">
										<table>
											<thead>
												<tr>
												<th scope="col">Data</th>
												<th scope="col" className="text-right" style={{width: "15%"}}>Indisponibilidade em Minutos</th>
												<th scope="col" className="text-right" style={{width: "15%"}}>Percentual de Disponibilidade</th>
												<th scope="col"></th>
												</tr>
											</thead>
											
											<tbody>
												{ disponibilidadesDiarias.map((disponibilidade, index) =>
														<tr key={index} >
															<td>{formataData(disponibilidade.data)}</td>
															<td className="text-right">{disponibilidade.indisponibilidadeMinutos}</td>
															<td className="text-right">{numberFormater.format(disponibilidade.disponibilidade * 100)}%</td>
															<td style={{width: "3%"}}>
																<IconeDisponibilidade disponibilidade = {disponibilidade.disponibilidade} 
																	percentualDisponibilidadeOtimo = {relatorioDisponibilidade.percentualDisponibilidadeOtimo} 
																	percentualDisponibilidadeBom = {relatorioDisponibilidade.percentualDisponibilidadeBom}/>
															</td>
														</tr>
													)}
											</tbody>
										</table>
									</div>
								</div>

								<br/><br/>
								<h2>Disponibilidade no período:</h2>
								<div className="br-table is-collapsible">						
									<div className="table">
										<table>
											<thead>
												<tr>
													<th scope="col">Indisponibilidade em minutos</th>
													<th scope="col" className="text-right">Disponibilidade</th>
													<th scope="col"></th>
												</tr>
											</thead>
											
											<tbody>
												<tr>
													<td>{relatorioDisponibilidade.indisponibilidadeMinutosPeriodo}</td>
													<td className="text-right">{numberFormater.format(relatorioDisponibilidade.disponibilidadePeriodo * 100)}%</td>
													<td style={{width: "3%"}}>
														<IconeDisponibilidade disponibilidade = {relatorioDisponibilidade.disponibilidadePeriodo} 
															percentualDisponibilidadeOtimo = {relatorioDisponibilidade.percentualDisponibilidadeOtimo} 
															percentualDisponibilidadeBom = {relatorioDisponibilidade.percentualDisponibilidadeBom}/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! disponibilidadesDiarias || disponibilidadesDiarias.length === 0)) &&
							<div>
								Informações não encontradas com os critérios informados.
							</div>
						}

					</div>
				</div>

				
			);
		}

		botaoPesquisarHabilitado() {
			const {botoesHabilitados, carregandoFornecedoras, fornecedoras} = this.state;
			return botoesHabilitados && carregandoFornecedoras === false && fornecedoras.length > 0;
		}

		validaFormulario(){
			let mensagens = [];
			const {fornecedora, dataInicial, dataFinal} = this.state;

			if(! fornecedora){
				mensagens.push({mensagem: "API é obrigatório."})
			}

			if(!dataInicial){
				mensagens.push({mensagem: "Data Inicial é obrigatório."})
			}

			if(!dataFinal){
				mensagens.push({mensagem: "Data Final é obrigatório"})
			}

			if(dataInicial && dataFinal && dataInicial > dataFinal){
				mensagens.push({mensagem: "A data inicial deve ser menor ou igual à data final"})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		pesquisar (e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {fornecedora, dataInicial, dataFinal } = this.state;
			const criterioPesquisa = {fornecedora, dataInicial, dataFinal};
			const url = '/api/disponibilidade';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					this.setState({
						relatorioDisponibilidade: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar a disponibilidade. ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		mostraRelatorioDisponibilidadePDF(e){
			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {fornecedora, dataInicial, dataFinal} = this.state;
			const criterioPesquisa = {fornecedora, dataInicial, dataFinal};
			const url = "/api/disponibilidade/pdf";

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), 'RelatorioDisponibilidade.pdf');
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de disponibilidade';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			this.setState({
				fornecedora: '',
				resultadoPesquisa: {},
				pesquisaExecutada : false,
				dataInicial: '',
				dataFinal: '',
			});
		}
	}

) // redux
); // router
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Pagination } from "../Pagination/Pagination";
import {getMessagemErro} from "../../utils/erro";
import {formataDataHora} from '../../utils/formatadorData';
import api from "../../services/api";
import './SolicitacaoRelatorioAuditoria.css';

import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { baixarArquivo } from "../../utils/downloadUtil";

registerLocale("pt_br", pt_br);

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const SolicitacaoRelatorioAuditoriaList =

// router
withRouter(
// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {
				criterioPesquisa: {
					fornecedora: '',
					orgaoConsumidor: '',
					periodoInicial: '',
					periodoFinal: '',
					cpfSolicitante: '',
				},
				resultadoPesquisa: {},
				fornecedoras: [],
				orgaos: [],
				pesquisaExecutada: false,
				exibeModalExcluir: false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);

			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.fechaModais = this.fechaModais.bind(this);
			this.handleOkModais = this.handleOkModais.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Solicitações de Relatório de Auditoria</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			api.get('/api/aplicacao-fornecedora/relatorio-auditoria')
				.then(response => { this.setState({fornecedoras: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar as aplicações fornecedoras " + JSON.stringify(erro))
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		renderForm() {
			const cpfUsuario = this.props.user.sub;

			const { criterioPesquisa, resultadoPesquisa, pesquisaExecutada, fornecedoras, orgaos } = this.state;
			const { content } = resultadoPesquisa;
			const { fornecedora, orgaoConsumidor, cpfSolicitante, periodoInicial, periodoFinal } = criterioPesquisa;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalExcluir}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente excluir a solicitação de relatório  de auditoria?"/>

					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										<select name="fornecedora" onChange={this.handleFornecedoraChange} value={fornecedora}>
											<option value="">Selecione</option>
												{
													fornecedoras.map(f =>
														<option 
															key={f.id} 
															value={f.id}>
															{f.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
						</div>

						{ fornecedora && 
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Órgão Consumidor:</label>
											<select name="orgaoConsumidor" onChange={this.handleInputChange} value={orgaoConsumidor}>
												<option value="">Selecione</option>
													{
														orgaos.map(orgao =>
															<option 
																key={orgao.id} 
																value={orgao.id}>
																{orgao.cnpjFormatado} - {orgao.nome}
															</option>
													)}
											</select>
										</div>
									</div>
								</div>
							</div>
						}

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>CPF Solicitante:</label>
										<input type="text" name="cpfSolicitante" 
											value={cpfSolicitante}  
											onChange={this.handleInputChange} maxLength={11}/>
										
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Período Inicial:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={periodoInicial} 
											disableClock={true}
											onChange={data => {
												const criterioPesquisa = this.state.criterioPesquisa;
												criterioPesquisa['periodoInicial'] = data;
												this.setState({criterioPesquisa})}
											} 
										/>
									</div>
								</div>
							</div>
						
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Período Final:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={periodoFinal} 
											disableClock={true}
											onChange={data => {
												const criterioPesquisa = this.state.criterioPesquisa;
												criterioPesquisa['periodoFinal'] = data;
												this.setState({criterioPesquisa})}
											} 

										/>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/solicitacao-relatorio-auditoria/new"> 
									<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Nova</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">API</th>
											<th scope="col">Solicitante</th>
											<th scope="col">Data da Solicitação</th>
											<th scope="col">CPF's/CNPJ's</th>
											<th scope="col">Órgão Consumidor</th>
											<th scope="col">Situação</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((solicitacao, index) =>
													<tr key={index} >
														<td>{solicitacao.nomeFornecedora}</td>
														<td>{solicitacao.nomeSolicitante}</td>
														<td>{formataDataHora(solicitacao.dataSolicitacao)}</td>
														<td>{solicitacao.listaCpfCnpjs.join(", ")}</td>
														<td>{solicitacao.nomeOrgaoConsumidor}</td>
														<td>{solicitacao.nomeSituacao}</td>
														<td className="text-center">
															<div className="actions">
																{ solicitacao.situacao === 'A' && solicitacao.cpfSolicitante === cpfUsuario &&
																	<Link to={`/solicitacao-relatorio-auditoria/edit/${solicitacao.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados} title="Editar a solicitação de relatório de auditoria">
																			<span className="sr-only">Editar</span>
																			<i className="fas fa-edit"></i>
																		</button>
																	</Link>
																}

																{ solicitacao.situacao === 'A'  && solicitacao.cpfSolicitante === cpfUsuario &&
																	<button type="button" onClick={() => this.confirmaOperacao(solicitacao)} disabled={!this.state.botoesHabilitados}  title="Excluir a solicitação de relatório de auditoria">
																		<span className="sr-only">Desativar</span>
																		<i className="fas fa-trash" id="danger"></i>
																	</button>
																}

																{ solicitacao.situacao === 'C' && solicitacao.cpfSolicitante === cpfUsuario &&
																	<button type="button" disabled={!this.state.botoesHabilitados} title="Download do arquivo" onClick={() => this.mostraRelatorioAuditoria(solicitacao.id)}>
																		<span className="sr-only">Download</span>
																		<i className="fas fa-download"></i>
																	</button>
																}
																
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhuma solicitação encontrada com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa[nome] = valor;
			this.setState({ criterioPesquisa });
		}

		handleFornecedoraChange(evento){
			const fornecedora =  evento.target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa['fornecedora'] = fornecedora;
			criterioPesquisa['orgaoConsumidor'] = "";

			this.setState({
				criterioPesquisa,
				orgaos: [],

			});
			this.carregaOrgaosConsumidoresFornecedora(fornecedora);
		}

		carregaOrgaosConsumidoresFornecedora(idFornecedora){
			if(idFornecedora && idFornecedora !== "0"){
				api.get(`/api/orgao/consumidores/fornecedora/${idFornecedora}`)
				.then(response => { 
					this.setState(
						{
							orgaos: response.data
						}
					);
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os órgãos consumidores da API " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento dos órgãos consumidores da API.")
				});
			}
		}


		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){

			const {criterioPesquisa} = this.state;
			criterioPesquisa['pagina'] = pagina;
			const url = '/api/solicitacao-relatorio-auditoria/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as solicitações de relatório de auditoria. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}


		mostraRelatorioAuditoria(id){
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			const options = {
				method: 'get',
				url: `/api/solicitacao-relatorio-auditoria/${id}/download`,
				responseType: 'arraybuffer'
			}

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]),'RelatorioAuditoria.zip' );
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de auditoria.';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.setState(
				{
					criterioPesquisa: {
						fornecedora: '',
						orgaoConsumidor: '',
						periodoInicial: '',
						periodoFinal: '',
						cpfSolicitante: '',
					},
					resultadoPesquisa: {},
					orgaos: [],
					pesquisaExecutada: false,
					exibeModalExcluir: false,
					botoesHabilitados : true,
				}
			)
		}

		excluirSolicitacao (solicitacao){
			this.setState({botoesHabilitados:false});
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			window.scrollTo(0, 0)

			api.delete(`/api/solicitacao-relatorio-auditoria/${solicitacao.id}`)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					this.props.exibeMensagemSucesso('Solicitação de relatório de auditoria excluída com sucesso');
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível excluir a solicitação de relatório de auditoria", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		handleOkModais () {
			this.excluirSolicitacao(this.state.solicitacaoAlvo);
			this.fechaModais();
		}

		confirmaOperacao(solicitacao) {
			this.setState(
				{
					exibeModalExcluir: true,
					solicitacaoAlvo: solicitacao,
				}
			);
		}

		fechaModais () {
			this.setState(
				{
					exibeModalExcluir:false,
				}
			);
		}
	}

) // redux
); // router
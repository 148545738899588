import axios from "axios";
import { sessionStorageLogout, getSessionStorage } from "../utils/sessionStoreUtil";
import config from '../config';

const api = axios.create({
  baseURL: ""
});

api.interceptors.request.use(async config => {
  const storage = getSessionStorage();
  if(storage) {
    const token = JSON.parse(storage).token;
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
  }
  return config;
});

api.interceptors.response.use((response) => {
  return response;
}, function (error) {
  const status = error.response.status;

  if (status === 401 || status === 403) {
      console.log('Não permitido. Redirecionando para tela inicial ...');
      sessionStorageLogout(); 
      window.location.href = config.govbrUrl + '/logout?post_logout_redirect_uri=' + config.govbrLougoutUrl;
  }
 
  return Promise.reject(error.response);
});


export default api;
const CD_DIRIGENTE_TI = 'DT';
const DIRIGENTE_TI = {
	codigo: CD_DIRIGENTE_TI,
	nome: 'Dirigente de TI'
}

const CD_RESPONSÁVEL_TECNICO = 'RT';
const RESPONSAVEL_TECNICO = {
	codigo: CD_RESPONSÁVEL_TECNICO,
	nome: 'Responsável Técnico'
}

export {CD_DIRIGENTE_TI, CD_RESPONSÁVEL_TECNICO, DIRIGENTE_TI, RESPONSAVEL_TECNICO}

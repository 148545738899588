import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './Application.css';
import 'react-picky/dist/picky.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando }
const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user
	}
}

export const ApplicationEdit =

withRouter(

// redux
connect(
	(mapStateToProps),
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				aplicacaoConsumidora: {
					id: undefined,
					nome: "",
					descricao: "",
					orgaos: [],
					orgao: 0,
				},
				carregandoOrgaos: false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Aplicações</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				api.get('/api/aplicacao-consumidora/' + id)
				.then(response => { 
					const aplicacaoConsumidora = response.data;
					this.setState(
						{
							aplicacaoConsumidora,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar a aplicação consumidora " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			if(this.props.user.isAdministrador === true){
				this.setState({carregandoOrgaos : true});
				api.get('/api/orgao/perfil-usuario?ativos=true')
				.then(response => { this.setState({orgaos: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar os órgãos " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				})
				.finally(() => {
					this.setState({carregandoOrgaos : false});
				});
			}
		}

		renderForm() {
			return(
				<div className="container-fluid">
					<div className="br-form">
						{this.props.user.isAdministrador === true &&	
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="orgao">Órgão:</label>						
										</div>
										<div className="input">
											<select id="orgao" name="orgao" onChange={this.handleInputChange} value={this.state.aplicacaoConsumidora.orgao}
												disabled={this.state.aplicacaoConsumidora.id}>
												<option value="">Selecione</option>
												{
													this.state.orgaos?.map(orgao =>
														<option 
															key={orgao.id} 
															value={orgao.id}>
															{orgao.nome}
														</option>
												)}
											</select>
											{this.state.carregandoOrgaos === true && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							</div>
						}

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Nome:</label>
										<input type="text" name="nome" 
											value={this.state.aplicacaoConsumidora.nome}  
											onChange={this.handleInputChange} 
											maxLength="15"/>
									</div>
								</div>
							</div>	
						
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Descrição:</label>
										<input type="text" name="descricao" 
											value={this.state.aplicacaoConsumidora.descricao}  
											onChange={this.handleInputChange} 
											maxLength="255"/>
									</div>
								</div>
							</div>	
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.aplicacaoConsumidora.id ? 'Alterar' : 'Incluir'}</button>

								{this.state.aplicacaoConsumidora.id && 
									<Link to={`/application/access/${this.state.aplicacaoConsumidora.id}`}> 
										<div className="back-button">
											<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Adesões</button>
										</div>
									</Link>
								}

								{this.state.aplicacaoConsumidora.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Nova Aplicação</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const aplicacaoConsumidora = this.state.aplicacaoConsumidora;
			aplicacaoConsumidora[nome] = valor;
			this.setState({ aplicacaoConsumidora });
		}

		novo(e){
			const rotaParaNovo = "/application/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const aplicacaoConsumidora = this.state.aplicacaoConsumidora;
			const method = aplicacaoConsumidora.id ? 'put' : 'post';
			let url = "/api/aplicacao-consumidora";
			if(aplicacaoConsumidora.id){
				url = url + '/' + aplicacaoConsumidora.id;
			}
			const options = {
				method,
				url,
				"data" : aplicacaoConsumidora,
			}

			api(options)
				.then(response => {
					const mensagem = `Aplicação ${this.state.aplicacaoConsumidora.id ? 'alterada' : 'incluída'} com sucesso!`;
					this.setState({ 
						aplicacaoConsumidora: response.data ,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.aplicacaoConsumidora.id ? 'alterar' : 'incluir'} a aplicação.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});   

		}

		validaFormulario(){
			let mensagens = [];
			const {orgao, nome, descricao } = this.state.aplicacaoConsumidora;

			if(this.props.user.isAdministrador && !orgao){
				mensagens.push({mensagem: "Órgão é obrigatório."})
			}

			if(!nome || nome.trim().length === 0 ){
				mensagens.push({mensagem: "Nome é obrigatório."})
			}

			if(!descricao || descricao.trim().length === 0 ){
				mensagens.push({mensagem: "Descrição é obrigatório."})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router
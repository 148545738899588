import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './RelatorioAuditoriaGenerico.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { Resultado } from "./Resultado";
import { baixarArquivo } from "../../utils/downloadUtil";

registerLocale("pt_br", pt_br);

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const RelatorioAuditoriaGenerico =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				orgaos: [],
				orgao: '',
				consumidoras: [],
				consumidorasOrgao: [],
				consumidora: '',
				fornecedoras: [],
				fornecedora: '',
				exibeColunaCpfsCnpj: false,
				carregarCodigosBilhetados: false,
				carregandoConsumidoras: false,
				carregandoFornecedoras: false,
				relatorioAuditoriaGenerico: {},
				periodoInicial: '',
				periodoFinal: '',
				tiposVisao: [],
				tipoVisao: 'C',
				bilhetados: true,
				codigosBilhetados: [],
				pesquisaExecutada : false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleAPIChange = this.handleAPIChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.carregarConsumidoras = this.carregarConsumidoras.bind(this);
			this.carregarFornecedoras = this.carregarFornecedoras.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisa = this.executaPesquisa.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.mostraRelatorioAuditoriaGenericoPDF = this.mostraRelatorioAuditoriaGenericoPDF.bind(this);
			this.mostraRelatorioAuditoriaGenericoCSV = this.mostraRelatorioAuditoriaGenericoCSV.bind(this);
			this.handleTipoVisaoChange = this.handleTipoVisaoChange.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Relatório de auditoria</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			if(this.props.user.isAdministrador === true || this.props.user.isMantenedor === true || this.props.user.isFornecedor === true){
				api.get('/api/orgao/consumidores/perfil-usuario')
				.then(response => { this.setState({orgaos: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar órgãos consumidores " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}
			if(this.props.user.isCliente === true){
				api.get('/api/aplicacao-consumidora/orgao')
				.then(response => { 
					const consumidoras = response.data;
					this.setState(
						{
							consumidoras,
							consumidorasOrgao: [...consumidoras]
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as aplicações do órgão " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			if(this.props.user.isFornecedor === true){
				api.get('/api/consumo/tipos-visao-relatorio')
				.then(response => { this.setState({tiposVisao: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de visão para o relatório " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			this.carregarFornecedoras(this.state.tipoVisao, this.state.orgao);
		}

		renderForm() {
			const { relatorioAuditoriaGenerico, pesquisaExecutada, exibeColunaCpfsCnpj } = this.state;
			const user = this.props.user;
			const tipoVisao = this.state.tipoVisao;
			const exibeComboOrgao = user && (user.isAdministrador === true || user.isMantenedor === true || (user.isFornecedor === true && tipoVisao === 'F'));
			const exibeComboAplicacao = ((user.isCliente === true && tipoVisao === 'C') || ((user.isAdministrador === true || user.isMantenedor === true || (user.isFornecedor === true && tipoVisao === 'F')) && this.state.orgao !== ''));
			const exibeBotoes = relatorioAuditoriaGenerico.content && relatorioAuditoriaGenerico.content.length > 0;
			const codigosBilhetados = this.state.codigosBilhetados.length > 0 ? "(" + this.state.codigosBilhetados.toString() + ")" : "";

			return(
				<div className="container-fluid">
					<div className="br-form">
						{user && user.isFornecedor === true &&
							<div className="row">
								<div className="col-sd">
									<div className="field">
										<div className="input">
											<label htmlFor="tipoVisao">Mostrar:</label>
											<select name="tipoVisao" onChange={this.handleTipoVisaoChange} value={this.state.tipoVisao}>
												{
													this.state.tiposVisao.map(tipoVisao =>
														<option 
															key={tipoVisao.codigo} 
															value={tipoVisao.codigo}>
															{tipoVisao.nome}
														</option>
												)}
											</select>
										</div>
									</div>
								</div>
							</div>
						}	
					<div className="row">
							{exibeComboOrgao &&
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="consumidora">Órgao Consumidor:</label>
											<select name="orgao" onChange={this.handleOrgaoChange} value={this.state.orgao}>
											<option value="">Selecione</option>
												{
													this.state.orgaos.map(orgao =>
														<option 
															key={orgao.id} 
															value={orgao.id}>
															{orgao.nome}
														</option>
												)}
											</select>
										</div>
									</div>
								</div>
							}
							{exibeComboAplicacao &&
							<div className="col-md">
								<div className="field">
									<div className="input">
										
										<label htmlFor="consumidora">Aplicação:</label>
										{this.state.consumidoras.length > 0 &&
											<select name="consumidora" onChange={this.handleInputChange} value={this.state.consumidora}>
												<option value="">Todas</option>
												{
													this.state.consumidoras.map(consumidora =>
														<option 
															key={consumidora.id} 
															value={consumidora.id}>
															{consumidora.nome}
														</option>
												)}
											</select>
										}
										{this.state.carregandoConsumidoras === true && 
										<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}

										{this.state.carregandoConsumidoras === false && this.state.consumidoras.length === 0 && 'Não há aplicações para serem selecionadas.'}
									</div>
								</div>
							</div>
							}

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{this.state.fornecedoras.length > 0 &&
											<select name="fornecedora" onChange={this.handleAPIChange} value={this.state.fornecedora}>
											<option value="">Selecione</option>											
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{this.state.carregandoFornecedoras === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}

										{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'Não há APIs para serem selecionadas.'}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Período Inicial:</label>
										<DatePicker locale="pt_br" showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" selected={this.state.periodoInicial} onChange={data => {this.setState({periodoInicial: data})}} />
									</div>
								</div>
							</div>
						
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Período Final:</label>
										<DatePicker locale="pt_br" showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" selected={this.state.periodoFinal} onChange={data => {this.setState({periodoFinal: data})}} />
									</div>
								</div>
							</div>
						
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Códigos de Retorno:</label>
										<select name="bilhetados" onChange={this.handleInputChange} value={this.state.bilhetados}>
											<option value="">Todos</option>
											<option value={true}>Bilhetados {codigosBilhetados}</option>											
											<option value={false}>Não Bilhetados</option>											
										</select>
										{this.state.carregandoCodigosBilhetados === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioAuditoriaGenericoPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioAuditoriaGenericoCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
						<Resultado resultadoPesquisa={relatorioAuditoriaGenerico} pesquisaExecutada={pesquisaExecutada} exibeColunaCpfsCnpj={exibeColunaCpfsCnpj} onChangePage={this.executaPesquisa}/>
					</div>
				</div>
				
			);
		}

		validaFormulario(){
			let mensagens = [];
			const {orgao, fornecedora, periodoInicial, periodoFinal, tipoVisao } = this.state;
			const user = this.props.user;

			if(user && (user.isAdministrador === true || user.isMantenedor === true || (user.isFornecedor === true && tipoVisao === 'F')) && !orgao){
				mensagens.push({mensagem: "Órgão é obrigatório."})
			}
			if(!fornecedora){
				mensagens.push({mensagem: "API é obrigatório."})
			}

			if(!periodoInicial){
				mensagens.push({mensagem: "Período Inicial é obrigatório."})
			}
			if(!periodoFinal){
				mensagens.push({mensagem: "Período Final é obrigatório."})
			}

			if(periodoInicial && periodoFinal){
				const milisegundos = periodoFinal.getTime() - periodoInicial.getTime();
				if(milisegundos < 0){
					mensagens.push({mensagem: "O período inicial deve ser menor ou igual ao período final"});
				}
				if(milisegundos > 24*60*60*1000){
					mensagens.push({mensagem: "O período deve ser menor ou igual a 1 dia."});
				}
			}
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleTipoVisaoChange(evento){
			const tipoVisao =  evento.target.value;
			const orgao = "";
			let consumidoras = [];
			let fornecedoras = [];
			if(tipoVisao === 'C') {
				consumidoras = [...this.state.consumidorasOrgao];
			}

			this.setState({
				tipoVisao,
				consumidoras,
				consumidora: "",
				fornecedoras,
				fornecedora: "",
				orgao,
			});

			this.carregarFornecedoras(tipoVisao, orgao);
		}

		handleAPIChange(evento){
			const fornecedora =  evento.target.value;

			this.setState({
				fornecedora,
				bilhetados: true,
				codigosBilhetados: [],
			});
			
			this.carregarCodigosBilhetados(fornecedora);
		}

		carregarCodigosBilhetados(fornecedora) {
			if(!fornecedora) {
				return;
			}

			this.setState(
				{
					carregandoCodigosBilhetados : true
				}
			);

			api.get(`/api/aplicacao-fornecedora/codigos-sucesso/${fornecedora}`)
			.then(response => { 
				const codigosBilhetados = response.data;
				this.setState(
					{
						codigosBilhetados,
					}
				)
			 })
			.catch(erro => {
				console.log("Não foi possível recuperar as códigos bilhetados " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			})
			.finally(() => {
				this.setState({carregandoCodigosBilhetados : false});
			});
		}

		handleOrgaoChange(evento){
			const idOrgao =  evento.target.value;
			const idTipoVisao = this.state.tipoVisao;

			this.setState({
				consumidora: "",
				fornecedora: "",
				orgao: idOrgao,
			});
			
			this.carregarConsumidoras(idOrgao);
			this.carregarFornecedoras(idTipoVisao, idOrgao);
		}

		carregarConsumidoras(idOrgao) {
			if(idOrgao && idOrgao !== ""){
				this.setState({carregandoConsumidoras : true});
				api.get(`/api/aplicacao-consumidora/orgao/${idOrgao}`)
				.then(response => { 
					this.setState(
						{
							consumidoras: response.data,
							carregandoConsumidoras : false
						}
					);
				})
				.catch(erro => {
					this.setState({carregandoConsumidoras : false});
					console.log("Não foi possível recuperar as aplicações associadas ao órgão " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento das aplicações do órgão.")
				});
			}
		}

		carregarFornecedoras(idTipoVisao, idOrgao) {
			this.setState(
				{
					fornecedora: "",
					carregandoFornecedoras : true
				}
			);
			api.get(`/api/aplicacao-fornecedora/tipo-visao/${idTipoVisao}/orgao-consumidor?idOrgaoConsumidor=${idOrgao}`)
			.then(response => { 
				const fornecedoras = response.data;
				this.setState(
					{
						fornecedoras,
						carregandoFornecedoras : false,
					}
				)
			 })
			.catch(erro => {
				this.setState({carregandoFornecedoras : false});
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		pesquisar (e){
			this.props.limpaMensagem();
			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}
			this.executaPesquisa(0);
		}

		executaPesquisa(pagina){
			const {consumidora, fornecedora, orgao, periodoInicial, periodoFinal, tipoVisao, bilhetados } = this.state;
			const criterioPesquisa = this.getCriteriosPesquisa(consumidora, fornecedora, orgao, periodoInicial, periodoFinal, tipoVisao, bilhetados);
			const url = '/api/auditoria/generico';
			criterioPesquisa['pagina'] = pagina;
			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}
			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					const relatorioAuditoriaGenerico = response.data; 
					const exibeColunaCpfsCnpj = relatorioAuditoriaGenerico.content.length > 0 && relatorioAuditoriaGenerico.content[0].cpfsCnpjConsultados;

					this.setState({
						relatorioAuditoriaGenerico,
						exibeColunaCpfsCnpj,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar o relatório de auditoria genérico. ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		getCriteriosPesquisa(consumidora, fornecedora, orgao, periodoInicial, periodoFinal, tipoVisao, bilhetados){
			return {consumidora, fornecedora, orgao, periodoInicial, periodoFinal, tipoVisao, bilhetados};
		}

		mostraRelatorioAuditoriaGenericoPDF(e){
			this.mostraRelatorioAuditoriaGenerico(e, 'pdf')
		}

		mostraRelatorioAuditoriaGenericoCSV(e){
			this.mostraRelatorioAuditoriaGenerico(e, 'csv')
		}

		mostraRelatorioAuditoriaGenerico(e, tipo){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {consumidora, fornecedora, orgao, periodoInicial, periodoFinal, tipoVisao, bilhetados } = this.state;
			const criterioPesquisa = this.getCriteriosPesquisa(consumidora, fornecedora, orgao, periodoInicial, periodoFinal, tipoVisao, bilhetados);
			const url = `/api/auditoria/generico/${tipo}`;
			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioAuditoriaGenerico.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de auditoria genérico';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			const tipoVisao = 'C';
			const orgao = '';
			this.setState({
				orgao,
				consumidoras: this.props.user.isAdministrador || this.props.user.isMantenedor ? [] : [...this.state.consumidorasOrgao],
				consumidora: '',
				fornecedora: '',
				periodoInicial: '',
				periodoFinal: '',
				tipoVisao,
				exibeColunaCpfsCnpj: '',
				codigosBilhetados: [],
				bilhetados: true,
				relatorioAuditoriaGenerico: {},
				pesquisaExecutada : false,
			});
			this.carregarFornecedoras(tipoVisao, orgao);
		}
	}

) // redux
); // router
import { parseCidr } from "cidr-tools";
import { stringifyIp } from "ip-bigint";

/* global BigInt */

const getRangeIp = function (cidr) {
    const parse = parseCidr(cidr);
    const ipInicial = stringifyIp({number: parse.start, version: parse.version});
    const ipFinal = stringifyIp({number: parse.end, version: parse.version});
    const quantidade = (parse.end - parse.start + BigInt(1)).toString();

    return {ipInicial, ipFinal, quantidade}
}

export {getRangeIp}
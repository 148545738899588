import React, { Component } from "react";
import api from "../../services/api";

export const SolicitacaoAdesaoHistory =

class extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			historico: [],
			carregandoHistorico: false,
			pesquisaExecutada: false,
		};
	}

	render() {
		const { historico, carregandoHistorico, pesquisaExecutada} = this.state;
		return (
			<div>
				{(historico.length > 0) &&	
					<table className="history-table">
						<tbody>
							{ historico.map((hist, index) =>
								<tr key={index} >
									<td className="text-right"><b>{hist.nomeAndamento}</b> por <b>{hist.nomeAutor}</b> em <b>{hist.dataHistorico}</b></td>
								</tr>
							)}
						</tbody>						
					</table>
				}
				{(pesquisaExecutada && (historico.length === 0)) &&
					<div>
						Nenhum andamento encontrado para a solicitação de adesão.
					</div>
				}
				{ carregandoHistorico === true  && 
					<button className="br-button is-secondary is-circle is-loading" type="button"/>
				}
			</div>
		);
	}

	componentDidMount(){
		const solicitacao = this.props.solicitacao;
		if(solicitacao) {
			this.setState({
				carregandoHistorico: true,
			})
			
			api.get(`/api/solicitacao-adesao/historico/${solicitacao}`)
				.then(response => { 
					this.setState(
						{
							historico: response.data,
							pesquisaExecutada: true,
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar o histórico da solicitação." + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				}).finally(() => {
					this.setState({carregandoHistorico: false})
				});
		}
	}
	
}

import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import { getUrlRetorno } from "../../utils/itiUtil"
import { base64ToStr } from "../../utils/base64Util";
import qs from 'query-string';
import './Billing.css';
import { PdfSigner } from "../PdfSigner/PdfSigner";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const pathRetorno = "/billing/agreement";

export const BillingAgreement =
withRouter(

// redux
connect(
	(null), 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
                faturamentoOrgao: {},
				prestacaoContasPDF : '',
                nomeArquivo: '',
				botoesHabilitados : true,
				prestacaoContasAssinadaSucesso: false,
			};

			this.assinaPrestacaoContas = this.assinaPrestacaoContas.bind(this);
			this.getState = this.getState.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Atestar Declaração de Consumo</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			const id = this.props.match.params.id;
            if(! id || (isNaN(id))){
                this.props.exibeMensagemErro("Id do Faturamento não informado corretamente");
                return;
            }

			const state = localStorage.getItem('state');

			const urlParams = qs.parse(this.props.location.search);
			const code = urlParams.code;
			const error = urlParams.error;

			if((code || error) && state){
				const objState = JSON.parse(base64ToStr(state));
				this.setState(objState);

				if(!error){
					const body = {
						tipoCertificado: objState.tipoCertificado,
						urlRetorno: getUrlRetorno(`${pathRetorno}/${id}`),
						code
					}
					this.assinaPrestacaoContas(body);
				}
				return;
			} 

			this.carregaFaturamentoOrgao(id);
            this.baixarPrestacaoContasPDF(id)
		}

		renderForm() {
            const {faturamentoOrgao, prestacaoContasPDF, nomeArquivo, botoesHabilitados, prestacaoContasAssinadaSucesso} = this.state;
			return(
                <Fragment>
                    <div className="container-fluid">
						<div className="br-form">
							<PdfSigner pdf={prestacaoContasPDF} 
								nomeArquivo={nomeArquivo} 
								botoesHabilitados={botoesHabilitados} 
								assinaturaHabilitada={(! prestacaoContasAssinadaSucesso && faturamentoOrgao.situacao === 'P')} 
								onAssinar={this.assinaPrestacaoContas}
								pathRetorno={`${pathRetorno}/${faturamentoOrgao.id}`}
								stateParent={this.getState}
								/>
						</div>
		            </div>
		        </Fragment>
			);
		}

		carregaFaturamentoOrgao(id){
			api.get(`/api/prestacao-contas/${id}`)
				.then(response => { 
					this.setState({faturamentoOrgao: response.data}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar a declaração de consumo " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		baixarPrestacaoContasPDF(id){
            const options = {
				method: 'post',
				url: `/api/prestacao-contas/relatorio/pdf/${id}`,
				responseType: 'arraybuffer'
			}

			this.props.limpaMensagem();
			this.props.mostraAguardando();
			this.setState({ botoesHabilitados : false });

			api(options)
				.then((response) => {
                    let nomeArquivo = 'RelatorioPrestacaoContas.pdf';

					const contentDisposition = response['headers']['content-disposition'];
					if(contentDisposition) {
						nomeArquivo = contentDisposition.split("filename=")[1];
					}

					const prestacaoContasPDF = Buffer.from(response.data).toString('base64');

                    this.setState({
                        prestacaoContasPDF, 
                        nomeArquivo,
                    })
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de declaração de consumo';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		getState() {
			const stateCopy = { ...this.state };
			return stateCopy;
		}

		assinaPrestacaoContas(body) {
			this.setState({botoesHabilitados:false});
			this.props.mostraAguardando();

			localStorage.removeItem('state');
			body.id = this.props.match.params.id;;

			api.post('/api/prestacao-contas/assinar', body)
				.then(response => {
					this.setState({ prestacaoContasAssinadaSucesso: true });
					this.props.exibeMensagemSucesso("Declaração de consumo assinada com sucesso.");
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível assinar a declaração de consumo:", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}
    }
) // redux
); // router
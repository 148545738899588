import React, { Component } from "react";

import './RelatorioConsumo.css';


export const ConsumoDiario =

	class extends Component {

		render() {
			const { resultadoPesquisa, pesquisaExecutada } = this.props;
			const {listaConsumo, consumoPorConsumidorFornecedor, consumoPorFornecedor} = resultadoPesquisa;
			const numberFormater = new Intl.NumberFormat('pt-BR', {useGrouping: true });
			const dateFormater = new Intl.DateTimeFormat('pt-BR');
			return (
				<div>
					{(listaConsumo && listaConsumo.length > 0) &&	
						<div className="container-fluid">	
							<h2>Consumo diário:</h2>
							<div className="br-table is-collapsible">
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">Data</th>
											<th scope="col">Aplicação</th>
											<th scope="col">API</th>
											<th scope="col" className="text-right">Consumo</th>
											</tr>
										</thead>
										
										<tbody>
											{ listaConsumo.map((consumo, index) =>
													<tr key={index} >
														<td>{dateFormater.format(consumo.data)}</td>
														<td>{consumo.nomeConsumidora}</td>
														<td>{consumo.nomeFornecedora}</td>
														<td className="text-right">{numberFormater.format(consumo.consumo)}</td>
													</tr>
												)}
										</tbody>
									</table>
									
								</div>
							</div>
						</div>
					}

					{(consumoPorConsumidorFornecedor && consumoPorConsumidorFornecedor.length > 0) &&	
						<div className="container-fluid">	
							<br/><br/>
							<h2>Consumo no período por aplicação:</h2>
							<div className="br-table is-collapsible">
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">Aplicação</th>
											<th scope="col">API</th>
											<th scope="col" className="text-right">Consumo</th>
											</tr>
										</thead>
										
										<tbody>
											{ consumoPorConsumidorFornecedor.map((consumo, index) =>
													<tr key={index} >
														<td>{consumo.nomeConsumidora}</td>
														<td>{consumo.nomeFornecedora}</td>
														<td className="text-right">{numberFormater.format(consumo.consumo)}</td>
													</tr>
												)}
										</tbody>
									</table>
									
								</div>
							</div>
						</div>
					}

					{(consumoPorFornecedor && consumoPorFornecedor.length > 0) &&	
						<div className="container-fluid">	
							<br/><br/>
							<h2>Consumo total no período por API:</h2>
							<div className="br-table is-collapsible">
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">API</th>
											<th scope="col" className="text-right">Consumo</th>
											</tr>
										</thead>
										
										<tbody>
											{ consumoPorFornecedor.map((consumo, index) =>
													<tr key={index} >
														<td>{consumo.nomeFornecedora}</td>
														<td className="text-right">{numberFormater.format(consumo.consumo)}</td>
													</tr>
												)}
										</tbody>
									</table>
									
								</div>
							</div>
						</div>
					}

					{(pesquisaExecutada && (! listaConsumo || listaConsumo.length === 0)) &&
						<div>
							Nenhum consumo encontrado com os critérios informados.
						</div>
					}
				</div>
			);
		}
		
	}


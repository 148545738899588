import React, { Component, Fragment} from "react";
import { connect } from 'react-redux';
import api from "../../services/api";
import { exibeMensagemSucesso, exibeMensagemErro, mostraAguardando, escondeAguardando } from "../../providers/actions";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { FileUpload } from "../FileUpload/FileUpload";

import { baixarArquivo } from "../../utils/downloadUtil";
import {getMessagemErro} from "../../utils/erro";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

const fileTypes = ["pdf"];

export const SolicitacaoAdesaoAttachments =

connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {
		
		constructor(props){
			super(props);
			this.state = {
				anexosSolicitacao: [],
				anexosAPI: [],
				exibeModalExcluir: false,
				carregandoAnexosSolicitacao: false,
				carregandoAnexosAPI: false,
			};
		}

		render() {
			const { user, solicitacao, habilitaEdicaoAnexo } = this.props;
			const { anexosSolicitacao, anexosAPI, carregandoAnexosSolicitacao, carregandoAnexosAPI } = this.state;

			return (
				<div>
					<ConfirmModal titulo="Confirmação" 
							visible={this.state.exibeModalExcluir}
							handleOk={() => this.excluiAnexo()}
							handleCancel={() => this.fechaModal()}
							message="Deseja realmente remover o anexo?"/>
					{habilitaEdicaoAnexo && solicitacao.alteracaoAnexosPermitida && 
						<Fragment>
							<fieldset className="attachments-fieldset">
								<legend>{Number(user.idOrgao) === solicitacao.orgao && anexosAPI.length > 0 ? 'Baixe os modelos dos anexos obrigatórios, preencha e anexe à solicitação:' : 'Selecione os arquivos que deseja anexar à solicitação:'}</legend>
							
								<table className="attachments-table">
									<thead>
										<tr>
											<th scope="col">Tipo</th>						
											<th scope="col">Modelo</th>
											<th scope="col" style={{width: "45%"}}>Anexar</th>
										</tr>
									</thead>
									<tbody>
										{ Number(user.idOrgao) === solicitacao.orgao &&
											<Fragment>
												{ anexosAPI.map((anexo) =>
													<tr key={anexo.id} >
														<td>{anexo.nomeTipo}</td>
														<td className="action-td">
															{anexo.nomeArquivo}
															<button type="button" title="Download do arquivo" onClick={() => this.downloadModeloAnexo(anexo)}>
																<span className="sr-only">Download</span>
																<i className="fas fa-download"></i>
															</button>
														</td>
														<td>
															<FileUpload onFileChange={(files) => this.handleFileChange(files, anexo.tipo)} fileTypes={fileTypes} multiple={false}/>
														</td>
													</tr>
												)}

												{ carregandoAnexosAPI === true  && 
													<button className="br-button is-secondary is-circle is-loading" type="button"/>
												}
											</Fragment>
										}
										<tr key={'outro'} >
											<td>Outro (opcional)</td>
												<td/>
												<td>
													<FileUpload onFileChange={this.handleFileChange} fileTypes={fileTypes} multiple={false}/>
												</td>		
										</tr>
									</tbody>
								</table>
							</fieldset>
							<br/>
						</Fragment>
					}
					{(anexosSolicitacao.length > 0) &&	
						<fieldset className="attachments-fieldset">
							{habilitaEdicaoAnexo && solicitacao.alteracaoAnexosPermitida &&
								<legend>Arquivos anexados à solicitação:</legend>
							}

							<table className="attachments-table">	
								<thead>
									<tr>
										<th scope="col">Tipo</th>						
										<th scope="col">Arquivo</th>
									</tr>
								</thead>					
								<tbody>
									{ anexosSolicitacao.map((anexo) =>
										<tr key={anexo.id} >
											<td>{anexo.nomeTipoAnexoAplicacaoFornecedora ? anexo.nomeTipoAnexoAplicacaoFornecedora : 'Outro'}</td>
											<td className="action-td">
												{anexo.nomeArquivo}
												<button type="button" title="Download do arquivo" onClick={() => this.mostraAnexo(anexo.id, anexo.nomeArquivo)}>
													<span className="sr-only">Download</span>
													<i className="fas fa-download"></i>
												</button>

												{habilitaEdicaoAnexo && solicitacao.alteracaoAnexosPermitida && anexo.exclusaoPermitida && 
													<button type="button" className="delete-file"
														title="Excluir o arquivo"
														onClick={() => this.confirmarExclusao(anexo.id)}>
														<span className="sr-only">Excluir</span>
														<i className="fas fa-trash" id="danger"></i>
													</button>
												}
											</td>
										</tr>
									)}
								</tbody>						
							</table>
						</fieldset>
					}

					{(carregandoAnexosSolicitacao === false && (anexosSolicitacao.length === 0)) &&
						<div>
							Nenhum anexo cadastrado na solicitação de adesão.
						</div>
					}
					{ carregandoAnexosSolicitacao === true  && 
						<button className="br-button is-secondary is-circle is-loading" type="button"/>
					}
				</div>
			);
		}

		componentDidMount(){
			this.carregaAnexosSolicitacao();
			if(this.props.habilitaEdicaoAnexo === true) {
				this.carregaAnexosAPI();
			}
		}

		carregaAnexosSolicitacao() {
			const { solicitacao } = this.props;
			const idSolicitacao = solicitacao.id;
			this.setState({
				carregandoAnexosSolicitacao: true,
			})
			api.get(`/api/solicitacao-adesao/anexo/${idSolicitacao}`)
				.then(response => { 
					this.setState(
						{
							anexosSolicitacao: response.data,
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os anexos da solicitação." + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento dos anexos da solicitação.")
				}).finally(() => {
					this.setState({carregandoAnexosSolicitacao: false})
				});
		}

		carregaAnexosAPI() {
			const { solicitacao } = this.props;
			const { fornecedora, tipo} = solicitacao;
			this.setState({
				carregandoAnexosAPI: true,
			})
			api.get(`/api/aplicacao-fornecedora/${fornecedora}/anexo?tipo=${tipo}`)
				.then(response => { 
					this.setState(
						{
							anexosAPI: response.data,
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os anexos da API." + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento dos anexos da API.")
				}).finally(() => {
					this.setState({carregandoAnexosAPI: false})
				});
		}

		handleFileChange = (files, idTipoAnexoAplicacaoFornecedora) => {
			const { solicitacao } = this.props;
			const idSolicitacao = solicitacao.id;

			const data = new FormData();
			data.append('file', files[0]);
			if(idTipoAnexoAplicacaoFornecedora) {
				data.append('idTipoAnexoAplicacaoFornecedora', idTipoAnexoAplicacaoFornecedora);
			}

			api.post(`/api/solicitacao-adesao/anexo/${idSolicitacao}/upload`, data, {
				headers: { 
					'Content-Type': 'multipart/form-data',
				}
			}) 
			.then((response) => {
					return response.data
			})
			.catch(erro => {
				const msg = getMessagemErro("Erro durante o upload dos arquivos.", erro);
				console.log(JSON.stringify(msg));
				this.props.exibeMensagemErro(msg.mensagem, msg.erros);
			})
			.then(() => {
				this.carregaAnexosSolicitacao();
			})
		}

		confirmarExclusao(id) {
			this.setState(
				{
					exibeModalExcluir: true,
					anexoAlvo: id,
				}
			);
		}

		excluiAnexo(){
			const {anexoAlvo} = this.state;
			api.delete(`/api/solicitacao-adesao/anexo/${anexoAlvo}`)
				.then(() => { 
					this.carregaAnexosSolicitacao();
				})
				.catch(erro => {
					const mensagemInicial = "Não foi possível excluir o anexo da solicitação.";
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
			
			this.fechaModal();
		}

		fechaModal () {
			this.setState(
				{
					exibeModalExcluir:false,
				}
			);
		}

		mostraAnexo(idAnexo, nomeAnexo){
			this.props.mostraAguardando();

			const options = {
				method: 'get',
				url: `/api/solicitacao-adesao/anexo/${idAnexo}/download`,
				responseType: 'arraybuffer'
			}

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), nomeAnexo );
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o anexo.';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		downloadModeloAnexo(modelo){
			this.props.mostraAguardando();

			const options = {
				method: 'get',
				url: `/api/aplicacao-fornecedora/anexo/${modelo.id}/download`,
				responseType: 'arraybuffer'
			}

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), modelo.nomeArquivo);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o modelo.';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}
		
	}
	
);
import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Pagination } from "../Pagination/Pagination";
import api from "../../services/api";
import './Plan.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const PlanList =

// router
withRouter(
// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {
				resultadoPesquisa: [],
				fornecedoras: [],
				idAplicacaoFornecedora: "",
				descricao: "",
				limite: "",
				situacoes: [],
				situacao: "A",
				pesquisaExecutada: false,
				exibeModalAtivar: false,
				exibeModalInativar: false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);

			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.fechaModais = this.fechaModais.bind(this);
			this.handleOkModais = this.handleOkModais.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Planos de Consumo</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			api.get('/api/aplicacao-fornecedora/gestor')
			.then(response => { this.setState({fornecedoras: response.data}) })
			.catch(erro => {
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});

			api.get('/api/plano/situacoes')
			.then(response => { this.setState({situacoes: response.data}) })
			.catch(erro => {
				console.log("Não foi possível recuperar as situacções dos planos " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		renderForm() {
			const { resultadoPesquisa, pesquisaExecutada } = this.state;
			const { content } = resultadoPesquisa;
			const numberFormater = new Intl.NumberFormat('pt-BR', {useGrouping: true });

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalAtivar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente habilitar o plano de consumo?"/>

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalInativar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente desabilitar o plano de consumo?"/>

					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
									<label>API:</label>
										<select name="idAplicacaoFornecedora" onChange={this.handleInputChange} value={this.state.idAplicacaoFornecedora}>
											<option value="">Todas</option>
												{
													this.state.fornecedoras.map(fornecedora =>
														<option 
															key={fornecedora.id} 
															value={fornecedora.id}>
															{fornecedora.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="descricao">Nome:</label>
										<input type="text" name="descricao" 
											value={this.state.descricao}  
											onChange={this.handleInputChange} 
											maxLength="50"/>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="limite">Limite:</label>
										<input type="number" name="limite" 
											value={this.state.limite}  
											onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
									<label>Situação:</label>
										<select name="situacao" onChange={this.handleInputChange} value={this.state.situacao}>
											<option value="">Todas</option>
												{
													this.state.situacoes.map(situacao =>
														<option 
															key={situacao.codigo} 
															value={situacao.codigo}>
															{situacao.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/plan/new"> 
									<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Novo</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">API</th>
											<th scope="col">Nome</th>
											<th scope="col" className="text-right">Limite</th>
											<th scope="col">Situação</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((plano, index) =>
													<tr key={index} >
														<td>{plano.nomeFornecedora}</td>
														<td>{plano.descricaoPlano}</td>
														<td className="text-right">{numberFormater.format(plano.limite)}</td>
														<td>{plano.descricaoSituacao}</td>

														<td className="text-center">
															<div className="actions">
																<Link to={`/plan/edit/${plano.idFornecedora}/${plano.idPlano}`}>
																	<button type="button" disabled={!this.state.botoesHabilitados} title="Editar o plano">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																</Link>

																{ plano.situacao === "A" &&
																	<button type="button" onClick={() => this.confirmaOperacao(plano)} disabled={!this.state.botoesHabilitados} title="Desabilitar o plano">
																		<span className="sr-only">Desativar</span>
																		<i className="fas fa-power-off" id="on"></i>
																	</button>
																}
																
																{  plano.situacao === "D" &&
																	<button type="button" onClick={() => this.confirmaOperacao(plano)} disabled={!this.state.botoesHabilitados} title="Habilitar o plano">
																		<span className="sr-only">Ativar</span>
																		<i className="fas fa-power-off" id="off"></i>
																	</button>
																}
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhum plano de consumo encontrado com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){

			const {idAplicacaoFornecedora, descricao, limite, situacao} = this.state;
			const criterioPesquisa = {idAplicacaoFornecedora, descricao, limite, situacao, pagina};
			const url = '/api/plano/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os Planos. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.setState(
				{
					resultadoPesquisa: [],
					idAplicacaoFornecedora: "",
					descricao: "",
					limite: "",
					situacao: "A",
					pesquisaExecutada: false,
					exibeModalAtivar: false,
					exibeModalInativar: false,
					botoesHabilitados : true
				}
			);
		}

		alterarSituacao (plano){
			this.setState({botoesHabilitados:false});
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			window.scrollTo(0, 0)

			const operacao = plano.situacao === "A" ? "desativar" : "ativar";
			api.put(`/api/plano/${operacao}/${plano.idFornecedora}/${plano.idPlano}`)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					let mensagem = plano.situacao === "A" ? 'Plano desabilitado com sucesso!' : 'Plano habilitado com sucesso!';
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${plano.situacao === "A" ? 'desativar' : 'ativar'} o plano.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		handleOkModais () {
			this.alterarSituacao(this.state.planoAlvo);
			this.fechaModais();
		}

		confirmaOperacao(plano) {
			this.setState(
				{
					exibeModalAtivar: plano.situacao === "D",
					exibeModalInativar: plano.situacao === "A",
					planoAlvo: plano,
				}
			);
		}

		fechaModais () {
			this.setState(
				{
					exibeModalAtivar:false,
					exibeModalInativar:false
				}
			);
		}
	}

) // redux
); // router
import React, { Component } from "react";
import { connect } from 'react-redux';
import './Avatar.css';


const mapStateToProps = (state) => {
	return {
		photo: state.usuarioStore.photo,
		user: state.usuarioStore.user
	}
}

export const Avatar =

// redux
connect(
	(mapStateToProps)
)(

class extends Component {
		render() {
			return <React.Fragment>
						<div className="flex-fill mx-3">
						<div className="avatar"  id="avatar-font-title">{this.props.user.name}</div>
							<div className="avatar"  id="avatar-font-text">{this.props.user.nomeOrgao}</div>
							<div className="avatar"  id="avatar-font-text">{this.props.user.nomePerfil}</div>
						</div>
						<div className="avatar">
							{this.props.photo && 
								<img id="avatar-true" src={`data:image/jpeg;base64, ${this.props.photo}`} alt="avatar"/>
							}

							{! this.props.photo && 
								<span id="avatar-false" className="fa fa-user" />
							}
						</div>
					</React.Fragment>
		}
	}

); // redux
import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";

import api from "../../services/api";
import './SolicitacaoRelatorioAuditoria.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("pt_br", pt_br);

const PF = 'PF';
const PJ = 'PJ';
const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const SolicitacaoRelatorioAuditoriaEdit =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				solicitacao: {
					id: '',
					fornecedora: '',
					orgaoConsumidor: '',
					periodoInicial: '',
					periodoFinal: '',
					formatoRelatorio: '',
					listaCpfCnpjs: [],
				},
				fornecedoras: [],
				tipoApiRfb: '',
				orgaos: [],
				cpfCnpjs: '',
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.handleCpfCnpjChange = this.handleCpfCnpjChange.bind(this);
			this.getTipoApiRfb = this.getTipoApiRfb.bind(this);
			this.carregaOrgaosConsumidoresFornecedora = this.carregaOrgaosConsumidoresFornecedora.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Solicitação de Relatório de auditoria</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				api.get(`/api/solicitacao-relatorio-auditoria/${id}`)
					.then(response => { 
						const solicitacao = response.data;
						const tipoApiRfb = solicitacao.tipoApiRfbFornecedora;
						const cpfCnpjs = solicitacao.listaCpfCnpjs.join("\n");
						this.setState(
							{
								solicitacao,
								tipoApiRfb,
								cpfCnpjs
							}) 
						this.carregaOrgaosConsumidoresFornecedora(solicitacao.fornecedora);
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar o relatório de auditoria. ", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					});
			}

			api.get('/api/aplicacao-fornecedora/relatorio-auditoria')
				.then(response => { this.setState({fornecedoras: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		isApiPFSelecionada(){
			const tipoApiRfb = this.state.tipoApiRfb;
			return (tipoApiRfb && tipoApiRfb === PF);
		}

		isApiPJSelecionada(){
			const tipoApiRfb = this.state.tipoApiRfb;
			return (tipoApiRfb && tipoApiRfb === PJ);
		}
		
		getLabelCpfCnpj(){
			if (this.isApiPFSelecionada()) {
				return <label>CPF's (Informe um por linha - até 10):</label>
			}
			if (this.isApiPJSelecionada()) { 
				return <label>CNPJ's (Informe um por linha - até 10):</label>
			}
			return <label/>
		}

		renderForm() {
			const { solicitacao } = this.state;
		    const labelCpfCnpj = this.getLabelCpfCnpj();
			const user = this.props.user;

			return(
				<div className="container-fluid">
					<div className="br-form">
						
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										<select name="fornecedora" onChange={this.handleFornecedoraChange} value={solicitacao.fornecedora}>
											<option value="">Selecione</option>
												{
													this.state.fornecedoras.map(fornecedora =>
														<option 
															key={fornecedora.id} 
															value={fornecedora.id}>
															{fornecedora.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
						</div>

						{ solicitacao.fornecedora && 
							<React.Fragment>
								<div className="row">
									{ user.isAuditor &&
										<div className="col-sd">
											<div className="field">
												<div className="input">
													<label>{labelCpfCnpj}</label>
													<textarea name="cpfCnpjs" value={this.state.cpfCnpjs} onChange={this.handleCpfCnpjChange} />
												</div>
											</div>
										</div>
									}

									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Órgão Consumidor:</label>
												<select name="orgaoConsumidor" onChange={this.handleInputChange} value={solicitacao.orgaoConsumidor}>
													<option value="">Selecione</option>
														{
															this.state.orgaos.map(orgao =>
																<option 
																	key={orgao.id} 
																	value={orgao.id}>
																	{orgao.cnpjFormatado} - {orgao.nome}
																</option>
														)}
												</select>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Período Inicial:</label>
												<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={solicitacao.periodoInicial} 
													disableClock={true}
													onChange={data => {
														const solicitacao = this.state.solicitacao;
														solicitacao['periodoInicial'] = data;
														this.setState({solicitacao})}
													} 
												/>
											</div>
										</div>
									</div>
								
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Período Final:</label>
												<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={solicitacao.periodoFinal} 
													disableClock={true}
													onChange={data => {
														const solicitacao = this.state.solicitacao;
														solicitacao['periodoFinal'] = data;
														this.setState({solicitacao})}
													} 
												/>
											</div>
										</div>
									</div>

									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label>Formato do Relatório:</label>
												<select name="formatoRelatorio" onChange={this.handleInputChange} value={solicitacao.formatoRelatorio}>
													<option value="">Selecione</option>
													<option value="P">PDF</option>
													<option value="C">CSV</option>
												</select>
											</div>
										</div>
									</div>
								</div>

								{/* button actions */}
								<div className="actions-button">
									<div className="actions justify-content-start">
										<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.solicitacao.id ? 'Alterar' : 'Incluir'}</button>

										{this.state.solicitacao.id && 
											<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Nova Solicitação</button>
										}

										<Link to="/"> 
											<div className="back-button">
												<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
											</div>
										</Link>
									</div>
								</div>
							</React.Fragment>
						}

					</div>
				</div>
				
			);
		}

		validaFormulario(){
			let mensagens = [];
			const { solicitacao, tipoApiRfb } = this.state;
			const { fornecedora, periodoInicial, periodoFinal, formatoRelatorio, listaCpfCnpjs, orgaoConsumidor } = solicitacao;
			const user = this.props.user;

			if(!fornecedora || fornecedora.toString().trim().length === 0 ){
				mensagens.push({mensagem: "API é obrigatório."})
			} else {
				if(user.isAuditor === true) {
					if(!listaCpfCnpjs || listaCpfCnpjs.length === 0 ){
						const msgCpfCnpj = tipoApiRfb === PF ? "CPF's é obrigatório." : "CNPJ's é obrigatório."
						mensagens.push({mensagem: msgCpfCnpj})
					} else if(listaCpfCnpjs.length > 10){
						let mensagem = this.state.tipoApiRfb === PF ? "Informe no máximo 10 CPF's" : "Informe no máximo 10 CNPJ's";
						mensagens.push({mensagem})
					}
				}

				if(user.isMantenedor === true && ! orgaoConsumidor){
					mensagens.push({mensagem: "Órgão Consumidor é obrigatório."});
				}

				if(!periodoInicial){
					mensagens.push({mensagem: "Período Inicial é obrigatório."})
				}

				if(!periodoFinal){
					mensagens.push({mensagem: "Período Final é obrigatório."})
				}

				if(periodoInicial && periodoFinal){
					let periodoInicialNumber = periodoInicial;
					let periodoFinalNumber = periodoFinal;

					if (typeof periodoInicialNumber === 'object'){
						periodoInicialNumber = periodoInicial.getTime();
					}

					if (typeof periodoFinallNumber === 'object'){
						periodoFinalNumber = periodoFinal.getTime();
					}

					const milisegundos = periodoFinalNumber - periodoInicialNumber;

					if(milisegundos < 0){
						mensagens.push({mensagem: "O período inicial deve ser menor ou igual ao período final"});
					}
				}

				if(!formatoRelatorio || formatoRelatorio.toString().trim().length === 0 ){
					mensagens.push({mensagem: "Formato do Relatório obrigatório."})
				}

			}
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleCpfCnpjChange(evento){
			let cpfCnpjs = evento.target.value;
			const listaCpfCnpjs = this.getListaCpfCnpjs(cpfCnpjs);	
			const solicitacao = this.state.solicitacao;
			solicitacao['listaCpfCnpjs'] = listaCpfCnpjs;

			this.setState({
				cpfCnpjs,
				solicitacao,
			});
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const solicitacao = this.state.solicitacao;
			solicitacao[nome] = valor;
			this.setState({ solicitacao });
		}

		getTipoApiRfb(fornecedora) {
			let tipoApiRfb = '';

			if(fornecedora && fornecedora !== ""){
				tipoApiRfb = this.state.fornecedoras.filter((f) => f.id === Number(fornecedora))[0].tipoApiRfb;
			} 

			return tipoApiRfb;
		}

		handleFornecedoraChange(evento){
			const fornecedora =  evento.target.value;
			const solicitacao = this.state.solicitacao;
			solicitacao['fornecedora'] = fornecedora;
			solicitacao['orgaoConsumidor'] = "";
			let tipoApiRfb = this.getTipoApiRfb(fornecedora);

			this.setState({
				solicitacao,
				tipoApiRfb,
				orgaos: [],

			});
			this.carregaOrgaosConsumidoresFornecedora(fornecedora);
		}

		carregaOrgaosConsumidoresFornecedora(idFornecedora){
			if(idFornecedora && idFornecedora !== "0"){
				api.get(`/api/orgao/consumidores/fornecedora/${idFornecedora}`)
				.then(response => { 
					this.setState(
						{
							orgaos: response.data
						}
					);
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os órgãos consumidores da API " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento dos órgãos consumidores da API.")
				});
			}
		}

		novo(e){
			const rotaParaNovo = "/solicitacao-relatorio-auditoria/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar (e){
			this.props.limpaMensagem();
			const { solicitacao } = this.state;
			const user = this.props.user;

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const method = solicitacao.id ? 'put' : 'post';
			let url = '/api/solicitacao-relatorio-auditoria';
			if(solicitacao.id){
				url = url + '/' + solicitacao.id;
			}

			const options = {
				method,
				url,
				data: solicitacao
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					const mensagem = `Solicitação de Relatório de Auditoria ${this.state.solicitacao.id ? 'alterada' : 'incluída'} com sucesso!`;
					const mensagensComplementares = [];
					mensagensComplementares.push({mensagem: "O relatório estará disponível na plataforma assim que for processado."});
					if(! user.isAuditor) {
						mensagensComplementares.push({mensagem: "O relatório será enviado ao email do gestor após o processamento da solicitação."});
					}

					const solicitacao = response.data;
					const cpfCnpjs = solicitacao.listaCpfCnpjs.join("\n");
			
					this.setState({ 
						solicitacao,
						cpfCnpjs
					});
					this.carregaOrgaosConsumidoresFornecedora(solicitacao.fornecedora);
					this.props.exibeMensagemSucesso(mensagem, mensagensComplementares);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.solicitacao.id ? 'alterar' : 'incluir'} a solicitação de relatório de auditoria.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		getListaCpfCnpjs(cpfCnpjs){
			const separadores = [' ', ';', '|', ',', '\n'];
			let cpfCnpjsStr = cpfCnpjs;
			if (separadores.includes(cpfCnpjsStr[cpfCnpjsStr.length -1 ])){
				//remove o ultimo caracter se for um separador
				cpfCnpjsStr = cpfCnpjsStr.slice(0, -1);
			}

			//remove mascara
			cpfCnpjsStr = cpfCnpjsStr.replace(new RegExp("\\.","g"),"").replace(/-/g, '').replace(new RegExp("\\/","g"),"");

			//substitui espacos, virgulas, ponto e virgulas, pipes por quebras de linhas
			cpfCnpjsStr = cpfCnpjsStr.replace(/ |,|;|\|/g, '\n');

			//substitui quebras de linhas consecutivas por apenas uma.
//			cpfCnpjsStr = cpfCnpjsStr.replace(/(\r\n|\r|\n){2,}/g, '\n');

			if(cpfCnpjsStr && cpfCnpjsStr.trim().length > 0){
				return cpfCnpjsStr.split("\n").filter( c => c.trim().length > 0);
			}

			return [];
		}

		
	}

) // redux
); // router
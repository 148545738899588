import React, {Fragment, useState} from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFastForward, faFastBackward, faStepBackward, faStepForward } from "@fortawesome/free-solid-svg-icons";
import './styles.css'; 

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PdfViewer (props) {

    const [totalPaginas, setTotalPaginas] = useState(null);
    const [paginaAtual, setPaginaAtual] = useState(1);

    function onDocumentLoadSuccess({ numPages: quantidadePaginas }) {
        setTotalPaginas(quantidadePaginas);
        setPaginaAtual(1);
    }
    
    function irPrimeiraPagina() {
        setPaginaAtual(1);
    }

    function irUltimaPagina() {
        setPaginaAtual(totalPaginas);
    }

    function mudaPagina(offset) {
        setPaginaAtual(prevPageNumber => prevPageNumber + offset);
    }
    
    function irPaginaAnterior() {
        mudaPagina(-1);
    }
    
    function irPaginaPosterior() {
        mudaPagina(1);
    }

    function download(arquivo) {
        var element = document.createElement('a');
        element.setAttribute('href', URL.createObjectURL(new Blob([arquivo])));
        element.setAttribute('download', props.nomeArquivo ? props.nomeArquivo : 'arquivo.pdf');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <Fragment>
            {props.arquivo && 
                <Fragment>
                    <div className="br-form">
                        <div className="actions justify-content-start">
                            <button type="button" className="button is-secondary botao-navegacao" disabled={! props.arquivo} onClick={() => {download(props.arquivo)}}>
                                <FontAwesomeIcon icon={faDownload} />
                            </button>

                            {(totalPaginas > 1) &&
                                <Fragment>

                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual >= totalPaginas} onClick={irUltimaPagina}>
                                        <FontAwesomeIcon icon={faFastForward} />
                                    </button>

                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual >= totalPaginas} onClick={irPaginaPosterior}>
                                        <FontAwesomeIcon icon={faStepForward} />
                                    </button>
                                    
                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual <= 1} onClick={irPaginaAnterior}>
                                        <FontAwesomeIcon icon={faStepBackward} />
                                    </button>

                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual <= 1} onClick={irPrimeiraPagina}>
                                        <FontAwesomeIcon icon={faFastBackward} />
                                    </button>

                                </Fragment>
                            }
                        </div>
                    </div>
                    <br/>
                    <Document file={`data:application/pdf;base64,${props.arquivo}`} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={paginaAtual} scale={1.5} renderTextLayer={false} renderAnnotationLayer={false} customTextRenderer={false}/>
                    </Document>
                </Fragment>
            }
        </Fragment>
    )
    
}